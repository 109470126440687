// INIT
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "react-dates/initialize";

// REACT
import React from "react";
import ReactDom from "react-dom";

import { CookiesProvider } from "react-cookie";

// REACT-QUERY

import { QueryClient, QueryClientProvider } from "react-query";

// REACT-TOASTIFY CONTAINER
import { ToastContainer } from "react-toastify";

// MATERIAL UI THEMING
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

// JQUERY (🤦‍♂️)
import $ from "jquery";

// ROUTER
import { Route, Router } from "react-router-dom";

// REDUX
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

// REDUCERS
import rootReducer from "./js/modules";
import App from "./js/containers/app";

// MOMENT LOCALE
import "moment/locale/fr";

import { RequireAuthentification } from "./js/authentification";

import storeProvider from "./js/storeProvider";

/*=====----- dev tools -----=====*/
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

// uncomment this line to enable redux extension on the chrome browser
import { composeWithDevTools } from "redux-devtools-extension";

import { createBrowserHistory } from "history";

import "./scss/main.scss";

import ErrorBoundary from "js/components/errorBoundary/ErrorBoundary";

// COLOR VARIABLES
import variables from "./scss/global/variables.js";

// IMMER COMPATIBILITY WITH IE11
import { enableES5 } from "immer";

// MOMENT
import momentTz from "moment-timezone";
import moment from "moment";
import "moment/locale/en-gb";
momentTz.tz.setDefault("UTC");
momentTz.locale("en-gb");
moment.locale("en-gb");

enableES5();

var isDevelopmentEnvironment =
  process.env.NODE_ENV && process.env.NODE_ENV === "development";

export const history = createBrowserHistory({ basename: process.env.REACT_APP_BASEPATH });

const instrumentationKey = window.env.APPINSIGHTS_CONNECTIONSTRING;
const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: { [reactPlugin.identifier]: { history: history } },
  },
});

if (
  !isDevelopmentEnvironment &&
  instrumentationKey !== "" &&
  instrumentationKey !== "#{AppInsightsConnectionString}"
) {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

const queryClient = new QueryClient();

const theme = createTheme({
  typography: {
    fontFamily: ['"Haffer"', '"Helvetica Neue"', '"sans-serif"'].join(","),
    fontSize: 12,
  },
  palette: {
    primary: {
      main: variables.primaryColor,
    },
    secondary: {
      main: variables.primaryColorDark,
    },
    white: {
      main: "#ffffff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "600",
          borderRadius: "0",
          boxShadow: "none",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: variables.primaryColor,
          fontFamily: ['"ITC Charter"'].join(","),
          fontSize: "1.4rem",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: "0.85rem",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
        },
        cell: {
          "&:not(.MuiDataGrid-cell--editable):focus": {
            outline: "none",
          },
          "&:not(.MuiDataGrid-cell--editable):focus-within": {
            outline: "none",
          },
        },
        columnHeader: {
          "&:focus": {
            outline: "none",
          },
        },
        columnHeaderTitle: {
          textTransform: "uppercase",
          fontWeight: 400,
          color: "#999",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          "&.MuiStepLabel-alternativeLabel": {
            textTransform: "uppercase",
            fontWeight: 400,
          },
          "&.Mui-completed, &.Mui-active": {
            color: variables.primaryColor,
            fontWeight: 600,
          },
          "&:not(.Mui-completed):not(.Mui-active)": {
            color: "lightgray",
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&:not(.Mui-completed):not(.Mui-active)": {
            color: "lightgray",
          },
          "&": {
            transform: "scale(1.5)",
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          positionStart: {
            marginRight: "0",
            marginLeft: "0.8rem",
          },
        },
      },
    },
  },
});

// uncomment this line to enable redux extension remotely

// import { composeWithDevTools } from 'remote-redux-devtools';

let configureStore = null;

configureStore = () =>
  createStore(
    rootReducer,
    window.__INITIAL_STATE__,
    composeWithDevTools(applyMiddleware(thunk))
  );

storeProvider.init(configureStore);

const store = storeProvider.getStore();

// Add jQuery to window

window.$ = $;
window.jQuery = $;

// render the root component

ReactDom.render(
  <ErrorBoundary>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <CookiesProvider>
              <Router history={history}>
                <Route
                  render={(props) => (
                    <RequireAuthentification
                      {...props}
                      AppComponent={App}
                      appInsights={appInsights}
                    />
                  )}
                />
              </Router>
            </CookiesProvider>
            <ToastContainer />
          </Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ErrorBoundary>,

  document.getElementById("root")
);

export default withAITracking(reactPlugin, App);
