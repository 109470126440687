import * as types from "../constants";

const initialState = {
  isLoading: true,
  error: null,
  items: {},
};

export default function promotorsEditReducer(state = initialState, action) {
  switch (action.type) {
    case types.EDIT_PROMOTOR_INIT:
      return { ...state, error: null, isLoading: true };

    case types.EDIT_PROMOTOR_SUCCESS:
      return { ...state, isLoading: false, items: action.data.data };

    case types.EDIT_PROMOTOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.notification.system_message,
      };

    default:
      return state;
  }
}
