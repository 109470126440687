// react dependencies
import React, { Component } from "react";

import { __ } from "../../utils";

class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: this.props.isValid,
      error: this.props.error,
      errorMsg: this.props.errorMsg,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.state.isValid != nextProps.isValid) {
      this.setState({ isValid: nextProps.isValid });
    }
    if (this.props.error != nextProps.error) {
      this.setState({ error: nextProps.error });
    }
    if (this.props.errorMsg != nextProps.errorMsg) {
      this.setState({ errorMsg: nextProps.errorMsg });
    }
  };

  renderIsValid = () => {
    if (
      this.props.mandatory ||
      this.props.validationRequired ||
      this.state.errorMsg
    ) {
      if (this.state.isValid && !this.state.errorMsg) {
        return <i className="pwc-status-check-1" />;
      } else {
        return <i className="pwc-status-close" />;
      }
    }
  };

  renderHelper = () => {
    if (this.state.isValid) {
      return __("__DATA_APPEARS_TO_BE_GOOD_");
    } else {
      return __("__ONE_VALUE_MUST_BE_SELECTED_");
    }
  };

  renderMandatory = () => {
    if (this.props.mandatory) {
      return <span className="form-row-mandatory">*</span>;
    }
  };

  validate = (e) => {
    if (this.props.validate) {
      this.props.validate(e);
    }
  };

  renderClassname = () => {
    if (this.state.isValid && !this.state.errorMsg) {
      return "form-row--valid";
    } else if (this.props.error || this.state.errorMsg) {
      return "form-row--error";
    } else {
      return "";
    }
  };

  reset = () => {
    this.setState({ error: false });
  };

  renderChildren = () => {
    if (this.props.dontPassProps) {
      return this.props.children;
    } else {
      if (this.props.validate)
        return React.Children.map(this.props.children, (child, index) => {
          if (child)
            return React.cloneElement(child, {
              validate: this.validate,
              reset: this.reset,
            });
        });
      else
        return React.Children.map(this.props.children, (child, index) => {
          if (child)
            return React.cloneElement(child, {
              reset: this.reset,
            });
        });
    }
  };

  renderHorizontal = () => {
    if (this.props.horizontal) {
      return "form-row-horizontal";
    }
  };

  render() {
    return (
      <div
        className={
          "form-row " +
          (this.props.extension ? "form-row--with-extension" : "") +
          " " +
          this.renderHorizontal() +
          " " +
          this.renderClassname() +
          " " +
          (this.props.className ?? "")
        }
      >
        {this.props.label ? (
          <label className="form-row-label">
            {this.props.label}
            {this.renderMandatory()}
            {this.state.errorMsg && (
              <>
                <br />
                <div className="form-error-msg">{this.state.errorMsg}</div>
              </>
            )}
          </label>
        ) : null}
        <div className="form-row-input">
          {this.renderChildren()}
          {this.props.extension ? this.props.extension : null}
          {this.props.validate &&
          !this.props.hideHelperIcon &&
          this.props.label ? (
            <div className="form-row-is-valid">{this.renderIsValid()}</div>
          ) : null}
        </div>
        {this.props.hasHelpText ? (
          <div className="form-row-helper">{this.renderHelper()}</div>
        ) : null}
      </div>
    );
  }
}

export default InputText;
