// react dependencies
import React, { Component } from "react";
import Image404 from "../../img/404.png";

import Button from "../components/button/Button";

const DEFAULT_TAG_LINE = "An error occurred";

const DEFAULT_MESSAGE = "Please try again later";

const DEFAULT_ILLUSTRATION = Image404;

class FullPageError extends Component {
  render() {
    return (
      <section className="not-found">
        <div className="container-default">
          <div className="not-found-img">
            <img
              src={this.props.illustration ?? DEFAULT_ILLUSTRATION}
              className="colorized"
              alt="error"
            />
          </div>
          <div className="not-found-oops">Oops...</div>
          <div className="not-found-error">
            {this.props.tagLine ?? DEFAULT_TAG_LINE}
          </div>
          <div className="not-found-sorry">
            {this.props.message ?? DEFAULT_MESSAGE}
          </div>
          <div className="not-found-action">
            <Button
              label="Retry"
              onClick={() => {
                document.location.reload();
              }}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default FullPageError;
