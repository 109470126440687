import { useEffect } from "react";
import { Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setAuthentificationNeedsAuth } from "../../modules/authentification/actions/authentificationActions";

const PublicRoute = (props) => {
  const dispatch = useDispatch();

  const needsAuth = useSelector(
    (state) => state.authentificationReducer.needsAuth
  );
  useEffect(() => {
    if (needsAuth === true || needsAuth === null) {
      dispatch(setAuthentificationNeedsAuth(false));
    }
  }, [dispatch, needsAuth]);

  return <Route {...props} />;
};

export default PublicRoute;
