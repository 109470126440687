import React from "react";
import "./SuspenseLoader.scss";

const SuspenseLoader = ({ message }) => {
  return (
    <div className="suspense-loader">
      <div className="cs-loader">
        <div className="loaderv3">
          <svg className="circularv3" viewBox="25 25 50 50">
            <circle
              className="pathv3"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </div>
      <div className="loader-full-screen-text">
        Loading module. Please wait...
      </div>
    </div>
  );
};

export default SuspenseLoader;
