// react dependencies
import React, { Component } from "react";

import Loader from "../loader/Loader";

import onClickOutside from "react-onclickoutside";

class Button extends Component {
  attachedMenu;
  constructor(props) {
    super(props);
    this.state = {
      popoverClassname: "btn-popover",
    };
  }

  handleClickOutside = (evt) => {
    if (this.props.popover) {
      this.setState({ popoverClassname: "btn-popover" });
    }
  };

  componentDidMount = () => {
    if (this.refs.boutonSurface) {
      window.mdc.ripple.MDCRipple.attachTo(this.refs.boutonSurface);
    }
    if (this.refs.menuSurface) {
      this.attachedMenu = window.mdc.menuSurface.MDCMenuSurface.attachTo(
        this.refs.menuSurface
      );
    }
  };

  open = () => {
    this.attachedMenu.foundation_.open();
  };

  close = () => {
    this.attachedMenu.foundation_.close();
  };

  renderIcon = () => {
    if (this.props.icon && !this.props.iconLeft) {
      return <i className={this.props.icon} />;
    }
  };

  renderIconLeft = () => {
    if (this.props.icon && this.props.iconLeft) {
      return <i className={this.props.icon} />;
    }
  };

  renderClass = () => {
    let className = "";
    if (this.props.className) {
      className = this.props.className;
    }
    if (this.props.popover) {
      className += " btn-popover";
    }
    if (this.props.popover) {
      className += " " + this.state.popoverClassname;
    }
    if (this.props.iconOnly) {
      className += " btn-icon-only-header";
    }
    if (
      !this.props.className ||
      (this.props.className.indexOf("btn-secondary") < 0 &&
        this.props.className.indexOf("btn-ghost") < 0)
    ) {
      className += " mdc-button--raised";
    }

    return className;
  };

  handleClick = (e) => {
    if (this.props.noPropagation) e.stopPropagation();
    if (this.props.popover) {
      if (this.state.popoverClassname.indexOf("--visible") > 0)
        this.setState({ popoverClassname: "btn-popover" });
      else
        this.setState({ popoverClassname: "btn-popover btn-popover--visible" });
    }
    if (this.props.onClick && !this.props.disabled) this.props.onClick();
  };

  onClickPopoverItem = (child) => {
    this.setState({ popoverClassname: "btn-popover" });
    if (child.props.onClick) child.props.onClick();
    this.close();
  };

  renderPopover = () => {
    if (this.props.popover) {
      return (
        <div className="btn-popover-content">
          <div>
            {React.Children.map(this.props.children, (child, index) => {
              if (child)
                return React.cloneElement(child, {
                  onClick: () => this.onClickPopoverItem(child),
                });
            })}
          </div>
        </div>
      );
    }
  };

  renderItemsPopover = () => {
    return React.Children.map(this.props.children, (child, index) => {
      if (child)
        return (
          <li className="mdc-list-item" role="menuitem">
            {React.cloneElement(child, {
              onClick: () => this.onClickPopoverItem(child),
            })}
          </li>
        );
    });
  };

  render() {
    if (this.props.loading) {
      return (
        <button className={"btn " + this.renderClass()}>
          <span>
            <Loader />
          </span>
        </button>
      );
    } else {
      if (this.props.popover) {
        /*
            return (
              <div disabled={this.props.disabled} onClick={this.handleClick} className={"btn "+this.renderClass()}>
                {this.renderIconLeft()}
                <span>{this.props.label}</span>
                {this.renderIcon()}
                {this.renderPopover()}
            </div>
            )
            */
        return (
          <div className="mdc-menu-surface--anchor">
            <div
              tabIndex={this.props.tabIndex ? this.props.tabIndex : 1}
              ref="boutonSurface"
              disabled={this.props.disabled}
              onClick={this.open}
              className={"mdc-ripple-surface btn " + this.renderClass()}
            >
              {this.renderIconLeft()}
              <span>{this.props.label}</span>
              {this.renderIcon()}
            </div>
            <div
              onClick={this.close}
              ref="menuSurface"
              className="mdc-menu mdc-menu-surface"
              tabIndex="-1"
            >
              <ul
                className="mdc-list"
                role="menu"
                aria-hidden="true"
                aria-orientation="vertical"
              >
                {this.renderItemsPopover()}
              </ul>
            </div>
          </div>
        );
      } else
        return (
          <button
            tabIndex={this.props.tabIndex ? this.props.tabIndex : 1}
            ref="boutonSurface"
            disabled={this.props.disabled}
            onClick={this.handleClick}
            className={
              "button--surface main-nav-item mdc-ripple-surface btn " +
              this.renderClass()
            }
          >
            {this.renderIconLeft()}
            <span>{this.props.label}</span>
            {this.renderIcon()}
            {this.renderPopover()}
          </button>
        );
    }
  }
}

export default onClickOutside(Button);
