export const GET_DOCUMENT_TEMPLATES_INIT = "DOCUMENT_TEMPLATES:GET_INIT";
export const GET_DOCUMENT_TEMPLATES_SUCCESS = "DOCUMENT_TEMPLATES:GET_SUCCESS";
export const GET_DOCUMENT_TEMPLATES_FAILURE = "DOCUMENT_TEMPLATES:GET_FAIL";

export const GET_DOCUMENT_TEMPLATES_TAGS_INIT =
  "DOCUMENT_TEMPLATES_TAGS:GET_INIT";
export const GET_DOCUMENT_TEMPLATES_TAGS_SUCCESS =
  "DOCUMENT_TEMPLATES_TAGS:GET_SUCCESS";
export const GET_DOCUMENT_TEMPLATES_TAGS_FAILURE =
  "DOCUMENT_TEMPLATES_TAGS:GET_FAIL";

export const GET_DOCUMENT_TEMPLATES_GET_INIT =
  "DOCUMENT_TEMPLATES_GET:GET_INIT";
export const GET_DOCUMENT_TEMPLATES_GET_SUCCESS =
  "DOCUMENT_TEMPLATES_GET:GET_SUCCESS";
export const GET_DOCUMENT_TEMPLATES_GET_FAILURE =
  "DOCUMENT_TEMPLATES_GET:GET_FAIL";

export const GET_DOCUMENT_TEMPLATES_ADD_INIT =
  "DOCUMENT_TEMPLATES_ADD:GET_INIT";
export const GET_DOCUMENT_TEMPLATES_ADD_SUCCESS =
  "DOCUMENT_TEMPLATES_ADD:GET_SUCCESS";
export const GET_DOCUMENT_TEMPLATES_ADD_FAILURE =
  "DOCUMENT_TEMPLATES_ADD:GET_FAIL";

export const GET_DOCUMENT_TEMPLATES_CLONE_INIT =
  "DOCUMENT_TEMPLATES_CLONE:GET_INIT";
export const GET_DOCUMENT_TEMPLATES_CLONE_SUCCESS =
  "DOCUMENT_TEMPLATES_CLONE:GET_SUCCESS";
export const GET_DOCUMENT_TEMPLATES_CLONE_FAILURE =
  "DOCUMENT_TEMPLATES_CLONE:GET_FAIL";

export const GET_DOCUMENT_TEMPLATES_DELETE_INIT =
  "DOCUMENT_TEMPLATES_DELETE:GET_INIT";
export const GET_DOCUMENT_TEMPLATES_DELETE_SUCCESS =
  "DOCUMENT_TEMPLATES_DELETE:GET_SUCCESS";
export const GET_DOCUMENT_TEMPLATES_DELETE_FAILURE =
  "DOCUMENT_TEMPLATES_DELETE:GET_FAIL";

export const GET_DOCUMENT_TEMPLATES_EDIT_INIT =
  "DOCUMENT_TEMPLATES_EDIT:GET_INIT";
export const GET_DOCUMENT_TEMPLATES_EDIT_SUCCESS =
  "DOCUMENT_TEMPLATES_EDIT:GET_SUCCESS";
export const GET_DOCUMENT_TEMPLATES_EDIT_FAILURE =
  "DOCUMENT_TEMPLATES_EDIT:GET_FAIL";

export const DOCUMENT_TEMPLATES_DATA_INIT = "DOCUMENT_TEMPLATES_DATA:GET_INIT";
export const DOCUMENT_TEMPLATES_DATA_SUCCESS =
  "DOCUMENT_TEMPLATES_DATA:GET_SUCCESS";
export const DOCUMENT_TEMPLATES_DATA_FAILURE =
  "DOCUMENT_TEMPLATES_DATA:GET_FAIL";
export const DOCUMENT_TEMPLATES_DATA_RESET =
  "DOCUMENT_TEMPLATES_DATA:GET_RESET";

export const DOCUMENT_TEMPLATES_RULE_DATA_INIT =
  "DOCUMENT_TEMPLATES_RULE_DATA:GET_INIT";
export const DOCUMENT_TEMPLATES_RULE_DATA_SUCCESS =
  "DOCUMENT_TEMPLATES_RULE_DATA:GET_SUCCESS";
export const DOCUMENT_TEMPLATES_RULE_DATA_FAILURE =
  "DOCUMENT_TEMPLATES_RULE_DATA:GET_FAIL";
export const DOCUMENT_TEMPLATES_RULE_DATA_RESET =
  "DOCUMENT_TEMPLATES_RULE_DATA:GET_RESET";
