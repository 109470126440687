// react dependencies
import React, { Component } from "react";

class ModalHeader extends Component {
  render() {
    /*
    return (
        <div className="modal-content-header">
            <header>
                <h2>{this.props.children}</h2>
                {this.props.closeButton?<div><i onClick={this.props.onClose} className="modal-content-header-close pwc-status-close"></i></div>:""}
            </header>
        </div>
    );
    */
    return (
      <h2 className="mdc-dialog__title" id="my-dialog-title">
        {this.props.children}
      </h2>
    );
  }
}

export default ModalHeader;
