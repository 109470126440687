// react dependencies
import React, { Component } from "react";

class Toggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked ? this.props.checked : false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.checked != this.state.checked) {
      this.setState({
        checked: nextProps.checked,
      });
    }
  }

  handleClick = (e) => {
    e.persist();
    if (this.props.disablePropagation) {
      e.stopPropagation();
    }
    if (this.props.onClick && !this.props.disabled) {
      this.props.onClick(!this.state.checked);
    }
    //this.setState({ checked: !this.state.checked })
  };

  renderLabel = () => {
    if (this.props.label) {
      return <span>{this.props.label}</span>;
    }
  };

  renderSecondLabel = () => {
    if (this.props.secondLabel) {
      return <span>{this.props.secondLabel}</span>;
    }
  };

  componentDidMount = () => {
    if (this.refs.switch) {
      this.attachedMenu = window.mdc.switchControl.MDCSwitch.attachTo(
        this.refs.switch
      );
    }
  };

  render() {
    return (
      <div className="switch-v2" onClick={this.handleClick}>
        {this.renderSecondLabel()}
        <div
          ref="switch"
          className={
            "mdc-switch" +
            (this.state.checked ? " mdc-switch--checked" : "") +
            (this.state.disabled ? " mdc-switch--disabled" : "")
          }
        >
          <div className="mdc-switch__track"></div>
          <div className="mdc-switch__thumb-underlay">
            <div className="mdc-switch__thumb">
              <input
                type="checkbox"
                disabled={this.props.disabled}
                onChange={() => {}}
                checked={this.state.checked}
                className="mdc-switch__native-control"
                role="switch"
              />
            </div>
          </div>
        </div>
        {this.renderLabel()}
      </div>
    );
  }
}

export default Toggle;
