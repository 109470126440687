import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import PromotorsApi from "../../../models/PromotorsApi";

export function loadPromotorsGetInfo() {
  let action = new ActionRequest(
    PromotorsApi.getPromotorInfo,
    loadPromotorsGetInfoInit,
    loadPromotorsGetInfoSuccess,
    loadPromotorsGetInfoFailure
  );
  return action.run({
    routeParameters: {},
    actionParameters: {},
  });
}

function loadPromotorsGetInfoSuccess(promotorsGetSettingsData, params) {
  return {
    type: types.GET_INFO_PROMOTOR_SUCCESS,
    data: promotorsGetSettingsData,
  };
}

function loadPromotorsGetInfoFailure(error) {
  return {
    type: types.GET_INFO_PROMOTOR_FAILURE,
    notification: {
      level: "error",
      label: "[action:promotorsGetInfos>loadPromotorsGetSettings]",
      message: "Impossible de récupérer la liste des promotorsGetSettings",
      system_message: error.message,
    },
  };
}

function loadPromotorsGetInfoInit(params) {
  return { type: types.GET_INFO_PROMOTOR_INIT };
}
