import Request from "../utils/requests/request";

export default class LibraryApi {
  static loadLibraryUploadFile(params, successCallback, failCallback) {
    if(!params.files){
      return null;
    }
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Library/UploadFile"
    );
    let formData = new FormData();
    for (const file of params.files){
      formData.append("file", file, file.name)
    }
    return loadReq.uploadLibrary(formData, params.fundId, params.documentType);
  }

  static loadLibraryGetAuthors(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Library/GetLibraryDocumentAuthorsAvailable"
    );
    return loadReq.get();
  }

  static loadDocumentSubTypesByDocumentType(
    params,
    successCallback,
    failCallback
  ) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Library/GetDocumentSubTypeByDocumentTypes"
    );
    return loadReq.post(params.type);
  }

  static loadLibraryGetExceptions(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Library/GetException"
    );
    return loadReq.get();
  }

  static loadLibraryGetHistory(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Library/GetLibraryHistory"
    );
    return loadReq.post(params.filters);
  }

  static loadLibraryGetCounters(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Library/GetLibraryDocumentStatus"
    );
    return loadReq.get();
  }

  static loadLibraryGetMetadataByType(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Library/GetMetadataTypeByDocumentTypes"
    );
    return loadReq.post(params.types);
  }

  static loadLibraryGetMissingMetadata(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Library/GetMissingMetadata"
    );
    return loadReq.get();
  }

  static loadLibraryGetStatus(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Library/GetLibraryDocumentStatus"
    );
    return loadReq.get();
  }

  static loadLibraryGetUnclassified(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Library/GetUnclassified"
    );
    return loadReq.get();
  }

  static loadLibraryPostException(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Library/PostException"
    );
    return loadReq.post(params.params);
  }

  static loadLibraryPostMissingMetadata(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Library/SetDocumentMetadata"
    );
    return loadReq.post(params.params);
  }

  static loadLibraryPostUnclassified(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Library/PostUnclassified"
    );
    return loadReq.post(params.params);
  }

  static loadLibraryDocuments(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Library/GetDocumentByTypeAndDate?fundId=" +
        params.fundId +
        "&documentTypeId=" +
        params.documentTypeId +
        "&date=" +
        params.date
    );
    return loadReq.post();
  }

  static loadDocumentRelatedTasks(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Library/GetDocumentRelatedTasks?docId=" +
        params.docId
    );
    return loadReq.get();
  }
}
