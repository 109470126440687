export const GET_FUND_DISTRIBUTION_WATCH_INIT =
  "FUND_DISTRIBUTION_WATCH:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_SUCCESS =
  "FUND_DISTRIBUTION_WATCH:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_FAILURE =
  "FUND_DISTRIBUTION_WATCH:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_STRUCTURE_INIT =
  "FUND_DISTRIBUTION_WATCH_STRUCTURE:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_STRUCTURE_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_STRUCTURE:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_STRUCTURE_FAILURE =
  "FUND_DISTRIBUTION_WATCH_STRUCTURE:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_STRUCTURE_EDIT_INIT =
  "FUND_DISTRIBUTION_WATCH_STRUCTURE_EDIT:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_STRUCTURE_EDIT_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_STRUCTURE_EDIT:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_STRUCTURE_EDIT_FAILURE =
  "FUND_DISTRIBUTION_WATCH_STRUCTURE_EDIT:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_EXPORT_INIT =
  "FUND_DISTRIBUTION_WATCH_EXPORT:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_EXPORT_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_EXPORT:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_EXPORT_FAILURE =
  "FUND_DISTRIBUTION_WATCH_EXPORT:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_IMPORT_INIT =
  "FUND_DISTRIBUTION_WATCH_IMPORT:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_IMPORT_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_IMPORT:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_IMPORT_FAILURE =
  "FUND_DISTRIBUTION_WATCH_IMPORT:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_COUNTRIES_BY_REGION_INIT =
  "FUND_DISTRIBUTION_WATCH_COUNTRIES_BY_REGION:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_COUNTRIES_BY_REGION_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_COUNTRIES_BY_REGION:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_COUNTRIES_BY_REGION_FAILURE =
  "FUND_DISTRIBUTION_WATCH_COUNTRIES_BY_REGION:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_SET_COUNTRIES_FOR_REGION_INIT =
  "FUND_DISTRIBUTION_WATCH_SET_COUNTRIES_FOR_REGION:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_SET_COUNTRIES_FOR_REGION_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_SET_COUNTRIES_FOR_REGION:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_SET_COUNTRIES_FOR_REGION_FAILURE =
  "FUND_DISTRIBUTION_WATCH_SET_COUNTRIES_FOR_REGION:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_GET_COUNTRIES_FOR_REGION_INIT =
  "FUND_DISTRIBUTION_WATCH_GET_COUNTRIES_FOR_REGION:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_GET_COUNTRIES_FOR_REGION_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_GET_COUNTRIES_FOR_REGION:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_GET_COUNTRIES_FOR_REGION_FAILURE =
  "FUND_DISTRIBUTION_WATCH_GET_COUNTRIES_FOR_REGION:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_TAGS_INIT =
  "FUND_DISTRIBUTION_WATCH_TAGS:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_TAGS_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_TAGS:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_TAGS_FAILURE =
  "FUND_DISTRIBUTION_WATCH_TAGS:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_TAGS_ADD_INIT =
  "FUND_DISTRIBUTION_WATCH_TAGS_ADD:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_TAGS_ADD_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_TAGS_ADD:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_TAGS_ADD_FAILURE =
  "FUND_DISTRIBUTION_WATCH_TAGS_ADD:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_TAGS_EDIT_INIT =
  "FUND_DISTRIBUTION_WATCH_TAGS_EDIT:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_TAGS_EDIT_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_TAGS_EDIT:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_TAGS_EDIT_FAILURE =
  "FUND_DISTRIBUTION_WATCH_TAGS_EDIT:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_TAGS_DELETE_INIT =
  "FUND_DISTRIBUTION_WATCH_TAGS_DELETE:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_TAGS_DELETE_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_TAGS_DELETE:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_TAGS_DELETE_FAILURE =
  "FUND_DISTRIBUTION_WATCH_TAGS_DELETE:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_CONTENT_INIT =
  "FUND_DISTRIBUTION_WATCH_CONTENT:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_CONTENT_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_CONTENT:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_CONTENT_FAILURE =
  "FUND_DISTRIBUTION_WATCH_CONTENT:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_CONTENT_EDIT_INIT =
  "FUND_DISTRIBUTION_WATCH_CONTENT_EDIT:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_CONTENT_EDIT_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_CONTENT_EDIT:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_CONTENT_EDIT_FAILURE =
  "FUND_DISTRIBUTION_WATCH_CONTENT_EDIT:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_SINGLE_CONTENT_EDIT_INIT =
  "FUND_DISTRIBUTION_WATCH_SINGLE_CONTENT_EDIT:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_SINGLE_CONTENT_EDIT_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_SINGLE_CONTENT_EDIT:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_SINGLE_CONTENT_EDIT_FAILURE =
  "FUND_DISTRIBUTION_WATCH_SINGLE_CONTENT_EDIT:GET_FAIL";

export const GET_FUND_DISTRIBUTION_WATCH_TAGS_FOR_CREATE_INIT =
  "FUND_DISTRIBUTION_WATCH_TAGS_FOR_CREATE:GET_INIT";
export const GET_FUND_DISTRIBUTION_WATCH_TAGS_FOR_CREATE_SUCCESS =
  "FUND_DISTRIBUTION_WATCH_TAGS_FOR_CREATE:GET_SUCCESS";
export const GET_FUND_DISTRIBUTION_WATCH_TAGS_FOR_CREATE_FAILURE =
  "FUND_DISTRIBUTION_WATCH_TAGS_FOR_CREATE:GET_FAIL";
