import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import LibraryV2Api from "../../../models/LibraryV2Api";

export function loadLibraryGetUnclassified() {
  let action = new ActionRequest(
    LibraryV2Api.loadLibraryGetUnclassified,
    loadLibraryGetUnclassifiedInit,
    loadLibraryGetUnclassifiedSuccess,
    loadLibraryGetUnclassifiedFailure
  );
  return action.run({
    routeParameters: {},
    actionParameters: {},
  });
}

function loadLibraryGetUnclassifiedSuccess(librayImportData, params) {
  return {
    type: types.LIBRARY_GET_UNCLASSIFIED_SUCCESS,
    data: librayImportData,
  };
}

function loadLibraryGetUnclassifiedFailure(error) {
  return {
    type: types.LIBRARY_GET_UNCLASSIFIED_FAILURE,
    notification: {
      level: "error",
      label: "[action:librayImport>loadLibraryGetUnclassified]",
      message: "Impossible de récupérer la liste des librayImport",
      system_message: error.message,
    },
  };
}

function loadLibraryGetUnclassifiedInit(params) {
  return { type: types.LIBRARY_GET_UNCLASSIFIED_INIT };
}
