export const GET_FUND_DETAILS_STRUCTURE_INIT =
  "FUND_DETAILS_STRUCTURE:GET_INIT";
export const GET_FUND_DETAILS_STRUCTURE_SUCCESS =
  "FUND_DETAILS_STRUCTURE:GET_SUCCESS";
export const GET_FUND_DETAILS_STRUCTURE_FAILURE =
  "FUND_DETAILS_STRUCTURE:GET_FAIL";

export const GET_FUND_DETAILS_EXPORT_INIT = "FUND_DETAILS_EXPORT:GET_INIT";
export const GET_FUND_DETAILS_EXPORT_SUCCESS =
  "FUND_DETAILS_EXPORT:GET_SUCCESS";
export const GET_FUND_DETAILS_EXPORT_FAILURE = "FUND_DETAILS_EXPORT:GET_FAIL";

export const GET_FUND_DETAILS_IMPORT_INIT = "FUND_DETAILS_IMPORT:GET_INIT";
export const GET_FUND_DETAILS_IMPORT_SUCCESS =
  "FUND_DETAILS_IMPORT:GET_SUCCESS";
export const GET_FUND_DETAILS_IMPORT_FAILURE = "FUND_DETAILS_IMPORT:GET_FAIL";
export const GET_FUND_DETAILS_IMPORT_FAILURE_CLEAR =
  "FUND_DETAILS_IMPORT:GET_FAIL_CLEAR";

export const GET_FUND_DETAILS_COLUMNS_INIT = "FUND_DETAILS_COLUMNS:GET_INIT";
export const GET_FUND_DETAILS_COLUMNS_SUCCESS =
  "FUND_DETAILS_COLUMNS:GET_SUCCESS";
export const GET_FUND_DETAILS_COLUMNS_FAILURE = "FUND_DETAILS_COLUMNS:GET_FAIL";

export const GET_FUND_DETAILS_COLUMNS_SAVE_INIT =
  "FUND_DETAILS_COLUMNS_SAVE:GET_INIT";
export const GET_FUND_DETAILS_COLUMNS_SAVE_SUCCESS =
  "FUND_DETAILS_COLUMNS_SAVE:GET_SUCCESS";
export const GET_FUND_DETAILS_COLUMNS_SAVE_FAILURE =
  "FUND_DETAILS_COLUMNS_SAVE:GET_FAIL";
