import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import DistributionMatrixApi from "../../../models/DistributionMatrixApi";

export function loadDistributionMatrix(filters) {
  let action = new ActionRequest(
    DistributionMatrixApi.loadDistributionMatrix,
    loadDistributionMatrixInit,
    loadDistributionMatrixSuccess,
    loadDistributionMatrixFailure
  );
  return action.run({
    routeParameters: { filters },
    actionParameters: { filters },
  });
}

function loadDistributionMatrixSuccess(distributionMatrixData) {
  return {
    type: types.GET_DISTRIBUTION_MATRIX_SUCCESS,
    data: distributionMatrixData,
  };
}

function loadDistributionMatrixFailure(error) {
  return {
    type: types.GET_DISTRIBUTION_MATRIX_FAILURE,
    notification: {
      level: "error",
      label: "[action:distributionMatrix>loadDistributionMatrix]",
      message: "Impossible de récupérer la liste des distributionMatrix",
      system_message: error.message,
    },
  };
}

function loadDistributionMatrixInit(params) {
  return { type: types.GET_DISTRIBUTION_MATRIX_INIT };
}
