import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import NotificationsApi from "../../../models/NotificationsApi";

export function loadNotificationsDelete(ids) {
  let action = new ActionRequest(
    NotificationsApi.loadNotificationsDelete,
    loadNotificationsDeleteInit,
    loadNotificationsDeleteSuccess,
    loadNotificationsDeleteFailure
  );
  return action.run({
    routeParameters: { ids },
    actionParameters: { ids },
  });
}

function loadNotificationsDeleteSuccess(notificationsDeleteData, params) {
  return {
    type: types.GET_NOTIFICATIONS_DELETE_SUCCESS,
    data: notificationsDeleteData,
  };
}

function loadNotificationsDeleteFailure(error) {
  return {
    type: types.GET_NOTIFICATIONS_DELETE_FAILURE,
    notification: {
      level: "error",
      label: "[action:notificationsDelete>loadNotificationsDelete]",
      message: "Impossible de récupérer la liste des notificationsDelete",
      system_message: error.message,
    },
  };
}

function loadNotificationsDeleteInit(params) {
  return { type: types.GET_NOTIFICATIONS_DELETE_INIT };
}
