import Request from "../utils/requests/request";

export default class FundDetailsApi {
  static loadFundDetailsStructure(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "FundDetails/GetFundDetails"
    );
    return loadReq.post(params.filters);
  }

  static loadFundDetailsExport(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "FundDetails/DownloadFundDetails"
    );
    return loadReq.downloadWithPost(params.filters, params.name);
  }

  static loadFundDetailsColumns(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "FundDetails/GetCustomColumnsList"
    );
    return loadReq.get();
  }

  static loadFundDetailsColumnsSave(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "FundDetails/UpdateCustomColumns"
    );
    return loadReq.post(params.columns);
  }

  static loadFundDetailsImport(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "FundDetails/UploadFundDetails"
    );
    let formData = new FormData();
    for (let i in params.files) {
      formData.append("file", params.files[i], params.files[i].name);
    }
    return loadReq.upload(formData);
  }
}
