// dependencies
import { routerReducer as routing } from "react-router-redux";
import { combineReducers } from "redux";

// reducers
import authentificationReducer from "./authentification/reducers/authentificationReducer";
import acceptGtcReducer from "./authentification/reducers/acceptGtcReducer";
import logOffReducer from "./authentification/reducers/logOffReducer";
import distributionMatrixReducer from "./distributionMatrix/reducers/distributionMatrixReducer";
import distributionMatrixTaskDetailsReducer from "./distributionMatrix/reducers/distributionMatrixTaskDetailsReducer";
import distributionMatrixMapReducer from "./distributionMatrix/reducers/distributionMatrixMapReducer";
import distributionMatrixImportReducer from "./distributionMatrix/reducers/distributionMatrixImportReducer";
import distributionMatrixImportFundsquareReducer from "./distributionMatrix/reducers/distributionMatrixImportFundsquareReducer";
import distributionMatrixImportFundsquareCSVReducer from "./distributionMatrix/reducers/distributionMatrixImportFundsquareCSVReducer";
import distributionMatrixImportUpdateFundsquareReducer from "./distributionMatrix/reducers/distributionMatrixImportUpdateFundsquareReducer";
import distributionMatrixImportUpdateFundsquareCSVReducer from "./distributionMatrix/reducers/distributionMatrixImportUpdateFundsquareCSVReducer";
import distributionMatrixImportUpdateReducer from "./distributionMatrix/reducers/distributionMatrixImportUpdateReducer";
import distributionMatrixFiltersReducer from "./distributionMatrix/reducers/distributionMatrixFiltersReducer";
import distributionMatrixDateReducer from "./distributionMatrix/reducers/distributionMatrixDateReducer";
import distributionMatrixFlushReducer from "./distributionMatrix/reducers/distributionMatrixFlushReducer";
import distributionMatrixAllFiltersReducer from "./distributionMatrix/reducers/distributionMatrixAllFiltersReducer";
import distributionMatrixExportExcelReducer from "./distributionMatrix/reducers/distributionMatrixExportExcelReducer";
import distributionMatrixExportPDFReducer from "./distributionMatrix/reducers/distributionMatrixExportPDFReducer";
import distributionMatrixExportCSVOpenfundsReducer from "./distributionMatrix/reducers/distributionMatrixExportCSVOpenfundsReducer";
import distributionMatrixFiltersStatsReducer from "./distributionMatrix/reducers/distributionMatrixFiltersStatsReducer";
import documentTypesByFundReducer from "./documentTypes/reducers/documentTypesByFundReducer";
import documentTypesReducer from "./documentTypes/reducers/documentTypesReducer";
import documentsByMonthReducer from "./documents/reducers/documentsByMonthReducer";
import libraryImportReducer from "./library/reducers/libraryImportReducer";
import libraryYearsReducer from "./library/reducers/libraryYearsReducer";
import libraryGetRelatedTasksReducer from "./libraryV2/reducers/libraryGetRelatedTasks";
import libraryImport2Reducer from "./library/reducers/libraryImport2Reducer";
import fundsReducer from "./funds/reducers/fundsReducer";
import fundsLifeCycleStatusReducer from "./funds/reducers/fundsLifeCycleStatusReducer";
import fundsByTaskTemplateReducer from "./funds/reducers/fundsByTaskTemplateReducer";
import fundsDomicilesReducer from "./funds/reducers/fundsDomicilesReducer";
import fundsByCountryReducer from "./funds/reducers/fundsByCountryReducer";
import subfundsByCountryReducer from "./funds/reducers/subfundsByCountryReducer";
import subFundsByFundReducer from "./subFunds/reducers/subFundsByFundReducer";
import subFundsLifeCycleStatusReducer from "./subFunds/reducers/subFundsLifeCycleStatusReducer";
import subFundsCurrenciesReducer from "./subFunds/reducers/subFundsCurrenciesReducer";
import shareClassesBySubFundByFundReducer from "./shareClasses/reducers/shareClassesBySubFundByFundReducer";
import shareClassesBySubFundReducer from "./shareClasses/reducers/shareClassesBySubFundReducer";
import shareClassesLifeCycleStatusReducer from "./shareClasses/reducers/shareClassesLifeCycleStatusReducer";
import shareClassesCurrenciesReducer from "./shareClasses/reducers/shareClassesCurrenciesReducer";
import shareClassesRegistrationCountriesReducer from "./shareClasses/reducers/shareClassesRegistrationCountriesReducer";
import shareClassesRegistrationStatusReducer from "./shareClasses/reducers/shareClassesRegistrationStatusReducer";
import emailTemplatesReducer from "./emailTemplates/reducers/emailTemplatesReducer";
import emailTemplatesTagsReducer from "./emailTemplates/reducers/emailTemplatesTagsReducer";
import emailTemplatesCloneReducer from "./emailTemplates/reducers/emailTemplatesCloneReducer";
import emailTemplatesDeleteReducer from "./emailTemplates/reducers/emailTemplatesDeleteReducer";
import emailTemplatesTagsFileReducer from "./emailTemplates/reducers/emailTemplatesTagsFileReducer";
import emailTemplatesAddReducer from "./emailTemplates/reducers/emailTemplatesAddReducer";
import emailTemplatesEditReducer from "./emailTemplates/reducers/emailTemplatesEditReducer";
import emailTemplatesGetReducer from "./emailTemplates/reducers/emailTemplatesGetReducer";
import emailTemplatesDataReducer from "./emailTemplates/reducers/emailTemplatesDataReducer";
import emailTemplatesGetTaskTypeReducer from "./emailTemplates/reducers/emailTemplatesGetTaskTypeReducer";
import countriesReducer from "./countries/reducers/countriesReducer";
import countriesByRegionReducer from "./countries/reducers/countriesByRegionReducer";
import countriesByRegionWithCustomReducer from "./countries/reducers/countriesByRegionWithCustomReducer";
import taskTemplatesDataReducer from "./taskTemplates/reducers/taskTemplatesDataReducer";
import taskTemplatesSubTasksDataReducer from "./taskTemplates/reducers/taskTemplatesSubTasksDataReducer";
import recipientTypesReducer from "./recipientTypes/reducers/recipientTypesReducer";
import taskTypesReducer from "./tasks/reducers/taskTypesReducer";
import taskByTypesReducer from "./tasks/reducers/taskByTypesReducer";
import tasksDetailsReducer from "./tasks/reducers/tasksDetailsReducer";
import tasksCancelReducer from "./tasks/reducers/tasksCancelReducer";
import tasksDeleteReducer from "./tasks/reducers/tasksDeleteReducer";
import tasksGenerateDocumentReducer from "./tasks/reducers/tasksGenerateDocumentReducer";
import tasksMissingSubfundsReducer from "./tasks/reducers/tasksMissingSubfundsReducer";
import tasksStatusReducer from "./tasks/reducers/tasksStatusReducer";
import tasksAddReducer from "./tasks/reducers/tasksAddReducer";
import tasksAddDocRequiredReducer from "./tasks/reducers/tasksAddDocRequiredReducer";
import tasksAllFiltersReducer from "./tasks/reducers/tasksAllFiltersReducer";
import tasksExportExcelReducer from "./tasks/reducers/tasksExportExcelReducer";
import tasksDocExportExcelReducer from "./tasks/reducers/tasksDocExportExcelReducer";
import tasksExportPdfReducer from "./tasks/reducers/tasksExportPdfReducer";
import tasksStatusAvailableReducer from "./tasks/reducers/tasksStatusAvailableReducer";
import taskTemplatesByTaskTypeReducer from "./taskTemplates/reducers/taskTemplatesByTaskTypeReducer";
import taskTemplateDetailsReducer from "./taskTemplates/reducers/taskTemplateDetailsReducer";
import taskTemplateCountriesReducer from "./taskTemplates/reducers/taskTemplateCountriesReducer";
import taskTemplateDetailsEditReducer from "./taskTemplates/reducers/taskTemplateDetailsEditReducer";
import taskTemplateAddReducer from "./taskTemplates/reducers/taskTemplateAddReducer";
import taskTemplateCloneReducer from "./taskTemplates/reducers/taskTemplateCloneReducer";
import taskTemplateDeleteReducer from "./taskTemplates/reducers/taskTemplateDeleteReducer";
import taskTemplateSetAutoReducer from "./taskTemplates/reducers/taskTemplateSetAutoReducer";
import taskTemplatesSubTasksActionReducer from "./taskTemplates/reducers/taskTemplatesSubTasksActionReducer";
import taskTemplatesSubTasksConditionsReducer from "./taskTemplates/reducers/taskTemplatesSubTasksConditionsReducer";
import taskTemplateSubTaskDetailsReducer from "./taskTemplates/reducers/taskTemplateSubTaskDetailsReducer";
import documentsLanguagesReducer from "./documents/reducers/documentsLanguagesReducer";
import disseminationRuleAddReducer from "./dissemination/reducers/disseminationRuleAddReducer";
import disseminationDocumentRuleAddReducer from "./dissemination/reducers/disseminationDocumentRuleAddReducer";
import promotorsSetCurrentReducer from "./promotors/reducers/promotorsSetCurrentReducer";
import promotorsGetCurrentReducer from "./promotors/reducers/promotorsGetCurrentReducer";
import promotorsAddReducer from "./promotors/reducers/promotorsAddReducer";
import promotorsReducer from "./promotors/reducers/promotorsReducer";
import disseminationRuleReducer from "./dissemination/reducers/disseminationRuleReducer";
import disseminationDocumentRuleReducer from "./dissemination/reducers/disseminationDocumentRuleReducer";
import shareClassesISINReducer from "./shareClasses/reducers/shareClassesISINReducer";
import documentsSetMetadataReducer from "./documents/reducers/documentsSetMetadataReducer";
import documentsMetadataReducer from "./documents/reducers/documentsMetadataReducer";
import documentsSubTypesReducer from "./documents/reducers/documentsSubTypesReducer";
import documentsMetadataTypesReducer from "./documents/reducers/documentsMetadataTypesReducer";
import tasksReducer from "./tasks/reducers/tasksReducer";
import documentsDeleteReducer from "./documents/reducers/documentsDeleteReducer";
import documentsDownloadReducer from "./documents/reducers/documentsDownloadReducer";
import documentsDownloadMultipleReducer from "./documents/reducers/documentsDownloadMultipleReducer";
import documentsPreviewReducer from "./documents/reducers/documentsPreviewReducer";
import documentsSelectedReducer from "./documents/reducers/documentsSelectedReducer";
import documentsCheckExistingReducer from "./documents/reducers/documentsCheckExistingReducer";
import notificationsReducer from "./notifications/reducers/notificationsReducer";
import notificationsMarkAsReadReducer from "./notifications/reducers/notificationsMarkAsReadReducer";
import notificationsMarkAsDoneReducer from "./notifications/reducers/notificationsMarkAsDoneReducer";
import notificationsMarkAllAsDoneReducer from "./notifications/reducers/notificationsMarkAllAsDoneReducer";
import notificationsDeleteReducer from "./notifications/reducers/notificationsDeleteReducer";
import currenciesReducer from "./currencies/reducers/currenciesReducer";
import distributionMatrixStatusReducer from "./distributionMatrix/reducers/distributionMatrixStatusReducer";
import distributionMatrixUpdateReducer from "./distributionMatrix/reducers/distributionMatrixUpdateReducer";
import promotorsGetSettingsReducer from "./promotors/reducers/promotorsGetSettingsReducer";
import promotorsGetInfoReducer from "./promotors/reducers/promotorsGetInfoReducer";
import promotorsGetStaffReducer from "./promotors/reducers/promotorsGetStaffReducer";
import promotorsEditReducer from "./promotors/reducers/promotorsEditReducer";
import crmCompaniesReducer from "./users/reducers/crmCompaniesReducer";
import addUserReducer from "./users/reducers/addUserReducer";
import addPwCUserReducer from "./users/reducers/addPwCUserReducer";
import removeUserReducer from "./users/reducers/removeUserReducer";
import disseminationRuleCheckLockedReducer from "./dissemination/reducers/disseminationRuleCheckLockedReducer";
import disseminationRuleCleanReducer from "./dissemination/reducers/disseminationRuleCleanReducer";
import tasksValidateSubTaskReducer from "./tasks/reducers/tasksValidateSubTaskReducer";
import editStaffReducer from "./users/reducers/editStaffReducer";
import getExternalUsersReducer from "./users/reducers/getExternalUsersReducer";
import getAllRolesReducer from "./users/reducers/getAllRolesReducer";
import getAllExternalRolesReducer from "./users/reducers/getAllExternalRolesReducer";
import getUsersForPromotorReducer from "./users/reducers/getUsersForPromotorReducer";
import getUsersByMailReducer from "./users/reducers/getUsersByMailReducer";
import removeAccessReducer from "./users/reducers/removeAccessReducer";
import fundsByPromotorReducer from "./funds/reducers/fundsByPromotorReducer";
import createExternalUserReducer from "./users/reducers/createExternalUserReducer";
import createOrEditAccessReducer from "./users/reducers/createOrEditAccessReducer";
import distributionMatrixCheckEditLockedReducer from "./distributionMatrix/reducers/distributionMatrixCheckEditLockedReducer";
import entitiesReducer from "./entities/reducers/entitiesReducer";
import entitiesCreateReducer from "./entities/reducers/entitiesCreateReducer";
import entitiesEditReducer from "./entities/reducers/entitiesEditReducer";
import activitiesAddReducer from "./entities/reducers/activitiesAddReducer";
import activityAddForPromotorReducer from "./entities/reducers/activityAddForPromotorReducer";
import activitiesByEntityReducer from "./entities/reducers/activitiesByEntityReducer";
import activitiesByEntityForPromotorReducer from "./entities/reducers/activitiesByEntityForPromotorReducer";
import activitiesDeleteReducer from "./entities/reducers/activitiesDeleteReducer";
import activitiesReducer from "./entities/reducers/activitiesReducer";
import entitiesDeleteReducer from "./entities/reducers/entitiesDeleteReducer";
import entitiesDeleteForPromotorReducer from "./entities/reducers/entitiesDeleteForPromotorReducer";
import entitiesDetailsReducer from "./entities/reducers/entitiesDetailsReducer";
import entitiesForPromotorReducer from "./entities/reducers/entitiesForPromotorReducer";
import entitiesCreateForPromotorReducer from "./entities/reducers/entitiesCreateForPromotorReducer";
import entitiesCreateContactReducer from "./entities/reducers/entitiesCreateContactReducer";
import entitiesGetContactReducer from "./entities/reducers/entitiesGetContactReducer";
import entitiesDeleteContactReducer from "./entities/reducers/entitiesDeleteContactReducer";
import activityDeleteForPromotorReducer from "./entities/reducers/activityDeleteForPromotorReducer";
import legalFormReducer from "./entities/reducers/legalFormReducer";
import ftpServerReducer from "./dissemination/reducers/getFtpServerReducer";
import dueDiligenceRatingsReducer from './entities/reducers/dueDiligenceRatingsReducer';
import entitiesForPromotorDueDiligenceReducer from "./entities/reducers/entitiesForPromotorDueDiligenceReducer";
import entitiesCreateOrUpdateEntityPromoterDdRatingsReducer from "./entities/reducers/entitiesCreateOrUpdateEntityPromoterDdRatingsReducer";
import entitiesDeleteEntityPromoterDdRatingsReducer from "./entities/reducers/entitiesDeleteEntityPromoterDdRatingsReducer";

import reportsReducer from "./reports/reducers/reportsReducer";
import reportsCreateReducer from "./reports/reducers/reportsCreateReducer";
import reportsPeriodsReducer from "./reports/reducers/reportsPeriodsReducer";
import reportsRecurrencesReducer from "./reports/reducers/reportsRecurrencesReducer";
import reportsSettingsReducer from "./reports/reducers/reportsSettingsReducer";
import reportsTypesReducer from "./reports/reducers/reportsTypesReducer";

import libraryGetAuthorsReducer from "./libraryV2/reducers/libraryGetAuthorsReducer";
import libraryDocumentsReducer from "./libraryV2/reducers/libraryDocumentsReducer";
import libraryGetExceptionsReducer from "./libraryV2/reducers/libraryGetExceptionsReducer";
import libraryGetHistoryReducer from "./libraryV2/reducers/libraryGetHistoryReducer";
import libraryGetMissingMetadataReducer from "./libraryV2/reducers/libraryGetMissingMetadataReducer";
import libraryGetStatusReducer from "./libraryV2/reducers/libraryGetStatusReducer";
import libraryGetUnclassifiedReducer from "./libraryV2/reducers/libraryGetUnclassifiedReducer";
import libraryPostExceptionReducer from "./libraryV2/reducers/libraryPostExceptionReducer";
import libraryPostMissingMetadataReducer from "./libraryV2/reducers/libraryPostMissingMetadataReducer";
import libraryPostUnclassifiedReducer from "./libraryV2/reducers/libraryPostUnclassifiedReducer";
import libraryUploadFileReducer from "./libraryV2/reducers/libraryUploadFileReducer";
import libraryGetMetadataByTypeReducer from "./libraryV2/reducers/libraryGetMetadataByTypeReducer";
import libraryGetCountersReducer from "./libraryV2/reducers/libraryGetCountersReducer";
import documentSubTypesByDocumentTypeReducer from "./documentTypes/reducers/documentSubTypesByDocumentTypeReducer";
import taskTemplateTriggersReducer from "./taskTemplates/reducers/taskTemplateTriggersReducer";
import tasksForceDocCheckReducer from "./tasks/reducers/tasksForceDocCheckReducer";
import tasksRemoveDocLinkReducer from "./tasks/reducers/tasksRemoveDocLinkReducer";
import tasksRemoveDocRequiredReducer from "./tasks/reducers/tasksRemoveDocRequiredReducer";
import tasksSubtaskExportReducer from "./tasks/reducers/tasksSubtaskExportReducer";

import fundDistributionWatchGetNewsReducer from "./fundDistributionWatch/reducers/fundDistributionWatchGetNewsReducer";

import partGetReducer from "./marketingArrangement/reducers/partGetReducer";
import partSetReducer from "./marketingArrangement/reducers/partSetReducer";
import partsReducer from "./marketingArrangement/reducers/partsReducer";
import partStructureReducer from "./marketingArrangement/reducers/partStructureReducer";
import partGetExportListReducer from "./marketingArrangement/reducers/partGetExportListReducer";
import partGetExportReducer from "./marketingArrangement/reducers/partGetExportReducer";
import partCopyToReducer from "./marketingArrangement/reducers/partCopyToReducer";
import partCopyFromReducer from "./marketingArrangement/reducers/partCopyFromReducer";
import partMarkAsCompletedReducer from "./marketingArrangement/reducers/partMarkAsCompletedReducer";

import teamReducer from "./dashboard/reducers/teamReducer";
import publicationsUpdateReducer from "./publications/reducers/publicationsUpdateReducer";
import publicationsUpdateCoverReducer from "./publications/reducers/publicationsUpdateCoverReducer";
import publicationsUpdateFileReducer from "./publications/reducers/publicationsUpdateFileReducer";
import publicationsGetReducer from "./publications/reducers/publicationsGetReducer";
import publicationsDownloadReducer from "./publications/reducers/publicationsDownloadReducer";
import publicationsDeleteCoverReducer from "./publications/reducers/publicationsDeleteCoverReducer";
import publicationsDeleteFileReducer from "./publications/reducers/publicationsDeleteFileReducer";

import tasksByStatusReducer from "./tasks/reducers/tasksByStatusReducer";
import tasksByTypeReducer from "./tasks/reducers/tasksByTypeReducer";
import tasksDetailsForAddingDocReducer from "./tasks/reducers/tasksDetailsForAddingDocReducer";

import commentsPostTaskReducer from "./comments/reducers/commentsPostTaskReducer";
import commentsPostSubTaskReducer from "./comments/reducers/commentsPostSubTaskReducer";
import commentsUpdateTaskReducer from "./comments/reducers/commentsUpdateTaskReducer";
import commentsUpdateSubTaskReducer from "./comments/reducers/commentsUpdateSubTaskReducer";

import fundDistributionWatchStructureReducer from "./fundDistributionWatch/reducers/fundDistributionWatchStructureReducer";
import fundDistributionWatchStructureEditReducer from "./fundDistributionWatch/reducers/fundDistributionWatchStructureEditReducer";
import fundDistributionWatchExportReducer from "./fundDistributionWatch/reducers/fundDistributionWatchExportReducer";
import fundDistributionWatchImportReducer from "./fundDistributionWatch/reducers/fundDistributionWatchImportReducer";
import fundDistributionWatchCountriesByRegionReducer from "./fundDistributionWatch/reducers/fundDistributionWatchCountriesByRegionReducer";
import fundDistributionWatchSetCountriesForRegionReducer from "./fundDistributionWatch/reducers/fundDistributionWatchSetCountriesForRegionReducer";
import fundDistributionWatchGetCountriesForRegionReducer from "./fundDistributionWatch/reducers/fundDistributionWatchGetCountriesForRegionReducer";

import fundDistributionWatchTagsReducer from "./fundDistributionWatch/reducers/fundDistributionWatchTagsReducer";
import fundDistributionWatchTagsForCreateReducer from "./fundDistributionWatch/reducers/fundDistributionWatchTagsForCreateReducer";
import fundDistributionWatchTagsEditReducer from "./fundDistributionWatch/reducers/fundDistributionWatchTagsEditReducer";
import fundDistributionWatchTagsDeleteReducer from "./fundDistributionWatch/reducers/fundDistributionWatchTagsDeleteReducer";
import fundDistributionWatchTagsAddReducer from "./fundDistributionWatch/reducers/fundDistributionWatchTagsAddReducer";

import fundDistributionWatchContentReducer from "./fundDistributionWatch/reducers/fundDistributionWatchContentReducer";
import fundDistributionWatchContentEditReducer from "./fundDistributionWatch/reducers/fundDistributionWatchContentEditReducer";

import fundDetailsStructureReducer from "./fundDetails/reducer/fundDetailsStructureReducer";
import fundDetailsExportReducer from "./fundDetails/reducer/fundDetailsExportReducer";
import fundDetailsImportReducer from "./fundDetails/reducer/fundDetailsImportReducer";
import fundDetailsColumnsReducer from "./fundDetails/reducer/fundDetailsColumnsReducer";
import fundDetailsColumnsSaveReducer from "./fundDetails/reducer/fundDetailsColumnsSaveReducer";

import promotorsGetCountriesFDWReducer from "./promotors/reducers/promotorsGetCountriesFDWReducer";
import promotorsSetCountriesFDWReducer from "./promotors/reducers/promotorsSetCountriesFDWReducer";

import documentTemplatesReducer from "./documentTemplates/reducer/documentTemplatesReducer";
import documentTemplatesTagsReducer from "./documentTemplates/reducer/documentTemplatesTagsReducer";
import documentTemplatesDataReducer from "./documentTemplates/reducer/documentTemplatesDataReducer";
import documentTemplatesGetReducer from "./documentTemplates/reducer/documentTemplatesGetReducer";
import documentTemplatesAddReducer from "./documentTemplates/reducer/documentTemplatesAddReducer";
import documentTemplatesCloneReducer from "./documentTemplates/reducer/documentTemplatesCloneReducer";
import documentTemplatesDeleteReducer from "./documentTemplates/reducer/documentTemplatesDeleteReducer";
import documentTemplatesEditReducer from "./documentTemplates/reducer/documentTemplatesEditReducer";

import documentTemplatesRuleDataReducer from "./documentTemplates/reducer/documentTemplatesRuleDataReducer";
import disseminationDocumentModificationRuleAddReducer from "./dissemination/reducers/disseminationDocumentModificationRuleAddReducer";
import disseminationDocumentModificationRuleReducer from "./dissemination/reducers/disseminationDocumentModificationRuleReducer";

const rootReducer = combineReducers({
  routing,
  authentificationReducer,
  disseminationDocumentModificationRuleAddReducer,
  disseminationDocumentModificationRuleReducer,
  ftpServerReducer,
  promotorsGetCountriesFDWReducer,
  documentTemplatesRuleDataReducer,
  promotorsSetCountriesFDWReducer,
  fundDistributionWatchContentReducer,
  fundDistributionWatchContentEditReducer,
  fundDistributionWatchTagsReducer,
  fundDistributionWatchTagsForCreateReducer,
  fundDistributionWatchTagsEditReducer,
  fundDistributionWatchTagsDeleteReducer,
  fundDistributionWatchTagsAddReducer,
  fundDistributionWatchCountriesByRegionReducer,
  fundDistributionWatchGetCountriesForRegionReducer,
  fundDistributionWatchSetCountriesForRegionReducer,
  fundDetailsColumnsReducer,
  fundDetailsColumnsSaveReducer,
  fundDetailsExportReducer,
  fundDetailsImportReducer,
  fundDetailsStructureReducer,
  fundDistributionWatchExportReducer,
  fundDistributionWatchImportReducer,
  fundDistributionWatchStructureReducer,
  fundDistributionWatchStructureEditReducer,
  distributionMatrixImportFundsquareReducer,
  distributionMatrixImportFundsquareCSVReducer,
  distributionMatrixImportUpdateFundsquareReducer,
  distributionMatrixImportUpdateFundsquareCSVReducer,
  commentsPostTaskReducer,
  commentsPostSubTaskReducer,
  commentsUpdateTaskReducer,
  commentsUpdateSubTaskReducer,
  partCopyToReducer,
  disseminationRuleCleanReducer,
  partCopyFromReducer,
  partMarkAsCompletedReducer,
  tasksStatusAvailableReducer,
  publicationsDeleteCoverReducer,
  publicationsDeleteFileReducer,
  tasksByStatusReducer,
  tasksByTypeReducer,
  reportsReducer,
  publicationsUpdateReducer,
  publicationsUpdateCoverReducer,
  publicationsUpdateFileReducer,
  publicationsGetReducer,
  publicationsDownloadReducer,
  reportsCreateReducer,
  reportsPeriodsReducer,
  reportsRecurrencesReducer,
  reportsSettingsReducer,
  reportsTypesReducer,
  logOffReducer,
  distributionMatrixReducer,
  distributionMatrixTaskDetailsReducer,
  distributionMatrixMapReducer,
  distributionMatrixImportReducer,
  distributionMatrixFlushReducer,
  documentsByMonthReducer,
  documentTypesReducer,
  documentTypesByFundReducer,
  libraryImportReducer,
  libraryImport2Reducer,
  fundsReducer,
  fundsLifeCycleStatusReducer,
  fundsByTaskTemplateReducer,
  fundsDomicilesReducer,
  fundsByCountryReducer,
  subfundsByCountryReducer,
  subFundsByFundReducer,
  subFundsLifeCycleStatusReducer,
  subFundsCurrenciesReducer,
  shareClassesBySubFundByFundReducer,
  shareClassesLifeCycleStatusReducer,
  shareClassesCurrenciesReducer,
  shareClassesRegistrationCountriesReducer,
  shareClassesRegistrationStatusReducer,
  emailTemplatesReducer,
  emailTemplatesTagsReducer,
  emailTemplatesCloneReducer,
  emailTemplatesDeleteReducer,
  emailTemplatesTagsFileReducer,
  emailTemplatesDataReducer,
  emailTemplatesGetTaskTypeReducer,
  countriesReducer,
  emailTemplatesAddReducer,
  taskTemplatesDataReducer,
  taskTemplatesSubTasksDataReducer,
  recipientTypesReducer,
  taskTypesReducer,
  taskByTypesReducer,
  taskTemplatesByTaskTypeReducer,
  taskTemplateDetailsReducer,
  taskTemplateAddReducer,
  taskTemplateCloneReducer,
  taskTemplateDeleteReducer,
  taskTemplatesSubTasksActionReducer,
  taskTemplatesSubTasksConditionsReducer,
  taskTemplateSubTaskDetailsReducer,
  tasksSubtaskExportReducer,
  documentsLanguagesReducer,
  disseminationRuleAddReducer,
  disseminationDocumentRuleAddReducer,
  promotorsSetCurrentReducer,
  promotorsReducer,
  disseminationRuleReducer,
  disseminationDocumentRuleReducer,
  shareClassesISINReducer,
  shareClassesBySubFundReducer,
  documentsSetMetadataReducer,
  documentsMetadataReducer,
  tasksReducer,
  documentsDeleteReducer,
  documentsDownloadReducer,
  documentsPreviewReducer,
  documentsSelectedReducer,
  documentsDownloadMultipleReducer,
  distributionMatrixFiltersReducer,
  distributionMatrixAllFiltersReducer,
  distributionMatrixFiltersStatsReducer,
  documentsCheckExistingReducer,
  emailTemplatesGetReducer,
  emailTemplatesEditReducer,
  distributionMatrixExportExcelReducer,
  taskTemplateDetailsEditReducer,
  distributionMatrixDateReducer,
  tasksDetailsReducer,
  tasksStatusReducer,
  tasksAllFiltersReducer,
  tasksExportExcelReducer,
  tasksDocExportExcelReducer,
  tasksExportPdfReducer,
  taskTemplateSetAutoReducer,
  documentsMetadataTypesReducer,
  notificationsReducer,
  notificationsMarkAsReadReducer,
  notificationsDeleteReducer,
  acceptGtcReducer,
  notificationsMarkAsDoneReducer,
  libraryYearsReducer,
  tasksAddReducer,
  tasksAddDocRequiredReducer,
  promotorsAddReducer,
  distributionMatrixExportPDFReducer,
  distributionMatrixExportCSVOpenfundsReducer,
  documentsSubTypesReducer,
  currenciesReducer,
  distributionMatrixStatusReducer,
  distributionMatrixUpdateReducer,
  promotorsGetSettingsReducer,
  promotorsGetInfoReducer,
  promotorsGetStaffReducer,
  promotorsEditReducer,
  crmCompaniesReducer,
  addUserReducer,
  addPwCUserReducer,
  removeUserReducer,
  disseminationRuleCheckLockedReducer,
  tasksValidateSubTaskReducer,
  promotorsGetCurrentReducer,
  distributionMatrixImportUpdateReducer,
  taskTemplateCountriesReducer,
  notificationsMarkAllAsDoneReducer,
  editStaffReducer,
  getExternalUsersReducer,
  getAllRolesReducer,
  getAllExternalRolesReducer,
  getUsersForPromotorReducer,
  getUsersByMailReducer,
  removeAccessReducer,
  fundsByPromotorReducer,
  createExternalUserReducer,
  createOrEditAccessReducer,
  distributionMatrixCheckEditLockedReducer,
  countriesByRegionReducer,
  countriesByRegionWithCustomReducer,
  entitiesReducer,
  entitiesCreateReducer,
  entitiesEditReducer,
  activitiesAddReducer,
  activityAddForPromotorReducer,
  activitiesByEntityReducer,
  activitiesDeleteReducer,
  activitiesReducer,
  entitiesDeleteReducer,
  libraryGetAuthorsReducer,
  libraryDocumentsReducer,
  libraryGetExceptionsReducer,
  libraryGetHistoryReducer,
  libraryGetMissingMetadataReducer,
  libraryGetStatusReducer,
  libraryGetUnclassifiedReducer,
  libraryGetRelatedTasksReducer,
  libraryPostExceptionReducer,
  libraryPostMissingMetadataReducer,
  libraryPostUnclassifiedReducer,
  libraryUploadFileReducer,
  libraryGetMetadataByTypeReducer,
  libraryGetCountersReducer,
  documentSubTypesByDocumentTypeReducer,
  tasksDetailsForAddingDocReducer,
  taskTemplateTriggersReducer,
  fundDistributionWatchGetNewsReducer,
  partGetReducer,
  partSetReducer,
  partsReducer,
  partStructureReducer,
  partGetExportListReducer,
  partGetExportReducer,
  legalFormReducer,
  entitiesDetailsReducer,
  entitiesForPromotorReducer,
  entitiesCreateForPromotorReducer,
  entitiesDeleteForPromotorReducer,
  activitiesByEntityForPromotorReducer,
  entitiesCreateContactReducer,
  entitiesDeleteContactReducer,
  entitiesGetContactReducer,
  activityDeleteForPromotorReducer,
  dueDiligenceRatingsReducer,
  entitiesForPromotorDueDiligenceReducer,
  entitiesCreateOrUpdateEntityPromoterDdRatingsReducer,
  entitiesDeleteEntityPromoterDdRatingsReducer,
  teamReducer,
  documentTemplatesReducer,
  documentTemplatesDataReducer,
  documentTemplatesTagsReducer,
  documentTemplatesGetReducer,
  documentTemplatesAddReducer,
  documentTemplatesCloneReducer,
  documentTemplatesDeleteReducer,
  documentTemplatesEditReducer,
  tasksCancelReducer,
  tasksDeleteReducer,
  tasksGenerateDocumentReducer,
  tasksMissingSubfundsReducer,
  tasksForceDocCheckReducer,
  tasksRemoveDocLinkReducer,
  tasksRemoveDocRequiredReducer,
});

export default rootReducer;
