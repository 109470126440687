import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import TasksApi from "../../../models/TasksApi";

export function loadTasksByType(filters) {
  let action = new ActionRequest(
    TasksApi.loadTasksByType,
    loadTasksByTypeInit,
    loadTasksByTypeSuccess,
    loadTasksByTypeFailure
  );
  return action.run({
    routeParameters: { filters },
    actionParameters: { filters },
  });
}

function loadTasksByTypeSuccess(tasksByTypeData, params) {
  return {
    type: types.GET_TASKS_BY_TYPE_SUCCESS,
    data: tasksByTypeData,
  };
}

function loadTasksByTypeFailure(error) {
  return {
    type: types.GET_TASKS_BY_TYPE_FAILURE,
    notification: {
      level: "error",
      label: "[action:tasksByType>loadTasksByType]",
      message: "Impossible de récupérer la liste des tasksByType",
      system_message: error.message,
    },
  };
}

function loadTasksByTypeInit(params) {
  return { type: types.GET_TASKS_BY_TYPE_INIT };
}
