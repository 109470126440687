/* eslint-disable no-useless-escape */
import fetch from "isomorphic-fetch";

import storeProvider from "../../storeProvider";

import download from "downloadjs/download";

class Request {
  constructor(url) {
    this.url = url;
  }

  get(signal) {
    return this.send("get", null, signal);
  }

  delete(data) {
    return this.send("delete", data);
  }

  post(data, signal) {
    return this.send("post", data, signal);
  }

  put(data) {
    return this.send("put", data);
  }

  _download(data, name, method) {
    const state = storeProvider.getStore().getState();
    let token = state.authentificationReducer.data.token;
    if (data) data = JSON.stringify(data);
    let fetchReq;
    if (data)
      fetchReq = fetch(this.url, {
        method,
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          RequestVerificationToken: token,
        },
        body: data,
      })
        .then((response) => {
          return response.blob();
        })
        .then(function (blob) {
          download(
            blob,
            name,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        });
    else
      fetchReq = fetch(this.url, {
        method,
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          RequestVerificationToken: token,
        },
      })
        .then((response) => {
          return response.blob();
        })
        .then(function (blob) {
          download(
            blob,
            name,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        });

    return fetchReq;
  }

  downloadWithPost(data, name) {
    return this._download(data, name, "post");
  }

  downloadWithGet(data, name) {
    return this._download(data, name, "get");
  }

  downloadWithPostPdf(data, name) {
    const state = storeProvider.getStore().getState();
    let token = state.authentificationReducer.data.token;
    if (data) data = JSON.stringify(data);
    let fetchReq;
    if (data)
      fetchReq = fetch(this.url, {
        method: "post",
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          RequestVerificationToken: token,
        },
        body: data,
      })
        .then((response) => {
          return response.blob();
        })
        .then(function (blob) {
          download(blob, name, "application/pdf");
        });
    else
      fetchReq = fetch(this.url, {
        method: "post",
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          RequestVerificationToken: token,
        },
      })
        .then((response) => {
          return response.blob();
        })
        .then(function (blob) {
          download(blob, name, "application/pdf");
        });

    return fetchReq;
  }

  upload(data, method = "post") {
    const state = storeProvider.getStore().getState();
    let token = state.authentificationReducer.data.token;
    let fetchReq;
    if (data)
      fetchReq = fetch(this.url, {
        method,
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          RequestVerificationToken: token,
        },
        body: data,
      }).then((response) => {
        return response.json();
      });
    else
      fetchReq = fetch(this.url, {
        method,
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          RequestVerificationToken: token,
        },
      }).then((response) => {
        return response.json();
      });
    return fetchReq;
  }

  uploadLibrary(data, fundId, documentType, withAutoReplace, documentDate) {
    const state = storeProvider.getStore().getState();
    let token = state.authentificationReducer.data.token;
    let fetchReq;
    if (data)
      fetchReq = fetch(
        this.url +
          "?upload=true" +
          (fundId ? "&fundId=" + fundId : "") +
          (documentType ? "&documentType=" + documentType : "") +
          (withAutoReplace ? "&withAutoReplace=true" : "") +
          (documentDate ? "&documentDateStr=" + documentDate : ""),
        {
          method: "post",
          credentials: "include",
          mode: "cors",
          headers: {
            Accept: "application/json",
            RequestVerificationToken: token,
          },
          body: data,
        }
      ).then((response) => {
        return response.json();
      });
    else
      fetchReq = fetch(
        this.url +
          "?upload=true" +
          (fundId ? "&fundId=" + fundId : "") +
          (documentType ? "&documentType=" + documentType : "") +
          (withAutoReplace ? "&withAutoReplace=true" : "") +
          (documentDate ? "&documentDateStr=" + documentDate : ""),
        {
          method: "post",
          credentials: "include",
          mode: "cors",
          headers: {
            Accept: "application/json",
            RequestVerificationToken: token,
          },
        }
      ).then((response) => {
        return response.json();
      });
    return fetchReq;
  }

  send(method, data = null, signal) {
    const state = storeProvider.getStore().getState();
    let token = state.authentificationReducer.data.token;
    if (data) data = JSON.stringify(data);
    let params = {
      //credentials: 'include',
      method: method,
      credentials: "include",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        RequestVerificationToken: token,
      },
    };
    if (data) params.body = data;
    if (signal) params.signal = signal;

    return fetch(this.url, params).then((response) => response.json());
  }

  download(data, strFileName, strMimeType) {
    var self = window, // this script is only for browsers anyway...
      u = "application/octet-stream", // this default mime also triggers iframe downloads
      m = strMimeType || u,
      x = data,
      D = document,
      a = D.createElement("a"),
      z = function (a) {
        return String(a);
      },
      B = self.Blob || self.MozBlob || self.WebKitBlob || z,
      BB = self.MSBlobBuilder || self.WebKitBlobBuilder || self.BlobBuilder,
      fn = strFileName || "download",
      blob,
      b,
      fr;

    //if(typeof B.bind === 'function' ){ B=B.bind(self); }

    if (String(this) === "true") {
      //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
      x = [x, m];
      m = x[0];
      x = x[1];
    }

    //go ahead and download dataURLs right away
    if (String(x).match(/^data\:[\w+\-]+\/[\w+\-]+[,;]/)) {
      return navigator.msSaveBlob // IE10 can't do a[download], only Blobs:
        ? navigator.msSaveBlob(d2b(x), fn)
        : saver(x); // everyone else can save dataURLs un-processed
    } //end if dataURL passed?

    try {
      blob = x instanceof B ? x : new B([x], { type: m });
    } catch (y) {
      if (BB) {
        b = new BB();
        b.append([x]);
        blob = b.getBlob(m); // the blob
      }
    }

    function d2b(u) {
      var p = u.split(/[:;,]/),
        t = p[1],
        dec = p[2] == "base64" ? atob : decodeURIComponent,
        bin = dec(p.pop()),
        mx = bin.length,
        i = 0,
        uia = new Uint8Array(mx);

      for (i; i < mx; ++i) uia[i] = bin.charCodeAt(i);

      return new B([uia], { type: t });
    }

    function saver(url, winMode) {
      if ("download" in a) {
        //html5 A[download]
        a.href = url;
        a.setAttribute("download", fn);
        a.innerHTML = "downloading...";
        D.body.appendChild(a);
        setTimeout(function () {
          a.click();
          D.body.removeChild(a);
          if (winMode === true) {
            setTimeout(function () {
              self.URL.revokeObjectURL(a.href);
            }, 250);
          }
        }, 66);
        return true;
      }

      //do iframe dataURL download (old ch+FF):
      var f = D.createElement("iframe");
      D.body.appendChild(f);
      if (!winMode) {
        // force a mime that will download:
        url = "data:" + url.replace(/^data:([\w\/\-\+]+)/, u);
      }

      f.src = url;
      setTimeout(function () {
        D.body.removeChild(f);
      }, 333);
    } //end saver

    if (navigator.msSaveBlob) {
      // IE10+ : (has Blob, but not a[download] or URL)
      return navigator.msSaveBlob(blob, fn);
    }

    if (self.URL) {
      // simple fast and modern way using Blob and URL:
      saver(self.URL.createObjectURL(blob), true);
    } else {
      // handle non-Blob()+non-URL browsers:
      if (typeof blob === "string" || blob.constructor === z) {
        try {
          return saver("data:" + m + ";base64," + self.btoa(blob));
        } catch (y) {
          return saver("data:" + m + "," + encodeURIComponent(blob));
        }
      }

      // Blob but not URL:
      fr = new FileReader();
      fr.onload = function (e) {
        saver(this.result);
      };
      fr.readAsDataURL(blob);
    }
    return true;
  }
}

export default Request;
