// react dependencies
import React, { Component } from "react";
import { __ } from "../../utils";
import onClickOutside from "react-onclickoutside";

import { Scrollbars } from "react-custom-scrollbars";

import Alert from "../../components/alert/Alert";

class NotificationCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      notificationCenter: false,
      notificationsCenterHeight:
        Math.max(document.documentElement.clientHeight, window.innerHeight) ||
        0,
    };
  }

  UNSAFE_componentWillMount = () => {
    window.addEventListener("resize", this.handleResizeWindow);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResizeWindow);
  };

  handleResizeWindow = () => {
    this.setState({
      notificationsCenterHeight:
        Math.max(document.documentElement.clientHeight, window.innerHeight) ||
        0,
    });
  };

  handleClickOutside = (evt) => {
    this.setState({ notificationCenter: false });
  };

  onClickNotificationCenterContent = (e) => {
    e.stopPropagation();
  };

  onClickNotificationCenter = () => {
    this.props.onClick();
    this.setState({
      notificationCenter: !this.state.notificationCenter,
    });
  };

  renderChildren = () => {
    return React.Children.map(this.props.children, (child, index) => {
      if (child)
        return React.cloneElement(child, {
          hide: this.handleClickOutside,
        });
    });
  };

  cleanAll = () => {
    this.setState({
      modal: true,
    });
  };

  confirmCleanAll = () => {
    this.setState({
      modal: false,
    });
    this.props.markAllNotificationsAsDone();
  };

  render() {
    return (
      <i
        onClick={this.onClickNotificationCenter}
        className="pwc-time-outline-alarm icon-notification"
      >
        {this.props.notificationsCounter > 0 ? (
          <div className="main-header-notifications-counter">
            {this.props.notificationsCounter}
          </div>
        ) : null}
        <div
          onClick={(e) => this.onClickNotificationCenterContent(e)}
          className={
            "notification-center " +
            (this.state.notificationCenter
              ? "notification-center--visible"
              : "")
          }
        >
          <div
            style={{
              minHeight: "400px",
              height: this.state.notificationsCenterHeight - 500 + "px",
              marginTop:
                "-" +
                (this.state.notificationsCenterHeight > 420
                  ? this.state.notificationsCenterHeight
                  : 420) +
                "px",
            }}
            className={
              "notification-center-container " +
              (this.state.notificationCenter
                ? "notification-center-container--visible"
                : "")
            }
          >
            <div className="notification-center-title">
              <div>{__("__NOTIFICATIONS_")}</div>
              <span onClick={this.cleanAll}>Clear all</span>
            </div>
            <Scrollbars>
              <div className="notification-center-content">
                {this.props.children.length ? (
                  this.renderChildren()
                ) : (
                  <div className="notification-center-content-empty">
                    {__("__NO_NOTIFICATION_YET_")}
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>
        </div>
        <Alert
          close={() => this.setState({ modal: false })}
          confirm={this.confirmCleanAll}
          title={"Clear all notifications"}
          message={"Are you sure?"}
          show={this.state.modal}
        />
      </i>
    );
  }
}
export default onClickOutside(NotificationCenter);
