import Request from "../utils/requests/request";

export default class DistributionMatrixApi {
  static loadDistributionMatrixTaskDetails(
    params,
    successCallback,
    failCallback
  ) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Tasks/RegMatrixForTask/" +
        params.taskId +
        "?countryId=" +
        params.countryId
    );
    return loadReq.get();
  }

  static loadDistributionMatrix(params, successCallback, failCallback) {
    let loadReq;
    if (params.filters.exclusive)
      loadReq = new Request(
        process.env.REACT_APP_API_BASEURL +
          "RegistrationMatrix/RegMatrixFilteredExclusively"
      );
    else
      loadReq = new Request(
        process.env.REACT_APP_API_BASEURL + "RegistrationMatrix/RegMatrix"
      );
    return loadReq.post(params.filters);
  }

  static importDistributionMatrix(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/UploadInitialF2CMatrix"
    );
    let formData = new FormData();
    for (let i in params.files) {
      formData.append("file", params.files[i], params.files[i].name);
    }
    return loadReq.upload(formData);
  }

  static importFundsquareDistributionMatrix(
    params,
    successCallback,
    failCallback
  ) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/UploadInitialFundSquareMatrixExcel"
    );
    let formData = new FormData();
    for (let i in params.files) {
      formData.append("file", params.files[i], params.files[i].name);
    }
    return loadReq.upload(formData);
  }

  static importFundsquareCSVDistributionMatrix(
    params,
    successCallback,
    failCallback
  ) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/UploadInitialFundSquareMatrixCSV"
    );
    let formData = new FormData();
    for (let i in params.files) {
      formData.append("file", params.files[i], params.files[i].name);
    }
    return loadReq.upload(formData);
  }

  static importUpdateFundsquareDistributionMatrix(
    params,
    successCallback,
    failCallback
  ) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/UploadUpdateFundSquareMatrixExcel?uploadUpdateType=1"
    );
    let formData = new FormData();
    for (let i in params.files) {
      formData.append("file", params.files[i], params.files[i].name);
    }
    return loadReq.upload(formData);
  }

  static importUpdateFundsquareCSVDistributionMatrix(
    params,
    successCallback,
    failCallback
  ) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/UploadUpdateFundSquareMatrixCSV?uploadUpdateType=1"
    );
    let formData = new FormData();
    for (let i in params.files) {
      formData.append("file", params.files[i], params.files[i].name);
    }
    return loadReq.upload(formData);
  }

  static importUpdateDistributionMatrix(params, successCallback, failCallback) {
    let uploadUpdateType = 1;
    if (params.statusesOnly) uploadUpdateType = 2;
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/UploadUpdateF2CMatrix?uploadUpdateType=" +
        uploadUpdateType
    );
    let formData = new FormData();
    for (let i in params.files) {
      formData.append("file", params.files[i], params.files[i].name);
    }
    return loadReq.upload(formData);
  }

  static loadDistributionMatrixFilters(params, successCallback, failCallback) {
    let loadReq;
    if (params.filters && params.filters.CustomColumnFilterId) {
      loadReq = new Request(
        process.env.REACT_APP_API_BASEURL +
          "FundDetails/FundDetailsFiltersEnabled"
      );
    } else {
      loadReq = new Request(
        process.env.REACT_APP_API_BASEURL +
          "RegistrationMatrix/RegMatrixFiltersEnabledLight"
      );
    }
    return loadReq.post(params.filters);
  }

  static loadDistributionMatrixAllFilters(
    params,
    successCallback,
    failCallback
  ) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/RegMatrixAllFilters"
    );
    return loadReq.get();
  }

  static loadDistributionMatrixFiltersStats(
    params,
    successCallback,
    failCallback
  ) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "RegistrationMatrix/RegMatrixStat"
    );
    return loadReq.post(params.filters);
  }

  static loadDistributionMatrixExportExcel(
    params,
    successCallback,
    failCallback
  ) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "RegistrationMatrix/DownloadRegMatrix"
    );
    return loadReq.downloadWithPost(params.filters, params.name);
  }

  static loadDistributionMatrixExportPDF(
    params,
    successCallback,
    failCallback
  ) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/DownloadRegMatrixPDF"
    );
    return loadReq.downloadWithPostPdf(params.filters, params.name);
  }

  static loadDistributionMatrixExportCSVOpenfunds(
    params,
    successCallback,
    failCallback
  ) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/DownloadRegMatrixCSVOpenfund"
    );
    return loadReq.downloadWithPostPdf(params.filters, params.name);
  }

  static loadDistributionMatrixDate() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/RegMatrixLastUpdateDate"
    );
    return loadReq.get();
  }

  static loadDistributionMatrixFlush() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "RegistrationMatrix/FlushRegMatrix"
    );
    return loadReq.get();
  }

  static loadDistributionMatrixStatus() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "LifeCycleStatus/Get"
    );
    return loadReq.get();
  }

  static loadDistributionMatrixUpdate(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "RegistrationMatrix/UpdateMatrix"
    );
    return loadReq.post(params.data);
  }

  static getRegistrationTypeAllowed(params) {
    if (params.length == 0) {
      return Promise.resolve([]);
    }
    const queryStringParams = `?fundTypesId=${params[0]}&${params
      .slice(1)
      .map((key) => `&fundTypesId=${key}`)
      .join("")}`;
    return new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/GetRegistrationTypeAllowed" +
        queryStringParams
    ).get();
  }
}
