export const GET_REPORTS_INIT = "REPORTS:GET_INIT";
export const GET_REPORTS_SUCCESS = "REPORTS:GET_SUCCESS";
export const GET_REPORTS_FAILURE = "REPORTS:GET_FAIL";

export const GET_REPORTS_CREATE_INIT = "REPORTS_CREATE:GET_INIT";
export const GET_REPORTS_CREATE_SUCCESS = "REPORTS_CREATE:GET_SUCCESS";
export const GET_REPORTS_CREATE_FAILURE = "REPORTS_CREATE:GET_FAIL";

export const GET_REPORTS_PERIODS_INIT = "REPORTS_PERIODS:GET_INIT";
export const GET_REPORTS_PERIODS_SUCCESS = "REPORTS_PERIODS:GET_SUCCESS";
export const GET_REPORTS_PERIODS_FAILURE = "REPORTS_PERIODS:GET_FAIL";

export const GET_REPORTS_RECURRENCES_INIT = "REPORTS_RECURRENCES:GET_INIT";
export const GET_REPORTS_RECURRENCES_SUCCESS =
  "REPORTS_RECURRENCES:GET_SUCCESS";
export const GET_REPORTS_RECURRENCES_FAILURE = "REPORTS_RECURRENCES:GET_FAIL";

export const GET_REPORTS_SETTINGS_INIT = "REPORTS_SETTINGS:GET_INIT";
export const GET_REPORTS_SETTINGS_SUCCESS = "REPORTS_SETTINGS:GET_SUCCESS";
export const GET_REPORTS_SETTINGS_FAILURE = "REPORTS_SETTINGS:GET_FAIL";

export const GET_REPORTS_TYPES_INIT = "REPORTS_TYPES:GET_INIT";
export const GET_REPORTS_TYPES_SUCCESS = "REPORTS_TYPES:GET_SUCCESS";
export const GET_REPORTS_TYPES_FAILURE = "REPORTS_TYPES:GET_FAIL";
