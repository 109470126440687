import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import PromotorsApi from "../../../models/PromotorsApi";

export function loadPromotors(promotorId) {
  let action = new ActionRequest(
    PromotorsApi.loadPromotors,
    loadPromotorsInit,
    loadPromotorsSuccess,
    loadPromotorsFailure
  );
  return action.run({
    routeParameters: { promotorId },
    actionParameters: { promotorId },
  });
}

function loadPromotorsSuccess(promotorsData, params) {
  return {
    type: types.GET_PROMOTORS_SUCCESS,
    data: promotorsData,
  };
}

function loadPromotorsFailure(error) {
  return {
    type: types.GET_PROMOTORS_FAILURE,
    notification: {
      level: "error",
      label: "[action:promotors>loadPromotors]",
      message: "Impossible de récupérer la liste des promotors",
      system_message: error.message,
    },
  };
}

function loadPromotorsInit(params) {
  return { type: types.GET_PROMOTORS_INIT };
}
