import { lang } from "../lang";

/*=====----- GETTING INTERNATIONAL LABELS -----=====*/

export default function __(constant, language = "en") {
  const label = lang[constant];
  if (label) {
    if (language in label) {
      return label[language];
    } else return label["en"]; // english fallback if language not exist for the label
  } else {
    return constant;
  }
}
