// react dependencies
import React, { Component } from "react";

class UserCenterItem extends Component {
  render() {
    return (
      <li>
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a onClick={this.props.onClick} href="#">
          {this.props.label}
        </a>
      </li>
    );
  }
}
export default UserCenterItem;
