import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import LibraryV2Api from "../../../models/LibraryV2Api";

export function loadLibraryGetCounters() {
  let action = new ActionRequest(
    LibraryV2Api.loadLibraryGetCounters,
    loadLibraryGetCountersInit,
    loadLibraryGetCountersSuccess,
    loadLibraryGetCountersFailure
  );
  return action.run({
    routeParameters: {},
    actionParameters: {},
  });
}

function loadLibraryGetCountersSuccess(librayImportData, params) {
  return {
    type: types.LIBRARY_GET_COUNTERS_SUCCESS,
    data: librayImportData,
  };
}

function loadLibraryGetCountersFailure(error) {
  return {
    type: types.LIBRARY_GET_COUNTERS_FAILURE,
    notification: {
      level: "error",
      label: "[action:librayImport>loadLibraryGetCounters]",
      message: "Impossible de récupérer la liste des librayImport",
      system_message: error.message,
    },
  };
}

function loadLibraryGetCountersInit(params) {
  return { type: types.LIBRARY_GET_COUNTERS_INIT };
}
