import * as types from '../constants';
import ActionRequest from '../../../utils/requests/actionRequest';

export function loadLogOff() {
  let action = new ActionRequest(
    loadLogOffInit,
    loadLogOffSuccess,
    loadLogOffFailure
  );
  return action.run(
    {
      routeParameters: {},
      actionParameters: {},
    }
  );
}

function loadLogOffSuccess(contactsData, params) {
  return {
    type: types.LOG_OFF_SUCCESS,
    data: contactsData,
  };
}

function loadLogOffFailure(error) {
  return {
    type: types.LOG_OFF_FAILURE,
    notification: { level: "error", label: "[action:task>loadLogOff]", message: "Impossible de se déconnecter", system_message: error.message }
  }
}

function loadLogOffInit(params) {
  return { type: types.LOG_OFF_INIT };
}
