import * as types from '../constants';

const initialState = {
  isLoading: true,
  error: null,
  items: []
}

export default function dueDiligenceRatingsReducer(state = initialState, action) {
  switch (action.type) {
  
    case types.GET_ENTITIES_DUE_DILIGENCE_RATINGS_INIT:
      return { ...state, error: null, isLoading: true };

    case types.GET_ENTITIES_DUE_DILIGENCE_RATINGS_SUCCESS:
      return { ...state, isLoading: false, items: action.data.data};

    case types.GET_ENTITIES_DUE_DILIGENCE_RATINGS_FAILURE:
      return { ...state, isLoading: false, error: action.notification.system_message };

    default:
      return state;
  }
}
