import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import FundDetailsApi from "../../../models/FundDetailsApi";

export function loadFundDetailsStructure(filters) {
  let action = new ActionRequest(
    FundDetailsApi.loadFundDetailsStructure,
    loadFundDetailsStructureInit,
    loadFundDetailsStructureSuccess,
    loadFundDetailsStructureFailure
  );
  return action.run({
    routeParameters: { filters },
    actionParameters: { filters },
  });
}

function loadFundDetailsStructureSuccess(
  fundDetailsStructureStructureData,
  params
) {
  return {
    type: types.GET_FUND_DETAILS_STRUCTURE_SUCCESS,
    data: fundDetailsStructureStructureData,
  };
}

function loadFundDetailsStructureFailure(error) {
  return {
    type: types.GET_FUND_DETAILS_STRUCTURE_FAILURE,
    notification: {
      level: "error",
      label: "[action:fundDetailsStructureStructure>loadFundDetailsStructure]",
      message:
        "Impossible de récupérer la liste des fundDetailsStructureStructure",
      system_message: error.message,
    },
  };
}

function loadFundDetailsStructureInit(params) {
  return { type: types.GET_FUND_DETAILS_STRUCTURE_INIT };
}
