export const GET_DISSEMINATION_RULE_ADD_INIT =
  "DISSEMINATION_RULE_ADD:GET_INIT";
export const GET_DISSEMINATION_RULE_ADD_SUCCESS =
  "DISSEMINATION_RULE_ADD:GET_SUCCESS";
export const GET_DISSEMINATION_RULE_ADD_FAILURE =
  "DISSEMINATION_RULE_ADD:GET_FAIL";

export const GET_DISSEMINATION_DOCUMENT_RULE_ADD_INIT =
  "DISSEMINATION_DOCUMENT_RULE_ADD:GET_INIT";
export const GET_DISSEMINATION_DOCUMENT_RULE_ADD_SUCCESS =
  "DISSEMINATION_DOCUMENT_RULE_ADD:GET_SUCCESS";
export const GET_DISSEMINATION_DOCUMENT_RULE_ADD_FAILURE =
  "DISSEMINATION_DOCUMENT_RULE_ADD:GET_FAIL";

export const GET_DISSEMINATION_RULE_INIT = "DISSEMINATION_RULE:GET_INIT";
export const GET_DISSEMINATION_RULE_SUCCESS = "DISSEMINATION_RULE:GET_SUCCESS";
export const GET_DISSEMINATION_RULE_FAILURE = "DISSEMINATION_RULE:GET_FAIL";

export const GET_DISSEMINATION_DOCUMENT_RULE_INIT =
  "DISSEMINATION_DOCUMENT_RULE:GET_INIT";
export const GET_DISSEMINATION_DOCUMENT_RULE_SUCCESS =
  "DISSEMINATION_DOCUMENT_RULE:GET_SUCCESS";
export const GET_DISSEMINATION_DOCUMENT_RULE_FAILURE =
  "DISSEMINATION_DOCUMENT_RULE:GET_FAIL";

export const GET_DISSEMINATION_RULE_CHECK_LOCKED_INIT =
  "DISSEMINATION_RULE_CHECK_LOCKED:GET_INIT";
export const GET_DISSEMINATION_RULE_CHECK_LOCKED_SUCCESS =
  "DISSEMINATION_RULE_CHECK_LOCKED:GET_SUCCESS";
export const GET_DISSEMINATION_RULE_CHECK_LOCKED_FAILURE =
  "DISSEMINATION_RULE_CHECK_LOCKED:GET_FAIL";

export const GET_DISSEMINATION_RULE_CLEAN_INIT =
  "DISSEMINATION_RULE_CLEAN:GET_INIT";
export const GET_DISSEMINATION_RULE_CLEAN_SUCCESS =
  "DISSEMINATION_RULE_CLEAN:GET_SUCCESS";
export const GET_DISSEMINATION_RULE_CLEAN_FAILURE =
  "DISSEMINATION_RULE_CLEAN:GET_FAIL";

export const GET_DISSEMINATION_DOCUMENT_MODIFICATION_ADD_RULE_INIT =
  "DISSEMINATION_DOCUMENT_MODIFICATION_ADD_RULE:GET_INIT";
export const GET_DISSEMINATION_DOCUMENT_MODIFICATION_ADD_RULE_SUCCESS =
  "DISSEMINATION_DOCUMENT_MODIFICATION_ADD_RULE:GET_SUCCESS";
export const GET_DISSEMINATION_DOCUMENT_MODIFICATION_ADD_RULE_FAILURE =
  "DISSEMINATION_DOCUMENT_MODIFICATION_ADD_RULE:GET_FAIL";

export const GET_DISSEMINATION_DOCUMENT_MODIFICATION_RULE_INIT =
  "DISSEMINATION_DOCUMENT_MODIFICATION_RULE:GET_INIT";
export const GET_DISSEMINATION_DOCUMENT_MODIFICATION_RULE_SUCCESS =
  "DISSEMINATION_DOCUMENT_MODIFICATION_RULE:GET_SUCCESS";
export const GET_DISSEMINATION_DOCUMENT_MODIFICATION_RULE_FAILURE =
  "DISSEMINATION_DOCUMENT_MODIFICATION_RULE:GET_FAIL";

export const GET_FTP_SERVER_INIT = "GET_FTP_SERVER:GET_INIT";
export const GET_FTP_SERVER_SUCCESS = "GET_FTP_SERVER:GET_SUCCESS";
export const GET_FTP_SERVER_FAILURE = "GET_FTP_SERVER:GET_FAIL";
