// react dependencies
import React, { Component } from "react";
import PropTypes from "prop-types";
import { __ } from "../utils";
import Toaster from "js/utils/Toaster";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as promotorsActions from "../modules/promotors/actions/promotorsActions";
import * as promotorsSetCurrentActions from "../modules/promotors/actions/promotorsSetCurrentActions";
import * as logOffActions from "../modules/authentification/actions/logOffActions";
import * as notificationsActions from "../modules/notifications/actions/notificationsActions";
import * as notificationsMarkAsReadActions from "../modules/notifications/actions/notificationsMarkAsReadActions";
import * as notificationsMarkAsDoneActions from "../modules/notifications/actions/notificationsMarkAsDoneActions";
import * as notificationsDeleteActions from "../modules/notifications/actions/notificationsDeleteActions";
import * as notificationsMarkAllAsDoneActions from "../modules/notifications/actions/notificationsMarkAllAsDoneActions";
import * as authentificationActions from "js/modules/authentification/actions/authentificationActions";
import LoaderFullScreen from "../components/loader/LoaderFullScreen";

import NotificationCenter from "../components/notificationCenter/NotificationCenter";
import NotificationCenterItem from "../components/notificationCenter/NotificationCenterItem";
import UserCenter from "../components/userCenter/UserCenter";
import UserCenterItem from "../components/userCenter/UserCenterItem";

import CarneLogo from '../../img/Curator_Logo.svg';

import ModalAddPromotor from "./modal/modalAddPromotor";

import Toggle from "../components/checkbox/Toggle";
import PromotorSelector from "js/components/PromotorSelector";
import { triggerAuthentication } from "js/authentification";
import { setBuffer } from "js/promotorChangeBuffer";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promotors: [],
      loaderPromotors: true,
      loader: false,
      modalPromotors: false,
      currentPromotor: null,
      nbNotifications: 0,
      notifications: [],
      loaderNotif: false,
      loaderPromotorName: "",
      url: "",
      adminMode: false,
      paramsNotifs: {},
    };
    this.url = document.URL;
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      this.props.promotors.isLoading &&
      !nextProps.promotors.isLoading &&
      !nextProps.promotors.error
    ) {
      this.setState({
        promotors: nextProps.promotors.items,
        loaderPromotors: false,
        currentPromotor: nextProps.promotors.items.find(
          (promotor) => promotor.name === this.props.user?.promotorName
        ),
      });
    }
    if (!this.props.promotors.isLoading && nextProps.promotors.isLoading) {
      this.setState({
        loaderPromotors: true,
      });
    }
    if (
      this.props.url &&
      this.props.promotorsSetCurrent.isLoading &&
      !nextProps.promotorsSetCurrent.isLoading
    ) {
      document.location.href = this.props.url;
    }
    if (
      !this.props.url &&
      this.props.promotorsSetCurrent.isLoading &&
      !nextProps.promotorsSetCurrent.isLoading
    ) {
      setBuffer();
      if (window.location.pathname.startsWith("/billing")) {
        document.location.href = "/billing";
      } else {
        window.location.reload();
      }
    }

    if (
      this.props.notificationsMarkAllAsDone.isLoading &&
      !nextProps.notificationsMarkAllAsDone.isLoading
    ) {
      if (!nextProps.notificationsMarkAllAsDone.error) {
        this.queryNotifications(false);
        Toaster.success("Your notifications have been cleared");
      } else {
        Toaster.error(__("__ERROR_NOTIFICATION_"));
      }
      this.setState({
        loader: false,
      });
    }
    if (
      (this.props.notificationsMarkAsDone.isLoading &&
        !nextProps.notificationsMarkAsDone.isLoading) ||
      (this.props.notificationsDelete.isLoading &&
        !nextProps.notificationsDelete.isLoading)
    ) {
      this.queryNotifications(false);
    }

    if (
      this.props.notificationsMarkAsDone.isLoading &&
      !nextProps.notificationsMarkAsDone.isLoading
    ) {
      if (this.state.paramsNotifs.promotorId != this.props.user?.promotorId) {
        this.setState(
          {
            loaderNotif: true,
            loaderPromotorName: this.state.paramsNotifs.promotorName,
            url: this.state.paramsNotifs.href,
          },
          () => {
            setTimeout(() => {
              this.props.loadPromotorsSetCurrent(
                this.state.paramsNotifs.promotorId
              );
            }, 2000);
          }
        );
      } else {
        let notifications = [];
        this.state.notifications.forEach((notification) => {
          if (notification.notificationId == this.state.paramsNotifs.id) {
            notification.stateId = 3;
          }
          notifications.push(notification);
        });
        this.setState({
          notifications: notifications,
        });
        document.location.href = this.state.paramsNotifs.href;
      }
    }
  };

  UNSAFE_componentWillMount = () => {
    if (this.props.user?.frontAccessList["SWITCH_PROMOTOR_READ"]) {
      this.props.loadPromotors();
    }
    this.refreshNotifications();
  };

  componentDidMount = () => {
    if (this.url.indexOf("/admin/") >= 0) {
      if (!this.state.adminMode) this.switchAdminMode(true, true);
    } else {
      if (this.state.adminMode) this.switchAdminMode(false, true);
    }
  };

  componentDidUpdate = () => {
    if (document.URL != this.url) {
      this.url = document.URL;
      if (this.url.indexOf("/admin/") >= 0) {
        if (!this.state.adminMode) this.switchAdminMode(true, true);
      } else {
        if (this.state.adminMode) this.switchAdminMode(false, true);
      }
    }
  };

  markAllNotificationsAsDone = () => {
    this.props.loadNotificationsMarkAllAsDone();
    this.setState({
      loader: true,
    });
  };

  queryNotifications = (refresh) => {
    fetch(process.env.REACT_APP_API_BASEURL + "Notification/GetList", {
      method: "get",
      credentials: "include",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        RequestVerificationToken: this.props.token,
      },
    })
      .then((response) => {
        if (refresh) {
          this.timeout = setTimeout(() => {
            this.queryNotifications(true);
          }, 1000 * 60);
        }
        return response.json();
      })
      .then((data) => {
        let history = this.props.history;
        if (
          !this.props.user.isAuthenticated &&
          this.props.authentification.needsAuth
        ) {
          if (data.status == "401.1")
            // perte session ADFS
            triggerAuthentication();
          if (data.status == "401.2")
            // GTC a accepter
            history?.push("/gtc");
          if (data.status == "206")
            // matrice en cours d'upload
            history.push("/dissemination/locked");
          if (data.status == "207")
            // matrice en cours d'upload
            history.push("/locked");
          if (data.status == "520") {
            // refresh des droits
            this.props.updateUser();
          }
        }
        if (
          !data.error &&
          JSON.stringify(data.data.notifications) !=
          JSON.stringify(this.state.notifications)
        ) {
          this.props.setUnreadNotificationsNumber(data.data.counters.unread);
          this.setState({
            nbNotifications: data.data.counters.unread,
            notifications: data.data.notifications,
          });
        }
      });
  };

  componentWillUnmount = () => {
    if (this.timeout) clearTimeout(this.timeout);
  };

  refreshNotifications = () => {
    this.queryNotifications(true);
  };

  changePromotor = (id) => {
    if (id) {
      const currentPromotor = this.state.promotors.find(
        (promotor) => promotor.id === id
      );
      this.setState({
        currentPromotor: currentPromotor,
        loader: true,
      });
      this.props.loadPromotorsSetCurrent(currentPromotor.id);
    }
  };

  logout = () => {
    this.setState({
      loader: true,
    });
    this.props.loadLogOff();
  };

  clickOnNotifs = () => {
    let ids = [];
    this.state.notifications.forEach((notification) => {
      if (notification.stateId == 1) {
        ids.push(notification.notificationId);
      }
    });
    if (ids.length) this.props.loadNotificationsMarkAsRead(ids);

    this.props.setUnreadNotificationsNumber(0);
    this.setState({
      nbNotifications: 0,
    });
  };

  redirect = (href, promotorId, promotorName, id) => {
    this.props.loadNotificationsMarkAsDone([id]);
    this.setState({
      loader: true,
      paramsNotifs: {
        href: href,
        promotorId: promotorId,
        promotorName: promotorName,
        id: id,
      },
    });
  };

  deleteNotification = (id) => {
    this.props.loadNotificationsDelete([id]);
    let notifications = [];
    this.state.notifications.forEach((notification) => {
      if (notification.notificationId == id) {
      } else {
        notifications.push(notification);
      }
    });
    this.setState({
      notifications: notifications,
    });
  };

  addPromotor = () => {
    this.setState({
      modalPromotors: true,
    });
  };

  switchToClient = (id, name) => {
    this.setState({
      modalPromotors: false,
    });
    this.redirect("/", id, name, -1);
  };

  goToSettings = () => {
    this.props.history.push("/rights-management");
  };

  switchAdminMode = (checked, noRedirect) => {
    this.setState({
      adminMode: checked,
    });
    this.props.switchAdminMode(checked, noRedirect);
  };

  isSec = () => {
    let isSec = false;
    this.state.promotors.forEach((p) => {
      if (p.id == this.props.user?.promotorId && p.sec) {
        isSec = true;
      }
    });
    return isSec;
  };

  getPromotorSelectorMinWidth = () => {
    const element = document.createElement("span");
    element.style.fontSize = "12px";
    element.style.fontWeight = "600";
    element.innerText =
      this?.state?.promotors?.find(
        (promotor) => promotor.id === this.state.currentPromotor?.id ?? 0
      )?.name || "";
    document.body.insertBefore(element, document.getElementById("root"));
    const width = element.offsetWidth + 45;
    element.remove();
    return Math.max(50, Math.min(400, width)) + "px";
  };

  render() {
    return (
      <header className="main-header">
        <img
          src={CarneLogo}
          className="pwc-network-network-world"
          alt="Curator App Logo"
        />
        <div className="main-header-app-title">
        </div>
        {!this.props.logoOnly && (
          <>
            {!this.state.adminMode ? (
              <>
                <div className="divider" />
                <div
                  className="promotor-select-header-wrapper"
                  style={{ minWidth: this.getPromotorSelectorMinWidth() }}
                >
                  {this.props.user?.frontAccessList["SWITCH_PROMOTOR_WRITE"] ? (
                    <div
                      onClick={() =>
                        this.setState({
                          notificationCenter: false,
                          userCenter: false,
                        })
                      }
                      className="main-header-promotors"
                    >
                      <PromotorSelector
                        promotors={this.state.promotors}
                        onAdd={
                          !!this.props.user?.frontAccessList[
                          "ADMINISTRATOR_WRITE"
                          ] && this.addPromotor
                        }
                        onChange={(value) => {
                          this.changePromotor(value);
                        }}
                        value={this.state.currentPromotor?.id ?? 0}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() =>
                        this.setState({
                          notificationCenter: false,
                          userCenter: false,
                        })
                      }
                      className="main-header-promotors"
                    >
                      <div>
                        {this.state.currentPromotor?.name}
                        {this.isSec() ? <i className="sec">sec</i> : null}
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : null}
            <div className="spacer" />
            {this.props.user?.frontAccessList["ADMINISTRATOR_WRITE"] ? (
              <div className="admin-mode">
                <span>{__("__ADMIN_MODE_")}</span>
                <Toggle
                  checked={this.state.adminMode}
                  onClick={this.switchAdminMode}
                />
              </div>
            ) : null}

            <NotificationCenter
              markAllNotificationsAsDone={this.markAllNotificationsAsDone}
              onClick={this.clickOnNotifs}
              notificationsCounter={this.state.nbNotifications}
            >
              {this.state.notifications.map((notification) => {
                return (
                  <NotificationCenterItem
                    key={notification.notificationId}
                    notification={notification}
                    deleteNotification={() =>
                      this.deleteNotification(notification.notificationId)
                    }
                    onClick={(href, promotorId, promotorName) =>
                      this.redirect(
                        href,
                        promotorId,
                        promotorName,
                        notification.notificationId
                      )
                    }
                  />
                );
              })}
            </NotificationCenter>

            <UserCenter user={this.props.user}>
              <UserCenterItem onClick={this.logout} label={__("__LOG_OUT_")} />
            </UserCenter>

            <ModalAddPromotor
              close={() => {
                this.setState({ modalPromotors: false });
              }}
              switchToClient={this.switchToClient}
              show={this.state.modalPromotors}
              countriesByRegion={this.props.countriesByRegion}
              countries={this.props.countries}
            />

            <LoaderFullScreen show={this.state.loader} />
            <LoaderFullScreen
              message={
                <div className="loader-full-screen-text-big">
                  Switching to client {this.state.loaderPromotorName}
                </div>
              }
              show={this.state.loaderNotif}
            />
          </>
        )}
      </header>
    );
  }
}

Header.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    promotors: state.promotorsReducer,
    promotorsSetCurrent: state.promotorsSetCurrentReducer,
    logOff: state.logOffReducer,
    notifications: state.notificationsReducer,
    notificationsMarkAsRead: state.notificationsMarkAsReadReducer,
    notificationsDelete: state.notificationsDeleteReducer,
    notificationsMarkAsDone: state.notificationsMarkAsDoneReducer,
    notificationsMarkAllAsDone: state.notificationsMarkAllAsDoneReducer,
    authentification: state.authentificationReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      promotorsActions,
      promotorsSetCurrentActions,
      logOffActions,
      notificationsActions,
      notificationsMarkAsReadActions,
      notificationsDeleteActions,
      notificationsMarkAsDoneActions,
      notificationsMarkAllAsDoneActions,
      authentificationActions
    ),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
