var store = undefined;

const exp = {
  init(configureStore) {
    store = configureStore();
  },
  getStore() {
    return store;
  },
};

export default exp;
