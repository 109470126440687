// react dependencies
import React, { Component } from "react";

import onClickOutside from "react-onclickoutside";

import { NavLink } from "react-router-dom";

class UploadCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOutside = (evt) => {
    this.setState({
      open: false,
    });
    //this.props.onClick(false)
  };

  handleClick = () => {
    this.setState(
      {
        open: !this.state.open,
      },
      () => {
        this.props.onClick(this.state.open);
      }
    );
  };

  renderFilesProcessedCounter = (status) => {
    let counter = 0;
    this.props.files.forEach((file) => {
      if (status > 0) {
        if (file.status.id == status) counter++;
      } else {
        if (file.status.id >= 0) counter++;
      }
    });
    return counter;
  };

  renderWidthBar = () => {
    let counter = 0;
    this.props.files.forEach((file) => {
      if (file.status.id >= 0) counter++;
    });
    if (this.props.files.length)
      return Math.round((counter / this.props.files.length) * 100);
    else return 0;
  };

  renderIcon = () => {
    if (
      this.renderFilesProcessedCounter(0) == this.props.files.length ||
      this.state.open
    )
      return (
        <div className="icon">
          <i className="pwc-cloud-outline-cloud-upload"></i>
        </div>
      );
    else
      return (
        <div className="icon icon--active">
          <div className="bgCircle"></div>
          <svg width="32" height="32" viewBox="0 0 32 32">
            <circle
              strokeDasharray="339.292"
              strokeDashoffset={this.renderCirconference()}
              cx="16"
              cy="16"
              r="15"
              fill="none"
              stroke="#fff"
              strokeWidth="2"
            />
          </svg>
          <i className="pwc-cloud-outline-cloud-upload"></i>
        </div>
      );
  };

  renderCirconference = () => {
    let counter = 0;
    this.props.files.forEach((file) => {
      if (file.status.id >= 0) counter++;
    });
    let percent = counter / this.props.files.length;
    return 239.292 + (100 - 100 * percent);
  };

  render() {
    return (
      <div className="upload-center">
        <div className="row">
          {this.renderIcon()}
          <div className="label">Documents upload</div>
        </div>
        <div className="second-row">
          <div className="progression">
            <div
              className="bar"
              style={{ width: this.renderWidthBar() + "%" }}
            ></div>
          </div>
          <div className="nb-files">
            <span className="nb">
              {this.renderFilesProcessedCounter(0)}/{this.props.files.length}
            </span>
            <span>files uploaded</span>
          </div>
        </div>
        <div className="overview" onClick={(e) => e.stopPropagation()}>
          <div>
            <div className="title">
              <span>Last upload results</span>
              <i onClick={this.handleClick} className="pwc-status-close"></i>
            </div>
            <ul>
              <li className="success">
                <div className="number">
                  {this.renderFilesProcessedCounter(1)}
                </div>
                <div className="name">
                  Documents <span>well uploaded</span>
                </div>
                <NavLink onClick={this.handleClick} to="/library/v2/files">
                  View
                </NavLink>
              </li>
              <li className="unclassified">
                <div className="number">
                  {this.renderFilesProcessedCounter(2)}
                </div>
                <div className="name">
                  Documents <span>unclassified</span>
                </div>
                <NavLink
                  onClick={this.handleClick}
                  to="/library/v2/unclassified"
                >
                  Check
                </NavLink>
              </li>
              <li className="missing-metadata">
                <div className="number">
                  {this.renderFilesProcessedCounter(3)}
                </div>
                <div className="name">
                  <span>Missing metadata</span> documents
                </div>
                <NavLink
                  onClick={this.handleClick}
                  to="/library/v2/missing-metadata"
                >
                  Check
                </NavLink>
              </li>
              <li className="exceptions">
                <div className="number">
                  {this.renderFilesProcessedCounter(4)}
                </div>
                <div className="name">
                  Documents <span>in exception</span>
                </div>
                <NavLink onClick={this.handleClick} to="/library/v2/exceptions">
                  Check
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default onClickOutside(UploadCenter);
