import * as types from "../constants";

const initialState = {
  isLoading: false,
  error: null,
  items: {},
};

export default function taskTemplatesSubTasksDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.TASK_TEMPLATES_SUB_TASKS_DATA_INIT:
      return { ...state, error: null, isLoading: false };

    case types.TASK_TEMPLATES_SUB_TASKS_DATA_SUCCESS:
      let items = state.items;
      for (var key in action.data) {
        if (action.data.hasOwnProperty(key)) {
          items[key] = action.data[key];
        }
      }
      return { ...state, isLoading: false, items: items };

    case types.TASK_TEMPLATES_SUB_TASKS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.notification.system_message,
      };

    case types.TASK_TEMPLATES_SUB_TASKS_DATA_RESET:
      return { ...state, isLoading: false, items: {} };

    default:
      return state;
  }
}
