export const GET_SHARE_CLASSES_BY_SUB_FUND_BY_FUND_INIT =
  "SHARE_CLASSES_BY_SUB_FUND_BY_FUND:GET_INIT";
export const GET_SHARE_CLASSES_BY_SUB_FUND_BY_FUND_SUCCESS =
  "SHARE_CLASSES_BY_SUB_FUND_BY_FUND:GET_SUCCESS";
export const GET_SHARE_CLASSES_BY_SUB_FUND_BY_FUND_FAILURE =
  "SHARE_CLASSES_BY_SUB_FUND_BY_FUND:GET_FAIL";

export const GET_SHARE_CLASSES_BY_SUB_FUND_INIT =
  "SHARE_CLASSES_BY_SUB_FUND:GET_INIT";
export const GET_SHARE_CLASSES_BY_SUB_FUND_SUCCESS =
  "SHARE_CLASSES_BY_SUB_FUND:GET_SUCCESS";
export const GET_SHARE_CLASSES_BY_SUB_FUND_FAILURE =
  "SHARE_CLASSES_BY_SUB_FUND:GET_FAIL";

export const GET_SHARE_CLASSES_LIFE_CYCLE_STATUS_INIT =
  "SHARE_CLASSES_LIFE_CYCLE_STATUS:GET_INIT";
export const GET_SHARE_CLASSES_LIFE_CYCLE_STATUS_SUCCESS =
  "SHARE_CLASSES_LIFE_CYCLE_STATUS:GET_SUCCESS";
export const GET_SHARE_CLASSES_LIFE_CYCLE_STATUS_FAILURE =
  "SHARE_CLASSES_LIFE_CYCLE_STATUS:GET_FAIL";

export const GET_SHARE_CLASSES_CURRENCIES_INIT =
  "SHARE_CLASSES_CURRENCIES:GET_INIT";
export const GET_SHARE_CLASSES_CURRENCIES_SUCCESS =
  "SHARE_CLASSES_CURRENCIES:GET_SUCCESS";
export const GET_SHARE_CLASSES_CURRENCIES_FAILURE =
  "SHARE_CLASSES_CURRENCIES:GET_FAIL";

export const GET_SHARE_CLASSES_REGISTRATION_COUNTRIES_INIT =
  "SHARE_CLASSES_REGISTRATION_COUNTRIES:GET_INIT";
export const GET_SHARE_CLASSES_REGISTRATION_COUNTRIES_SUCCESS =
  "SHARE_CLASSES_REGISTRATION_COUNTRIES:GET_SUCCESS";
export const GET_SHARE_CLASSES_REGISTRATION_COUNTRIES_FAILURE =
  "SHARE_CLASSES_REGISTRATION_COUNTRIES:GET_FAIL";

export const GET_SHARE_CLASSES_REGISTRATION_STATUS_INIT =
  "SHARE_CLASSES_REGISTRATION_STATUS:GET_INIT";
export const GET_SHARE_CLASSES_REGISTRATION_STATUS_SUCCESS =
  "SHARE_CLASSES_REGISTRATION_STATUS:GET_SUCCESS";
export const GET_SHARE_CLASSES_REGISTRATION_STATUS_FAILURE =
  "SHARE_CLASSES_REGISTRATION_STATUS:GET_FAIL";

export const GET_SHARE_CLASSES_ISIN_INIT = "SHARE_CLASSES_ISIN:GET_INIT";
export const GET_SHARE_CLASSES_ISIN_SUCCESS = "SHARE_CLASSES_ISIN:GET_SUCCESS";
export const GET_SHARE_CLASSES_ISIN_FAILURE = "SHARE_CLASSES_ISIN:GET_FAIL";
