// react dependencies
import React, { Component } from "react";

import Button from "../button/Button";
import { __ } from "../../utils";
import classNames from "classnames";

class Alert extends Component {
  dialog;
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.show != this.props.show) {
      this.setState({
        show: nextProps.show,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevProps.show && this.props.show && this.refs.btnOk)
      this.refs.btnOk.focus();
    if (!prevProps.show && this.props.show) {
      if (this.refs.dialog) {
        this.dialog = window.mdc.dialog.MDCDialog.attachTo(this.refs.dialog);
        this.dialog.foundation_.open();
        this.dialog.listen("MDCDialog:closing", () => {
          this.handleClick();
        });
      }
    }
  };

  handleClick = () => {
    this.dialog.foundation_.close();
    this.setState({
      show: false,
    });
    if (this.props.close) this.props.close();
    if (this.props.cancel) this.props.cancel();
  };

  confirm = () => {
    this.dialog.foundation_.close();
    this.setState({
      show: false,
    });
    this.props.confirm();
  };

  render() {
    if (this.state.show) {
      return (
        <div
          ref="dialog"
          className={classNames("mdc-dialog", this.props.className)}
          role="alertdialog"
          aria-modal="true"
          aria-labelledby={this.props.labeledBy ?? "my-dialog-title"}
          aria-describedby="my-dialog-content"
        >
          <div className="mdc-dialog__container">
            <div className="mdc-dialog__surface">
              <h2 className="mdc-dialog__title" id="my-dialog-title">
                <span>{this.props.title}</span>
              </h2>
              <div className="mdc-dialog__content" id="my-dialog-content">
                {this.props.message}
                {this.props.children}
              </div>
              <footer className="mdc-dialog__actions">
                {this.props.closeButton ? (
                  <button
                    key="btn3"
                    className="btn btn-ghost"
                    onClick={this.props.close}
                  >
                    {__("__CLOSE_")}
                  </button>
                ) : null}
                {this.props.confirm ? (
                  [
                    <Button
                      key="btn1"
                      className="btn-ghost"
                      onClick={this.handleClick}
                      label={
                        this.props.labelCancel
                          ? this.props.labelCancel
                          : __("__NO_")
                      }
                    />,
                    <button
                      key="btn2"
                      className={classNames(
                        "btn",
                        this.props.btnConfirmClassName
                      )}
                      onClick={this.confirm}
                    >
                      {this.props.labelConfirm
                        ? this.props.labelConfirm
                        : __("__YES_")}
                    </button>,
                  ]
                ) : (
                  <Button onClick={this.handleClick} label={__("__OK_")} />
                )}
              </footer>
            </div>
          </div>
          <div className="mdc-dialog__scrim"></div>
        </div>
      );
    } else {
      return null
    }
  }
}

export default Alert;
