
export default function Footer() {

  return (
    <div className="copyright">
      Unity through simplicity &trade;
      <br />
      <a href="https://www.carnegroup.com/" target="_blank" rel="noreferrer">
        www.carnegroup.com
      </a>
    </div>
  );
}
