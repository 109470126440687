import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import LibraryV2Api from "../../../models/LibraryV2Api";

export function loadLibraryUploadFile(files, fundId, documentType) {
  let action = new ActionRequest(
    LibraryV2Api.loadLibraryUploadFile,
    loadLibraryUploadFileInit,
    loadLibraryUploadFileSuccess,
    loadLibraryUploadFileFailure
  );
  return action.run({
    routeParameters: { files, fundId, documentType },
    actionParameters: { files, fundId, documentType },
  });
}

function loadLibraryUploadFileSuccess(librayImportData, params) {
  return {
    type: types.LIBRARY_UPLOAD_FILE_SUCCESS,
    data: librayImportData,
  };
}

function loadLibraryUploadFileFailure(error) {
  return {
    type: types.LIBRARY_UPLOAD_FILE_FAILURE,
    notification: {
      level: "error",
      label: "[action:librayImport>loadLibraryUploadFile]",
      message: "Impossible de récupérer la liste des librayImport",
      system_message: error.message,
    },
  };
}

function loadLibraryUploadFileInit(params) {
  return { type: types.LIBRARY_UPLOAD_FILE_INIT };
}
