export const GET_TASK_TYPES_INIT = "TASK_TYPES:GET_INIT";
export const GET_TASK_TYPES_SUCCESS = "TASK_TYPES:GET_SUCCESS";
export const GET_TASK_TYPES_FAILURE = "TASK_TYPES:GET_FAIL";

export const GET_TASK_BY_TYPES_INIT = "TASK_BY_TYPES:GET_INIT";
export const GET_TASK_BY_TYPES_SUCCESS = "TASK_BY_TYPES:GET_SUCCESS";
export const GET_TASK_BY_TYPES_FAILURE = "TASK_BY_TYPES:GET_FAIL";

export const GET_TASKS_INIT = "TASKS:GET_INIT";
export const GET_TASKS_SUCCESS = "TASKS:GET_SUCCESS";
export const GET_TASKS_FAILURE = "TASKS:GET_FAIL";

export const GET_TASKS_BY_STATUS_INIT = "TASKS_BY_STATUS:GET_INIT";
export const GET_TASKS_BY_STATUS_SUCCESS = "TASKS_BY_STATUS:GET_SUCCESS";
export const GET_TASKS_BY_STATUS_FAILURE = "TASKS_BY_STATUS:GET_FAIL";

export const GET_TASKS_BY_TYPE_INIT = "TASKS_BY_TYPE:GET_INIT";
export const GET_TASKS_BY_TYPE_SUCCESS = "TASKS_BY_TYPE:GET_SUCCESS";
export const GET_TASKS_BY_TYPE_FAILURE = "TASKS_BY_TYPE:GET_FAIL";

export const GET_TASKS_STATUS_INIT = "TASKS_STATUS:GET_INIT";
export const GET_TASKS_STATUS_SUCCESS = "TASKS_STATUS:GET_SUCCESS";
export const GET_TASKS_STATUS_FAILURE = "TASKS_STATUS:GET_FAIL";

export const GET_TASKS_DETAILS_INIT = "TASKS_DETAILS:GET_INIT";
export const GET_TASKS_DETAILS_SUCCESS = "TASKS_DETAILS:GET_SUCCESS";
export const GET_TASKS_DETAILS_FAILURE = "TASKS_DETAILS:GET_FAIL";

export const GET_TASKS_DETAILS_FOR_ADDING_DOC_INIT =
  "TASKS_DETAILS_FOR_ADDING_DOC:GET_INIT";
export const GET_TASKS_DETAILS_FOR_ADDING_DOC_SUCCESS =
  "TASKS_DETAILS_FOR_ADDING_DOC:GET_SUCCESS";
export const GET_TASKS_DETAILS_FOR_ADDING_DOC_FAILURE =
  "TASKS_DETAILS_FOR_ADDING_DOC:GET_FAIL";

export const GET_TASKS_ADD_INIT = "TASKS_ADD:GET_INIT";
export const GET_TASKS_ADD_SUCCESS = "TASKS_ADD:GET_SUCCESS";
export const GET_TASKS_ADD_FAILURE = "TASKS_ADD:GET_FAIL";

export const GET_TASKS_ADD_DOC_REQUIRED_INIT =
  "TASKS_ADD_DOC_REQUIRED:GET_INIT";
export const GET_TASKS_ADD_DOC_REQUIRED_SUCCESS =
  "TASKS_ADD_DOC_REQUIRED:GET_SUCCESS";
export const GET_TASKS_ADD_DOC_REQUIRED_FAILURE =
  "TASKS_ADD_DOC_REQUIRED:GET_FAIL";

export const GET_TASKS_ALL_FILTERS_INIT = "TASKS_ALL_FILTERS:GET_INIT";
export const GET_TASKS_ALL_FILTERS_SUCCESS = "TASKS_ALL_FILTERS:GET_SUCCESS";
export const GET_TASKS_ALL_FILTERS_FAILURE = "TASKS_ALL_FILTERS:GET_FAIL";

export const GET_TASKS_EXPORT_EXCEL_INIT = "TASKS_EXPORT_EXCEL:GET_INIT";
export const GET_TASKS_EXPORT_EXCEL_SUCCESS = "TASKS_EXPORT_EXCEL:GET_SUCCESS";
export const GET_TASKS_EXPORT_EXCEL_FAILURE = "TASKS_EXPORT_EXCEL:GET_FAIL";

export const GET_TASKS_EXPORT_DOC_EXCEL_INIT =
  "TASKS_EXPORT_DOC_EXCEL:GET_INIT";
export const GET_TASKS_EXPORT_DOC_EXCEL_SUCCESS =
  "TASKS_EXPORT_DOC_EXCEL:GET_SUCCESS";
export const GET_TASKS_EXPORT_DOC_EXCEL_FAILURE =
  "TASKS_EXPORT_DOC_EXCEL:GET_FAIL";

export const GET_TASKS_EXPORT_PDF_INIT = "TASKS_EXPORT_PDF:GET_INIT";
export const GET_TASKS_EXPORT_PDF_SUCCESS = "TASKS_EXPORT_PDF:GET_SUCCESS";
export const GET_TASKS_EXPORT_PDF_FAILURE = "TASKS_EXPORT_PDF:GET_FAIL";

export const GET_TASKS_VALIDATE_SUBTASK_INIT =
  "TASKS_VALIDATE_SUBTASK:GET_INIT";
export const GET_TASKS_VALIDATE_SUBTASK_SUCCESS =
  "TASKS_VALIDATE_SUBTASK:GET_SUCCESS";
export const GET_TASKS_VALIDATE_SUBTASK_FAILURE =
  "TASKS_VALIDATE_SUBTASK:GET_FAIL";

export const GET_TASKS_CANCEL_INIT = "TASKS_CANCEL:GET_INIT";
export const GET_TASKS_CANCEL_SUCCESS = "TASKS_CANCEL:GET_SUCCESS";
export const GET_TASKS_CANCEL_FAILURE = "TASKS_CANCEL:GET_FAIL";

export const GET_TASKS_DELETE_INIT = "TASKS_DELETE:GET_INIT";
export const GET_TASKS_DELETE_SUCCESS = "TASKS_DELETE:GET_SUCCESS";
export const GET_TASKS_DELETE_FAILURE = "TASKS_DELETE:GET_FAIL";

export const GET_FORCE_DOC_CHECK_INIT = "TASKS_FORCE_DOC_CHECK:GET_INIT";
export const GET_FORCE_DOC_CHECK_SUCCESS = "TASKS_FORCE_DOC_CHECK:GET_SUCCESS";
export const GET_FORCE_DOC_CHECK_FAILURE = "TASKS_FORCE_DOC_CHECK:GET_FAIL";

export const GET_REMOVE_DOC_LINK_INIT = "REMOVE_DOC_LINK:GET_INIT";
export const GET_REMOVE_DOC_LINK_SUCCESS = "REMOVE_DOC_LINK:GET_SUCCESS";
export const GET_REMOVE_DOC_LINK_FAILURE = "REMOVE_DOC_LINK:GET_FAIL";

export const GET_REMOVE_DOC_REQUIRED_INIT = "REMOVE_DOC_REQUIRED:GET_INIT";
export const GET_REMOVE_DOC_REQUIRED_SUCCESS =
  "REMOVE_DOC_REQUIRED:GET_SUCCESS";
export const GET_REMOVE_DOC_REQUIRED_FAILURE = "REMOVE_DOC_REQUIRED:GET_FAIL";

export const GET_TASKS_MISSING_SUBFUNDS_INIT =
  "TASKS_MISSING_SUBFUNDS:GET_INIT";
export const GET_TASKS_MISSING_SUBFUNDS_SUCCESS =
  "TASKS_MISSING_SUBFUNDS:GET_SUCCESS";
export const GET_TASKS_MISSING_SUBFUNDS_FAILURE =
  "TASKS_MISSING_SUBFUNDS:GET_FAIL";

export const GET_TASKS_GENERATE_DOCUMENT_INIT =
  "TASKS_GENERATE_DOCUMENT:GET_INIT";
export const GET_TASKS_GENERATE_DOCUMENT_SUCCESS =
  "TASKS_GENERATE_DOCUMENT:GET_SUCCESS";
export const GET_TASKS_GENERATE_DOCUMENT_FAILURE =
  "TASKS_GENERATE_DOCUMENT:GET_FAIL";

export const GET_TASKS_STATUS_AVAILABLE_INIT =
  "TASKS_STATUS_AVAILABLE:GET_INIT";
export const GET_TASKS_STATUS_AVAILABLE_SUCCESS =
  "TASKS_STATUS_AVAILABLE:GET_SUCCESS";
export const GET_TASKS_STATUS_AVAILABLE_FAILURE =
  "TASKS_STATUS_AVAILABLE:GET_FAIL";

export const GET_TASKS_SUBTASK_EXPORT_INIT =
  "GET_TASKS_SUBTASK_EXPORT:GET_INIT";
export const GET_TASKS_SUBTASK_EXPORT_SUCCESS =
  "GET_TASKS_SUBTASK_EXPORT:GET_SUCCESS";
export const GET_TASKS_SUBTASK_EXPORT_FAILURE =
  "GET_TASKS_SUBTASK_EXPORT:GET_FAILURE";
