// react dependencies
import React, { Component } from "react";

class InputText extends Component {
  constructor(props) {
    super(props);
    this.cursorPosition = 0;
  }

  handleKeyup = (e) => {
    this.props.reset();
    this.props.validate(e);
    this.getCursorPosition();
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.newValue != nextProps.newValue && nextProps.newValue != "") {
      let value = this.refs.input.value;
      value =
        value.substring(0, this.cursorPosition) +
        nextProps.newValue +
        value.substring(this.cursorPosition, value.length);
      this.refs.input.value = value;
      this.refs.input.focus();
      this.refs.input.setSelectionRange(
        this.cursorPosition + nextProps.newValue.length,
        this.cursorPosition + nextProps.newValue.length
      );
      this.cursorPosition = this.cursorPosition + nextProps.newValue.length;
      this.props.validate(this.refs.input.value);
    }
    if (
      this.props.value != nextProps.value &&
      nextProps.value != this.refs.input.value
    ) {
      this.refs.input.value = nextProps.value;
    }
  };

  componentDidMount = () => {
    if (this.props.value) {
      this.refs.input.value = this.props.value;
    }
  };

  onClick = () => {
    if (this.props.onClick) this.props.onClick();
    this.getCursorPosition();
  };

  getCursorPosition = () => {
    if (this.props.onClick) this.props.onClick();
    this.cursorPosition = parseFloat(this.refs.input.selectionStart);
  };

  render() {
    return (
      <input
        {...this.props.inputProps}
        maxLength={this.props.maxLength ? this.props.maxLength : null}
        tabIndex="1"
        placeholder={this.props.placeholder ? this.props.placeholder : null}
        onClick={this.onClick}
        ref="input"
        onMouseUp={this.handleKeyup}
        onKeyUp={this.handleKeyup}
        type={this.props.number ? "number" : "text"}
        className="form-row-input-field"
      />
    );
  }
}

export default InputText;
