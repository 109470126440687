import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import CountriesApi from "../../../models/CountriesApi";

export function loadCountriesByRegion() {
  let action = new ActionRequest(
    CountriesApi.loadCountriesByRegion,
    loadCountriesByRegionInit,
    loadCountriesByRegionSuccess,
    loadCountriesByRegionFailure
  );
  return action.run({
    routeParameters: {},
    actionParameters: {},
  });
}

function loadCountriesByRegionSuccess(countriesData, params) {
  return {
    type: types.GET_COUNTRIES_BY_REGION_SUCCESS,
    data: countriesData,
  };
}

function loadCountriesByRegionFailure(error) {
  return {
    type: types.GET_COUNTRIES_BY_REGION_FAILURE,
    notification: {
      level: "error",
      label: "[action:countries>loadCountriesByRegion]",
      message: "Impossible de récupérer la liste des countries",
      system_message: error.message,
    },
  };
}

function loadCountriesByRegionInit(params) {
  return { type: types.GET_COUNTRIES_BY_REGION_INIT };
}
