import Request from "../utils/requests/request";

export default class FundsApi {
  static loadFunds() {
    let loadReq = new Request(process.env.REACT_APP_API_BASEURL + "Funds/Get");
    return loadReq.get();
  }

  static loadFundsLifeCycleStatus(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/FundLifeCycleFilter"
    );
    return loadReq.post(params.filters);
  }

  static loadFundsDomiciles(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "RegistrationMatrix/FundDomicileFilter"
    );
    return loadReq.post(params.filters);
  }

  static loadFundsByCountry() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Funds/GetFundByCountry"
    );
    return loadReq.get();
  }

  static loadSubfundsByCountry(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Funds/GetSubFundCountByCountry"
    );
    return loadReq.post(params.filters);
  }

  static loadFundsByPromotor(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Promotor/GetFunds/" + params.id
    );
    return loadReq.get();
  }
  static loadFundsByTaskTemplate(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Funds/GetFundsByTaskTemplate?taskTemplateId=" +
        params.id
    );
    return loadReq.get();
  }
}
