import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import AuthentificationApi from "../../../models/AuthentificationApi";

export function loadAuthentification() {
  let action = new ActionRequest(
    AuthentificationApi.isIdentified,
    loadAuthentificationInit,
    loadAuthentificationSuccess,
    loadAuthentificationFailure
  );
  return action.run({
    routeParameters: {},
    actionParameters: {},
  });
}

function loadAuthentificationSuccess(contactsData, params) {
  return {
    type: types.IS_AUTHENTICATED_SUCCESS,
    data: contactsData,
  };
}

function loadAuthentificationFailure(error) {
  return {
    type: types.IS_AUTHENTICATED_FAILURE,
    notification: {
      level: "error",
      label: "[action:task>loadAuthentification]",
      message: "Impossible de s'authentifier",
      system_message: error.message,
    },
  };
}

function loadAuthentificationInit(params) {
  return { type: types.IS_AUTHENTICATED_INIT };
}

export function setAuthentificationNeedsAuth(value) {
  return { type: types.SET_NEEDS_AUTH, payload: value };
}
