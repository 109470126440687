import { history } from "../../../index";

import storeProvider from "../../storeProvider";

import { loadAuthentification } from "../../modules/authentification/actions/authentificationActions";
import { triggerAuthentication } from "js/authentification";

class ActionRequest {
  constructor(request, action = null, actionSuccess = null, actionFail = null) {
    this.action = action;
    this.actionSuccess = actionSuccess;
    this.actionFail = actionFail;
    this.request = request;
  }

  getRequest() {
    return this.request;
  }

  setRequest(request) {
    this.request = request;
  }

  getAction() {
    return this.action;
  }

  setAction(action) {
    this.action = action;
  }

  getActionSuccess() {
    return this.actionSuccess;
  }

  setActionSuccess(action) {
    this.actionSuccess = action;
  }

  getDefaultActionFail = (error) => {
    return {
      type: "ACTION_REQUEST_FAIL",
      notification: {
        level: "error",
        code: 1,
        label: "action request",
        message: error.message,
        system_message: error.message,
      },
    };
  };

  getDefaultActionSuccess = () => {
    return {
      type: "ACTION_REQUEST_SUCCESS",
      notification: {
        level: "info",
        code: 1,
        label: "action request",
        message: "SUCCESS",
      },
    };
  };

  cancel = () => {
    let actionParameters = {};
    return (dispatch) => {
      dispatch(this.getAction()(actionParameters));
    };
  };

  run(params = {}, data = null) {
    let routeParameters = params.routeParameters ? params.routeParameters : {};
    let actionParameters = params.actionParameters
      ? params.actionParameters
      : {};

    return (dispatch) => {
      dispatch(this.getAction()(actionParameters));

      let req = this.getRequest()(routeParameters, data);
      req
        .then((result) => {
          if (result.status != "200" || result.error) {
            const authentificationReducer = storeProvider
              .getStore()
              .getState().authentificationReducer;
            const needsAuth =
              authentificationReducer.data && authentificationReducer.needsAuth;
            if (needsAuth) {
              if (result.status == "401.1")
                // perte session ADFS
                triggerAuthentication();
              if (result.status == "401.2")
                // GTC a accepter
                history.push("/gtc");
              if (result.status == "205")
                // matrice en cours d'upload
                history.push("/registration-matrix-edit-locked");
              if (result.status == "206")
                // matrice en cours d'upload
                history.push("/dissemination/locked");
              if (result.status == "207")
                // matrice en cours d'upload
                history.push("/locked");
              if (result.status == "520") {
                // refresh des droits
                const store = storeProvider.getStore();
                store.dispatch(loadAuthentification());
              }
            }
            throw new Error(result.error + "\n" + result.data);
          } else {
            let actionSuccess = this.getActionSuccess()
              ? this.getActionSuccess()
              : this.getDefaultActionSuccess;
            dispatch(actionSuccess(result, actionParameters));
          }
        })
        .catch((error) => {
          const actionFail = this.actionFail ?? this.getDefaultActionFail;
          dispatch(actionFail(error));
        });
    };
  }
}

export default ActionRequest;
