import * as types from "../constants";

const initialState = {
  isLoading: true,
  error: null,
  data: {},
  needsAuth: null,
};

export default function authentificationReducer(state = initialState, action) {
  switch (action.type) {
    case types.IS_AUTHENTICATED_INIT:
      return { ...state, error: null, isLoading: true };

    case types.IS_AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...action.data.data,
          user: {
            ...action.data.data.user,
            frontAccessList: action.data.data.user.frontAccessList.reduce(
              (acc, item) => {
                acc[item] = true;
                return acc;
              },
              {}
            ),
          },
        },
      };

    case types.IS_AUTHENTICATED_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.notification.system_message,
      };

    case types.SET_NEEDS_AUTH:
      return {
        ...state,
        needsAuth: action.payload,
      };

    default:
      return state;
  }
}
