export const GET_COMMENTS_POST_SUB_TASK_INIT =
  "COMMENTS_POST_SUB_TASK:GET_INIT";
export const GET_COMMENTS_POST_SUB_TASK_SUCCESS =
  "COMMENTS_POST_SUB_TASK:GET_SUCCESS";
export const GET_COMMENTS_POST_SUB_TASK_FAILURE =
  "COMMENTS_POST_SUB_TASK:GET_FAIL";

export const GET_COMMENTS_POST_TASK_INIT = "COMMENTS_POST_TASK:GET_INIT";
export const GET_COMMENTS_POST_TASK_SUCCESS = "COMMENTS_POST_TASK:GET_SUCCESS";
export const GET_COMMENTS_POST_TASK_FAILURE = "COMMENTS_POST_TASK:GET_FAIL";

export const GET_COMMENTS_UPDATE_SUB_TASK_INIT =
  "COMMENTS_UPDATE_SUB_TASK:GET_INIT";
export const GET_COMMENTS_UPDATE_SUB_TASK_SUCCESS =
  "COMMENTS_UPDATE_SUB_TASK:GET_SUCCESS";
export const GET_COMMENTS_UPDATE_SUB_TASK_FAILURE =
  "COMMENTS_UPDATE_SUB_TASK:GET_FAIL";

export const GET_COMMENTS_UPDATE_TASK_INIT = "COMMENTS_UPDATE_TASK:GET_INIT";
export const GET_COMMENTS_UPDATE_TASK_SUCCESS =
  "COMMENTS_UPDATE_TASK:GET_SUCCESS";
export const GET_COMMENTS_UPDATE_TASK_FAILURE = "COMMENTS_UPDATE_TASK:GET_FAIL";
