export const GET_LIBRARY_IMPORT_INIT = "LIBRARY_IMPORT:GET_INIT";
export const GET_LIBRARY_IMPORT_SUCCESS = "LIBRARY_IMPORT:GET_SUCCESS";
export const GET_LIBRARY_IMPORT_FAILURE = "LIBRARY_IMPORT:GET_FAIL";

export const GET_LIBRARY_IMPORT2_INIT = "LIBRARY_IMPORT2:GET_INIT";
export const GET_LIBRARY_IMPORT2_SUCCESS = "LIBRARY_IMPORT2:GET_SUCCESS";
export const GET_LIBRARY_IMPORT2_FAILURE = "LIBRARY_IMPORT2:GET_FAIL";

export const GET_LIBRARY_YEARS_INIT = "LIBRARY_YEARS:GET_INIT";
export const GET_LIBRARY_YEARS_SUCCESS = "LIBRARY_YEARS:GET_SUCCESS";
export const GET_LIBRARY_YEARS_FAILURE = "LIBRARY_YEARS:GET_FAIL";
