// react dependencies
import { inBuffer } from "js/promotorChangeBuffer";
import React from "react";
import { useHistory } from "react-router";
import Image404 from "../../img/404.png";

import Button from "../components/button/Button";

const NotFound = () => {
  const history = useHistory();
  if (inBuffer()) {
    history.replace("/");
  }
  return (
    <section className="not-found">
      <div className="container-default">
        <div className="not-found-img">
          <img src={Image404} alt="404" />
        </div>
        <div className="not-found-oops">Oops...</div>
        <div className="not-found-404">404</div>
        <div className="not-found-error">Error</div>
        <div className="not-found-sorry">Sorry, the page was not found</div>
        <div className="not-found-action">
          <Button
            label="Back to dashboard"
            onClick={() => history.replace("/")}
          />
        </div>
      </div>
    </section>
  );
};

export default NotFound;
