import Request from "../utils/requests/request";

export default class NotificationsApi {
  static loadNotifications(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Notification/GetList"
    );
    return loadReq.get();
  }

  static loadNotificationsMarkAsRead(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Notification/ConsiderAsRead"
    );
    return loadReq.post(params.ids);
  }

  static loadNotificationsMarkAsDone(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Notification/ConsiderAsDone"
    );
    return loadReq.post(params.ids);
  }

  static loadNotificationsDelete(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Notification/ConsiderAsDeleted"
    );
    return loadReq.post(params.ids);
  }

  static loadNotificationsMarkAllAsDone(params, successCallback, failCallback) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Notification/ConsiderAllAsDeleted"
    );
    return loadReq.get();
  }
}
