import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const APP_NAME = "GFDPlatform";

const PageTitle = ({ title = "" }) => {
  const unreadNumber = useSelector(
    (state) => state.notificationsReducer.unreadNumber
  );
  return (
    <Helmet>
      <title>{`${unreadNumber > 0 ? `(${unreadNumber}) ` : ""}${
        title && `${title} - `
      }${APP_NAME}`}</title>
    </Helmet>
  );
};

export default PageTitle;
