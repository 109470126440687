import getInitialsFromName from "js/utils/helpers/getInitialsFromName";
import React, { useMemo } from "react";

import "./Avatar.scss";

const Avatar = ({ fullName, photo = null }) => {
  const text = useMemo(() => {
    if (!fullName) {
      return <i class="pwc-users-actions-person-2" />;
    }
    return getInitialsFromName(fullName);
  }, [fullName]);
  return (
    <div className="avatar">
      {photo !== null && <img src={photo} alt={fullName} />}
      {text}
    </div>
  );
};

export default Avatar;
