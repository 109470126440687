const getInitialsFromName = (name) => {
  let initials = name
    .split(" ")
    .map((n) => n[0].toUpperCase())
    .join("");
  if (initials.length > 3) {
    initials = initials.substr(0, 2) + initials.substr(initials.length - 1, 1);
  }
  return initials;
};

export default getInitialsFromName;
