import facilitiesAgent from "./facilitiesAgent";

/*=====----- COMMON LABELS -----=====*/
const common = {
  __FUNDS_: {
    en: "Funds",
  },
  __SETTINGS_: {
    en: "Settings",
  },
  __CURRENT_DISTRIBUTION_: {
    en: "Current distribution",
  },
  __SUB_FUND_SHARECLASS_: {
    en: "Sub-fund / Share class",
  },
  __ISSUE_HAPPENED_: {
    en: "Oops, something went wrong :-(",
  },
  __PLEASE_SELECT_A_FUND_: {
    en: "Please select a fund",
  },
  __PLEASE_SELECT_COUNTRY_PROFILE_SECTION_: {
    en: "Please select a country profile section",
  },
  __PLEASE_SELECT_A_FILETYPE_: {
    en: "Please select a document type",
  },
  __FUNDS_FILETYPES_: {
    en: "Funds / Document types",
  },
  __FUND_TYPES_COUNTRY_PROFILE_SECTION_: {
    en: "Fund types / Country profile section",
  },
  __PLEASE_SELECT_A_COUNTRY_: {
    en: "Please select a country",
  },
  __SELECT_A_COUNTRY_: {
    en: "Please select a country",
  },
  __SELECT_A_DATE_: {
    en: "Select a date",
  },
  __VALIDATE_: {
    en: "Validate",
  },
  __VALIDATE_ALL_: {
    en: "Validate all",
  },
  __COUNTRIES_: {
    en: "Countries",
  },
  __REQUIRED_DOCUMENTS_: {
    en: "Required documents",
  },
  __N_A_: {
    en: "N/A",
  },
  __SEND_: {
    en: "Send",
  },
  __ANSWER_: {
    en: "Answer",
  },
  __CONTACT_TYPE_CONTACT_NAME_: {
    en: "Contact Type / Contact name",
  },
  __VIEW_ALL_LOCAL_CONTACTS_: {
    en: "View all delegates",
  },
  __GO_TO_TOPIC_: {
    en: "Go to topic",
  },
  __BACK_: {
    en: "Back",
  },
  __EDIT_: {
    en: "Edit",
  },
  __ALL_DONE_: {
    en: "All done",
  },
  __NAME_: {
    en: "Name",
  },
  __START_DATE_: {
    en: "Start date",
  },
  __END_DATE_: {
    en: "End date",
  },
  __COMPLETION_DATE_: {
    en: "Completion date",
  },
  __STATUS_: {
    en: "Status",
  },
  __FUND_: {
    en: "Fund",
  },
  __1_2_FUNDS_: {
    en: "1-2 funds",
  },
  __3_4_FUNDS_: {
    en: "3-4 funds",
  },
  __5_PLUS_FUNDS_: {
    en: "5+ funds",
  },
  __FILTER_: {
    en: "Filter",
  },
  __FILTERS_: {
    en: "Filters",
  },
  __YEAR_: {
    en: "Year",
  },
  __DOWNLOAD_SELECTED_FILES_: {
    en: "Download selected documents",
  },
  __ADD_FILE_: {
    en: "Add document",
  },
  __ADD_DOCUMENT_: {
    en: "Add document",
  },
  __ADD_REQUIREMENT_: {
    en: "Add requirement",
  },
  __COUNTRY_PROFILE_: {
    en: "Country profile",
  },
  __LOCAL_CONTACTS_: {
    en: "Delegates",
  },
  __CREATE_NEW_REPORT_: {
    en: "Create new report",
  },
  __SUB_FUND_: {
    en: "Sub-fund",
  },
  __DESCRIPTION_: {
    en: "Description",
  },
  __DASHBOARD_: {
    en: "Dashboard",
  },
  __CALENDAR_: {
    en: "Calendar",
  },
  __TASK_: {
    en: "Task",
  },
  __TASKS_: {
    en: "Tasks",
  },
  __TASKS_LIST_: {
    en: "Tasks list",
  },
  __VIEW_ALL_TASKS_: {
    en: "View all tasks",
  },
  __TASK_WITH_ACTION_REQUIRED_: {
    en: "Task with action required",
  },
  __DETAILS_: {
    en: "Details",
  },
  __TASK_DETAILS_: {
    en: "Task details",
  },
  __DOWNLOAD_: {
    en: "Download",
  },
  __SEND_BY_EMAIL_: {
    en: "Send by email",
  },
  __GO_TO_COUNTRY_DETAILS_: {
    en: "Go to country details",
  },
  __TASK_PROGRESSION_: {
    en: "Task progression",
  },
  __EVENT_: {
    en: "Event",
  },
  __EVENTS_: {
    en: "Events",
  },
  __VIEW_ALL_EVENTS_: {
    en: "View all events",
  },
  __LIBRARY_: {
    en: "Library",
  },
  __DISTRIBUTION_MAP_: {
    en: "Distribution map",
  },
  __DISTRIBUTION_MATRIX_: {
    en: "Distribution matrix",
  },
  __CHAT_: {
    en: "Chat",
  },
  __MARKETING_ARRANGEMENTS_: {
    en: "Marketing arrangements",
  },
  __LOCAL_ENTITIES_: {
    en: "Delegates",
  },
  __REPORTING_: {
    en: "Reporting",
  },
  __ADMINISTRATIVE_: {
    en: "Administrative",
  },
  __WELCOME_: {
    en: "Welcome",
  },
  __PUBLICATIONS_: {
    en: "Publications",
  },
  __3W_NEWS_: {
    en: "Carne flash news",
  },
  __VIEW_IN_3W_: {
    en: "View",
  },
  __CONTACTS_: {
    en: "Contacts",
  },
  __NOT_REGISTERED_: {
    en: "Not reg",
  },
  __TO_BE_REGISTERED_: {
    en: "To be reg",
  },
  __TO_BE_DE_REGISTERED_: {
    en: "To be de-reg",
  },
  __TO_BE_REG_FOR_INSTITUTIONAL_: {
    en: "To be reg for institutional",
  },
  __UNDER_REGISTRATION_: {
    en: "Under registration",
  },
  __UNDER_DE_REGISTRATION_: {
    en: "Under de-registration",
  },
  __UNDER_DE_REGISTRATION_INSTITUTIONAL_: {
    en: "Under de-registration institutional",
  },
  __TO_BE_REGISTERED_FOR_RETAIL_: {
    en: "To be reg for retail",
  },
  __PRIVATE_PLACEMENT_: {
    en: "Registration Exemption",
  },
  __REGISTERED_FOR_INSTITUTIONAL_: {
    en: "Reg for institutional",
  },
  __REGISTERED_FOR_RETAIL_: {
    en: "Reg for retail",
  },
  __DE_REGISTERED_: {
    en: "De-reg",
  },
  __TO_BE_PRE_MARKETED_: {
    en: "To be Pre-Marketed",
  },
  __PRE_MARKETING_: {
    en: "Pre-Marketing",
  },
  __PRE_MARKETING_STOPPED_: {
    en: "Pre-Marketing Stopped",
  },
  __LISTED_: {
    en: "Listed",
  },
  __TO_BE_LISTED_: {
    en: "To be listed",
  },
  __REGISTERED_FOR_NON_INSTITUTIONAL_: {
    en: "Reg for non institutional",
  },
  __EXPORT_: {
    en: "Export",
  },
  __SAVE_FILTERS_: {
    en: "Save filters",
  },
  __CANCEL_: {
    en: "Cancel",
  },
  __CONFIRM_: {
    en: "Confirm",
  },
  __SHOW_FILTERS_: {
    en: "Show filters",
  },
  __SHOW_: {
    en: "Show",
  },
  __CAP_DIST_: {
    en: "Cap/dist",
  },
  __TASK_TYPE_: {
    en: "Task type",
  },
  __DRAG_AND_DROP_FILES_: {
    en: "Drag and drop documents here",
  },
  __DRAG_AND_DROP_FILES_MANUAL_: {
    en: "Or click to browse your documents",
  },
  __LAUNCH_DATE_: {
    en: "Launch date",
  },
  __DOCUMENT_: {
    en: "Document",
  },
  __DOCUMENT_NAME_: {
    en: "Document name",
  },
  __FUND_SUB_FUND_SHARECLASS_: {
    en: "Fund, Sub-fund, Share class",
  },
  __OUTPUT_: {
    en: "Output",
  },
  __COUNTRY_: {
    en: "Country",
  },
  __SHOW_DATES_: {
    en: "Show dates",
  },
  __SHOW_SHARE_CLASS_: {
    en: "Show share class",
  },
  __REPORT_TYPE_: {
    en: "Report type",
  },
  _HELPER_LIBRARY_DRAG_AND_DROP_: {
    en: "You can drag and drop documents in desired fund / document type",
  },
  __COMMENTS_: {
    en: "Comments",
  },
  __COMMENT_: {
    en: "Comment",
  },
  __VIEW_ALL_ENTITIES_: {
    en: "View all delegates",
  },
  __ENTITIES_: {
    en: "Delegates",
  },
  __REPORTING_PERIOD_: {
    en: "Reporting period",
  },
  __GROUPED_ACTIONS_: {
    en: "Grouped actions",
  },
  __CREATION_DATE_: {
    en: "Creation date",
  },
  __REFRESH_: {
    en: "Refresh",
  },
  __FUND_SUBFUND_SHARE_: {
    en: "Fund / Sub-Fund / Share class",
  },
  __ISIN_: {
    en: "ISIN",
  },
  __NEW_TASK_: {
    en: "New task",
  },
  __VERSION_: {
    en: "Version",
  },
  __FILENAME_: {
    en: "Document name",
  },
  __DOCUMENT_DATE_: {
    en: "Document date",
  },
  __LANGUAGE_: {
    en: "Language",
  },
  __LANGUAGES_: {
    en: "Languages",
  },
  __UPLOAD_DATE_: {
    en: "Upload date",
  },
  __VIEW_FILES_: {
    en: "View documents",
  },
  __NEW_FILE_: {
    en: "New document",
  },
  __NEW_PROSPECTUS_DETECTED_: {
    en: "New document(s) detected",
  },
  __NEW_PROSPECTUS_DETECTED_DESCRIPTION_: {
    en: "Do you want to create a prospectus update task?",
  },
  __YES_: {
    en: "Yes",
  },
  __PLEASE_SELECT_A_DOCUMENT_: {
    en: "Please select a document",
  },
  __UPLOADED_BY_: {
    en: "Uploaded by",
  },
  __UPLOAD_: {
    en: "Upload",
  },
  __SEND_THE_REPORT_: {
    en: "Send the report",
  },
  __DOWNLOAD_THE_REPORT_: {
    en: "Download the report",
  },
  __CUSTOM_PERIOD_: {
    en: "Custom period",
  },
  __SHOW_REPORT_BY: {
    en: "Show report by",
  },
  __PERIOD_: {
    en: "Period",
  },
  __DATAS_TO_SHOW_: {
    en: "Datas to show",
  },
  __EVENT_TYPE_: {
    en: "Event type",
  },
  __ADRESS_: {
    en: "Adress",
  },
  __YOUR_OPEN_TASKS_: {
    en: "Your open tasks",
  },
  __YOUR_QUESTIONS_: {
    en: "Your questions",
  },
  __COMPLETED_TASKS_: {
    en: "Completed tasks",
  },
  __EVENTS_OF_THE_DAY_: {
    en: "Events of the day",
  },
  __3W_FLASHNEWS_: {
    en: "Carne flash news",
  },
  __YOUR_GFD_TEAM_: {
    en: "Your Carne team",
  },
  __DOCUMENT_TYPE_: {
    en: "Document type",
  },
  __DOCUMENT_SUB_TYPE_: {
    en: "Document sub type",
  },
  __VIEW_ENTITIES_: {
    en: "View delegates",
  },
  __NEW_REPORT_: {
    en: "New report",
  },
  __RESPONSIBLE_: {
    en: "Responsible",
  },
  __DROP_FILE_HERE_OR_: {
    en: "Drop file here or",
  },
  __SELECT_FILE_: {
    en: "select file",
  },
  __NOTIFICATIONS_: {
    en: "Notifications",
  },
  __BACK_TO_TASKS_LIST_: {
    en: "Back to tasks list",
  },
  __BACK_TO_DISTRIBUTION_MAP_: {
    en: "Back to distribution map",
  },
  __EXPORT_SELECTED_ENTITIES_: {
    en: "Export selected delegates",
  },
  __ADD_ENTITY_: {
    en: "Add delegate",
  },
  __EDIT_ENTITY_: {
    en: "Edit delegate",
  },
  __COUNTRY_NAME_: {
    en: "Country / Name",
  },
  __FILETYPE_: {
    en: "Filetype",
  },
  __ENTITIES_FUNDS_: {
    en: "Delegates / Funds",
  },
  __THERE_IS_NO_ACTIVE_DISCUSSION_FOR_THIS_TASK_: {
    en: "There is no active discussion for this task",
  },
  __IMPORT_: {
    en: "Import",
  },
  __UCITS_: {
    en: "UCITS",
  },
  __AIF_: {
    en: "AIF",
  },
  __COUNTRIES_FUNDS_: {
    en: "COUNTRIES / FUNDS",
  },
  __SELECT_ALL_: {
    en: "Select all",
  },
  __DESELECT_ALL_: {
    en: "Deselect all",
  },
  __LIFE_CYCLE_STATUS_: {
    en: "Life cycle status",
  },
  __DOMICILE_: {
    en: "Domicile",
  },
  __CURRENCY_: {
    en: "Currency",
  },
  __CLASS_: {
    en: "Class",
  },
  __CLOSE_: {
    en: "Close",
  },
  __REGISTRATION_COUNTRY_: {
    en: "Registration country",
  },
  __REGISTRATION_STATUS_: {
    en: "Registration status",
  },
  __NEW_FILTER_: {
    en: "New filter",
  },
  __UPDATE_EXISTING_FILTER_: {
    en: "Update existing filter",
  },
  __FILTER_NAME_: {
    en: "Filter name",
  },
  __THERE_IS_CURRENTLY_NO_FUND_: {
    en: "There is currently no fund",
  },
  __CHANGE_SELECTION_STATUS_: {
    en: "Change selection status",
  },
  __CHANGE_STATUS_: {
    en: "Edit status",
  },
  __CANCEL_MATRIX_UPDATE_: {
    en: "Cancel edit",
  },
  __CONFIRM_MATRIX_UPDATE_: {
    en: "Confirm matrix update",
  },
  __ELEMENTS_SELECTED_: {
    en: "elements selected",
  },
  __ELEMENT_SELECTED_: {
    en: "element selected",
  },
  __TYPE_TO_SEARCH_A_PROMOTOR_: {
    en: "Type to search a promotor",
  },
  __CANCEL_STATUS_UPDATE_: {
    en: "Cancel status update",
  },
  __CONFIRM_STATUS_UPDATE_: {
    en: "Confirm status update",
  },
  __NATIVE_SELECT_BOX_: {
    en: "Native select box",
  },
  __PICK_A_DATE_: {
    en: "Select a date",
  },
  __SHARE_CLASS_: {
    en: "Share class",
  },
  __GENERAL_: {
    en: "General",
  },
  __APPLY_FILTERS_: {
    en: "Apply filters",
  },
  __TYPE_HERE_TO_SEARCH_: {
    en: "Search...",
  },
  __STATUS_UPDATED_: {
    en: "Status updated",
  },
  __PLEASE_CONFIRM_MATRIX_UPDATE_TO_SAVE_YOUR_CHANGES_: {
    en: "Please confirm matrix update to save your changes",
  },
  __CURRENT_CLIENT_: {
    en: "Current client",
  },
  __NO_FILTER_OPTION_AVAILABLE_: {
    en: "No option available",
  },
  __PLEASE_CHOOSE_THE_TASK_YOU_WANT_TO_EXECUTE_: {
    en: "Please choose the task you want to execute",
  },
  __SUMMARY_: {
    en: "Summary",
  },
  __REQUIREMENTS_: {
    en: "Requirements",
  },
  __NEXT_: {
    en: "Next",
  },
  __PREVIOUS_: {
    en: "Previous",
  },
  __FINISH_: {
    en: "Finish",
  },
  __DOWNLOAD_SELECTED_DOCUMENTS_: {
    en: "Download selected documents",
  },
  __DROP_YOUR_FILES_HERE_TO_UPLOAD_: {
    en: "Drop your file(s) here to upload.",
  },
  __YOU_CAN_ALS_DRAG_YOUR_FILES_IN_FOLDER_ON_THE_LEFT_SIDE_: {
    en: "You can also drag your file(s) in folder on the left side.",
  },
  __OR_: {
    en: "Or",
  },
  __SELECT_FILES_: {
    en: "select file(s)",
  },
  __PLEASE_WAIT_: {
    en: "Please wait it may take a few minutes",
  },
  __PROCESSING_: {
    en: "Processing",
  },
  __CONFIRM_NON_PRODUCTION_: {
    en: "This is a non production environment",
  },
  __CONFIRM_NON_PRODUCTION_MESSAGE_: {
    en: "Please confirm that your file does not contain any production data or client’s identifiable information. All data, except test clients, will be automatically deleted every day.",
  },
  __INVALID_FILETYPE_: {
    en: "Invalid filetype",
  },
  __YOU_HAVE_TO_PROVIDE_EXCEL_FILE_: {
    en: "You must provide an Excel file",
  },
  __DROP_YOUR_DISTRIBUTION_MATRIX_HERE_TO_UPLOAD_: {
    en: "Drop your distribution matrix here to upload.",
  },
  __INVALID_SELECTION_: {
    en: "Invalid selection",
  },
  __YOU_MUST_DRAG_YOUR_FILES_IN_A_DOCUMENT_TYPE_ONLY_: {
    en: "You must drag your file(s) in a document type only",
  },
  __YOU_MUST_PROVIDE_FOLLOWING_FILETYPES_: {
    en: "You must provide following filetypes",
  },
  __DOCUMENT_UPLOAD_: {
    en: "Document upload",
  },
  __EDIT_SELECTION_: {
    en: "Edit selection",
  },
  __CONFIRM_METADATAS_: {
    en: "Confirm metadatas",
  },
  __CANCEL_FILE_UPLOAD_: {
    en: "Cancel file upload",
  },
  __CONFIRM_CHANGES_: {
    en: "Confirm changes",
  },
  __VERSION_DOCUMENT_NAME_: {
    en: "Version/Document name",
  },
  __RELATED_TO_: {
    en: "Related to",
  },
  __METADATA_STATUS_: {
    en: "Metadata status",
  },
  __ADD_DOCUMENT_IN_THIS_FOLDER_: {
    en: "Add document in this folder",
  },
  __CURR_: {
    en: "Curr",
  },
  __APPLY_: {
    en: "Apply",
  },
  __VIEW_: {
    en: "View",
  },
  __DELETE_: {
    en: "Delete",
  },
  __EDIT_METADATA_: {
    en: "Edit metadata",
  },
  __ONE_VALUE_MUST_BE_SELECTED_: {
    en: "One value must be selected",
  },
  __DATA_APPEARS_TO_BE_GOOD_: {
    en: "Data appears to be good",
  },
  __DATE_: {
    en: "Date",
  },
  __REVIEWED_DATE_: {
    en: "Reviewed Date",
  },
  __USER_: {
    en: "User",
  },
  __LEVEL_: {
    en: "Level",
  },
  __MESSAGE_: {
    en: "Message",
  },
  __EXCEPTION_: {
    en: "Exception",
  },
  __CURRENT_PROCESS_: {
    en: "Current process",
  },
  __JOB_NAME_: {
    en: "Job name",
  },
  __START_RUNTIME_: {
    en: "Start runtime",
  },
  __END_RUNTIME_: {
    en: "End runtime",
  },
  __DURATION_: {
    en: "Duration",
  },
  __DISSEMINATION_: {
    en: "Dissemination",
  },
  __TASK_TEMPLATES_: {
    en: "Task templates",
  },
  __EMAIL_TEMPLATES_: {
    en: "E-mail templates",
  },
  __DOCUMENT_TEMPLATES_: {
    en: "Document templates",
  },
  __NAMING_: {
    en: "Naming",
  },
  __MAILING_LIST_: {
    en: "Mailing list",
  },
  __RULES_: {
    en: "Rules",
  },
  __EMAIL_TEMPLATE_NAME_: {
    en: "E-mail template name",
  },
  __DOCUMENT_TEMPLATE_NAME_: {
    en: "Document template name",
  },
  __DOCUMENT_TEMPLATE_: {
    en: "Document template",
  },
  __EDITION_DATE_: {
    en: "Edition date",
  },
  __LAST_EDITOR_: {
    en: "Last editor",
  },
  __NEW_TEMPLATE_: {
    en: "New template",
  },
  __EMAIL_TEMPLATE_CONTENT_: {
    en: "E-mail template content",
  },
  __EMAIL_TEMPLATE_COUNTRIES_: {
    en: "E-mail template countries",
  },
  __PROPERTIES: {
    en: "Properties",
  },
  __TITLE_: {
    en: "Title",
  },
  __TO_: {
    en: "To",
  },
  __CONTENT_: {
    en: "Content",
  },
  __TAGS_: {
    en: "Tags",
  },
  __SUBJECT_: {
    en: "Subject",
  },
  __NEW_EMAIL_TEMPLATE_: {
    en: "New e-mail template",
  },
  __NEW_DOCUMENT_TEMPLATE_: {
    en: "New document template",
  },
  __SELECT_COUNTRIES_TARGETED_: {
    en: "Select countries targeted",
  },
  __NEW_TASK_TEMPLATE_: {
    en: "New task template",
  },
  __ALL_COUNTRIES_: {
    en: "All countries",
  },
  __SELECTED_COUNTRIES_: {
    en: "Selected countries",
  },
  __PROPERTIES_: {
    en: "Properties",
  },
  __SEARCH_: {
    en: "Search",
  },
  __PROPERTIES_AND_SUBTASK_: {
    en: "Properties and subtask",
  },
  __DOCUMENTS_: {
    en: "Documents",
  },
  __PLEASE_SELECT_A_TASK_TYPE_: {
    en: "Please select a task type",
  },
  __SUB_TASK_: {
    en: "Sub task",
  },
  __POSITION_: {
    en: "Position",
  },
  __AUTOMATIC_: {
    en: "Automatic",
  },
  __CONDITION_: {
    en: "Condition",
  },
  __ACTION_: {
    en: "Action",
  },
  __FORCABLE_: {
    en: "Forceable",
  },
  __NO_: {
    en: "No",
  },
  __PLEASE_SELECT_A_CONDITION_: {
    en: "Please select a condition",
  },
  __PLEASE_SELECT_AN_ACTION_: {
    en: "Please select an action",
  },
  __ADD_THIS_SUB_TASK_: {
    en: "Add this sub task",
  },
  __NO_SUB_TASK_: {
    en: "Sub task missing",
  },
  __YOU_MUST_ADD_AT_LEAST_ONE_SUB_TASK_: {
    en: "You must add at least one sub task",
  },
  __ADD_A_SUB_TASK_USING_THE_FORM_ABOVE_: {
    en: "Add a sub task using the form above",
  },
  __PLEASE_SELECT_EMAIL_TEMPLATES_YOU_WANT_TO_USE_FOR_DISSEMINATION_: {
    en: "Please select email template you want to use for dissemination",
  },
  __NO_EMAIL_TEMPLATE_: {
    en: "Email template is missing",
  },
  __YOU_MUST_CHECK_AT_LEAST_ONE_EMAIL_TEMPLATE_: {
    en: "You must check at least one email template",
  },
  __EMAIL_TEMPLATE_COUNTRY_: {
    en: "Email template / country",
  },
  __PLEASE_ADD_RECIPIENT_TO_EMAIL_TEMPLATE_AND_COUNTRIES: {
    en: "Please add recipient to email template and countries",
  },
  __MAILING_LIST_BY_EMAIL_TEMPLATE_AND_COUNTRIES_: {
    en: "Mailing list by email template and countries",
  },
  __EMAIL_ADDRESS_: {
    en: "Email address",
  },
  __FTP_SERVER_: {
    en: "FTP",
  },
  __RECIPIENT_TYPE_: {
    en: "Recipient type",
  },
  __EMAIL_TEMPLATE_: {
    en: "Email template",
  },
  __ADD_EMAIL_ADDRESS_: {
    en: "Add email address",
  },
  __FORMAT_DATE_: {
    en: "DD/MM/YYYY",
  },
  __SELECT_ONE_: {
    en: "Select one",
  },
  __PRINCIPAL_: {
    en: "Principal",
  },
  __SECONDARY_CC_: {
    en: "Secondary (Cc)",
  },
  __HIDDEN_BCC_: {
    en: "Hidden (BCc)",
  },
  __ALL_RECIPIENTS_: {
    en: "All recipients",
  },
  __CREATE_DISSEMINATION_RULE_: {
    en: "Create dissemination rule",
  },
  __CREATE_MAILING_RULE_: {
    en: "Create mailing rule",
  },
  __EDIT_MAILING_RULE_: {
    en: "Edit mailing rule",
  },
  __TASK_TEMPLATES_LIST_: {
    en: "Task templates list",
  },
  __PLEASE_ADD_DOCUMENT_TYPE_TO_EMAIL_TEMPLATE_AND_COUNTRIES_: {
    en: "Please add document type to email template and countries",
  },
  __DOCUMENT_BY_EMAIL_TEMPLATE_AND_COUNTRIES_: {
    en: "Documents by email template and countries",
  },
  __NEW_DISSEMINATION_RULE_: {
    en: "New dissemination rule",
  },
  __DISSEMINATE_SUB_TASK_: {
    en: "Disseminate sub task",
  },
  __RULE_: {
    en: "rule",
  },
  __FILE_TYPE_: {
    en: "File type",
  },
  __FILE_LANGUAGE_: {
    en: "File language",
  },
  __ADD_DOCUMENT_TYPE_: {
    en: "Add document type",
  },
  __ALL_DOCUMENT_TYPES_: {
    en: "All document types",
  },
  __SELECT_A_PROMOTOR_: {
    en: "Promotor",
  },
  __EDIT_DISSEMINATION_RULE_: {
    en: "Edit dissemination rule",
  },
  __EMAIL_: {
    en: "E-mail",
  },
  __PLEASE_SELECT_A_SUB_FUND_: {
    en: "Please select a sub-fund",
  },
  _SELECT_A_SUB_FUND_: {
    en: "Select a sub-fund",
  },
  __PLEASE_SELECT_A_SHARE_CLASS_: {
    en: "Please select a share class",
  },
  __PLEASE_SELECT_A_MAIL_TEMPLATE_: {
    en: "Please select a mail template",
  },
  __SELECT_A_SHARE_CLASS_: {
    en: "Select a share class",
  },
  __PLEASE_SELECT_A_LANGUAGE_: {
    en: "Please select a language",
  },
  __SELECT_A_LANGUAGE_: {
    en: "Select a language",
  },
  __FUND_DOMICILE_: {
    en: "Fund domicile",
  },
  __FUND_FORMS_: {
    en: "Fund form",
  },
  __FUND_TYPES_: {
    en: "Fund type",
  },
  __SELECT_PERIOD_: {
    en: "Select period",
  },
  __VIEW_SELECTION_: {
    en: "View selection",
  },
  __ELEMENT_: {
    en: "element",
  },
  __ELEMENTS_: {
    en: "elements",
  },
  __DOWNLOAD_SELECTION_: {
    en: "Download selection",
  },
  __REJECT_: {
    en: "Reject",
  },
  __ACCEPT_: {
    en: "Accept",
  },
  __REJECT_ALL_: {
    en: "Reject all",
  },
  __ACCEPT_ALL_: {
    en: "Accept all",
  },
  __FILE_METADATA_FUND_: {
    en: "File metadata fund",
  },
  __ERROR_: {
    en: "Error",
  },
  __IMPORT_IN_PROGRESS_: {
    en: "Import in progress",
  },
  __FILE_: {
    en: "File",
  },
  __SUB_FUNDS_: {
    en: "Sub-funds",
  },
  __SHARE_CLASSES_: {
    en: "Share classes",
  },
  __SHARE_CLASS_NAME_: {
    en: "Share class name",
  },
  __EDIT_EMAIL_TEMPLATE_: {
    en: "Edit E-mail template",
  },
  __EDIT_DOCUMENT_TEMPLATE_: {
    en: "Edit document template",
  },
  __EDIT_TASK_TEMPLATE_: {
    en: "Edit task template",
  },
  __SEARCH_ISIN_: {
    en: "Search ISIN",
  },
  __CLEAR_: {
    en: "Clear",
  },
  __HIDE_FILTERS_: {
    en: "Hide filters",
  },
  __FUND_NAME_: {
    en: "Fund name",
  },
  __FILE_UPLOAD_: {
    en: "File upload",
  },
  __IN_PENDING_LIST_: {
    en: "in pending list",
  },
  __FILES_: {
    en: "Files",
  },
  __FILE_NAME_: {
    en: "File name",
  },
  __OK_: {
    en: "OK",
  },
  __SET_METADATA_FOR_SELECTED_DOCUMENTS_: {
    en: "Set metadata for selected documents",
  },
  __WITHOUT_METADATA_: {
    en: "without metadata",
  },
  __SELECTED_: {
    en: "selected",
  },
  __SELECT_A_FUND_: {
    en: "Select a fund",
  },
  __SELECT_A_FILE_TYPE_: {
    en: "Select a file type",
  },
  __SELECT_A_DOCUMENT_TYPE_: {
    en: "Select a document type",
  },
  __FILE_MEDATA_FUND_: {
    en: "File metadata fund",
  },
  __WITH_EXCEPTION_: {
    en: "with exception",
  },
  __BACK_TO_LIBRARY_: {
    en: "Back to library",
  },
  __SELECT_A_FUND_AND_A_DOCUMENT_TYPE_ON_THE_LEFT_: {
    en: "Select a fund and a document type on the left",
  },
  __TASK_TEMPLATE_NAME_: {
    en: "Task template name",
  },
  __REJECTED_: {
    en: "Rejected",
  },
  __SUCCESSFULLY_UPLOADED_: {
    en: "successfully uploaded",
  },
  __FILE_ERROR_: {
    en: "File error",
  },
  __FILE_WILL_NOT_BE_UPLOADED_: {
    en: "File will not be uploaded",
  },
  __NO_NOTIFICATION_YET_: {
    en: "No notification... yet.",
  },
  __LOG_OUT_: {
    en: "Log off",
  },
  __LEGEND_DM_ITEM_1_: {
    en: "1-3 sub-funds",
  },
  __LEGEND_DM_ITEM_2_: {
    en: "4-6 sub-funds",
  },
  __LEGEND_DM_ITEM_3_: {
    en: "7+ sub-funds",
  },
  __ONBOARDING_DM_: {
    en: "Once uploaded, please verify the integrity of your imported matrix then press DONE",
  },
  __ONBOARDING_DISSEMINATION_: {
    en: "Please configure new dissemination rules or edit existing dissemination rules (if needed)",
  },
  __PROMOTOR_PROPERTIES_: {
    en: "Promotor properties",
  },
  __PROMOTOR_USERS_: {
    en: "Promotor users",
  },
  __ONBOARDING_SETTINGS_: {
    en: "Add new promoter accounts (please configure e-mail address and user name)",
  },
  __ADD_NEW_PROMOTOR_: {
    en: "Add new promotor",
  },
  __ADD_A_NEW_PROMOTOR_: {
    en: "Add a new promotor",
  },
  __PROMOTER_SETUP_: {
    en: "Promotor setup",
  },
  __PROMOTOR_NAME_: {
    en: "Client name",
  },
  __PROMOTOR_DOMICILE_: {
    en: "Client domicile",
  },
  __PROMOTOR_CLIENT_ID_SETUP__: {
    en: "Warning: To take advantage of the library, reports or email attachments, please contact the product team to configure a Client ID at ",
  },
  __MISSING_DOCUMENTS_: {
    en: "Missing documents",
  },
  __CONFIRMATION_REQUIRED_: {
    en: "Confirmation required",
  },
  __PROGRESSION_: {
    en: "Progression",
  },
  __DOC_DATE_: {
    en: "Doc date",
  },
  __DOCUMENT_MISSING_: {
    en: "Document missing",
  },
  __AUTHOR_: {
    en: "Author",
  },
  __REASON_: {
    en: "Reason",
  },
  __COMPLETIONDATE_: {
    en: "Completion Date",
  },
  __MERGE_PDF_: {
    en: "Merge PDF",
  },
  __EXPORT_AS_EXCEL_: {
    en: "Export as Excel",
  },
  __EXPORT_AS_PDF_: {
    en: "Export as PDF",
  },
  __EXPORT_REPORT_: {
    en: "Export report",
  },
  __PDF_REPORT_: {
    en: "PDF Report",
  },
  __EXCEL_REPORT_: {
    en: "Excel report",
  },
  __SHOW_TO_DO_LIST_: {
    en: "Show to-do list",
  },
  __ALL_: {
    en: "All",
  },
  __INITIATED_: {
    en: "Initiated",
  },
  __ON_GOING_: {
    en: "Ongoing",
  },
  __LATE_: {
    en: "Late",
  },
  __ON_HOLD_: {
    en: "On hold",
  },
  __COMPLETED_: {
    en: "Completed",
  },
  __CANCELED_: {
    en: "Canceled",
  },
  __VIEW_OPTIONS_: {
    en: "View options",
  },
  __TASK_TEMPLATE_: {
    en: "Task template",
  },
  __DELETE_SELECTION_: {
    en: "Delete selection",
  },
  __LEGAL_MENTIONS_: {
    en: "Legal mentions",
  },
  __DISPLAYED_COUNTRIES_: {
    en: "Displayed countries",
  },
  __SAVE_: {
    en: "Save",
  },
  __RESET_: {
    en: "Reset",
  },
  __PICK_A_STATUS_: {
    en: " Select a status",
  },
  __APPLY_STATUS_: {
    en: "Apply selection",
  },
  __CLIENT_PROPERTIES_: {
    en: "Client properties",
  },
  __CLIENT_USERS_: {
    en: "Client users",
  },
  __SUB_TYPE_: {
    en: "Sub type",
  },
  __PLEASE_SELECT_A_SUB_TYPE_: {
    en: "Please select a sub type",
  },
  __SELECT_A_SUB_TYPE_: {
    en: "Select a sub type",
  },
  __FILE_UPLOAD_WIZARD_: {
    en: "File upload wizard",
  },
  __METADATA_: {
    en: "Metadata",
  },
  __EXCEPTIONS_: {
    en: "Exceptions",
  },
  __COUNTRIES_PANEL_: {
    en: "Countries panel",
  },
  __FULL_NAME_: {
    en: "Full name",
  },
  __CLIENT_RESPONSIBLE_: {
    en: "Client's team",
  },
  __CLIENT_USER_ACCOUNTS_: {
    en: "Client user accounts",
  },
  __ADD_: {
    en: "Add",
  },
  __FUND_FORM_: {
    en: "Fund form",
  },
  __FUND_TYPE_: {
    en: "Fund type",
  },
  __FORM_ERROR_TITLE_: {
    en: "Warning",
  },
  __FORM_ERROR_DESC_: {
    en: "Please check form and correct red fields",
  },
  __USER_FIRST_NAME_: {
    en: "User first name",
  },
  __USER_LAST_NAME_: {
    en: "User last name",
  },
  __CRM_COMPANY_: {
    en: "CRM Company",
  },
  __PLATFORM_ACCESS_: {
    en: "Client access",
  },
  __FUNDS_ACCESS_: {
    en: "Funds access",
  },
  __USER_NAME_: {
    en: "User name",
  },
  __SHARED_FOLDER_: {
    en: "Shared folder",
  },
  __HIDE_COUNTRIES_PANEL_: {
    en: "Hide countries panel",
  },
  __SHOW_COUNTRIES_PANEL_: {
    en: "Show countries panel",
  },
  __FUNDS_DETAILS_: {
    en: "Fund details",
  },
  __RIGHTS_MANAGEMENT_: {
    en: "Rights management",
  },
  __GFD_STAFF_: {
    en: "GFD Staff",
  },
  __EXTERNAL_USER_RIGHTS_: {
    en: "External Users",
  },
  __CLIENT_USERS_RIGHTS_: {
    en: "Client users",
  },
  __SUPPORT_USERS_RIGHTS_: {
    en: "Support",
  },
  __SELECT_IS_SUPPORT_: {
    en: "Select is support",
  },
  __PLEASE_SELECT_A_STAFF_: {
    en: "Please select a staff",
  },
  __ADMINISTRATOR_: {
    en: "Administrator",
  },
  __CLIENT_NOTIFICATION_: {
    en: "Client notification",
  },
  __IS_AN_SYSTEM_ADMINISTRATOR_: {
    en: "Is a system administrator",
  },
  __REVIEW_CHANGES_: {
    en: "Preview edit",
  },
  __NEW_REPORTING_: {
    en: "New reporting",
  },
  __TYPE_: {
    en: "Type",
  },
  __SCOPE_: {
    en: "Scope",
  },
  __PLEASE_SELECT_A_REPORT_TYPE_: {
    en: "Please select a report type",
  },
  __FIXED_: {
    en: "Fixed",
  },
  __CUSTOM_: {
    en: "Custom",
  },
  __CLIENT_: {
    en: "Client",
  },
  __NEW_USER_: {
    en: "New user",
  },
  __FUND_ACCESS_: {
    en: "Fund access",
  },
  __ROLE_: {
    en: "Role",
  },
  __ADD_USER_: {
    en: "Add user",
  },
  __USERNAME_: {
    en: "Username",
  },
  __ADD_ACCESS_: {
    en: "Add access",
  },
  __CLIENT_NAME_: {
    en: "Client name",
  },
  __COUNTRY_SCOPE_: {
    en: "Country scope",
  },
  __DISTRIBUTION_SCOPE_: {
    en: "Distribution scope",
  },
  __BY_COUNTRY_: {
    en: "By country",
  },
  __BY_FUND_: {
    en: "By fund",
  },
  __BY_STATUS_: {
    en: "By status",
  },
  __BY_CREATOR_: {
    en: "By creator",
  },
  __PDF_: {
    en: "PDF",
  },
  __EXCEL_: {
    en: "Excel",
  },
  __GROUP_: {
    en: "Group",
  },
  __SORT_: {
    en: "Sort",
  },
  __EXPORT_AS_: {
    en: "Export as",
  },
  __DISPLAYED_OTHER_COUNTRIES_: {
    en: "Displayed other countries",
  },
  __EXTERNAL_USERS_: {
    en: "External users",
  },
  __STANDARD_PERIOD_: {
    en: "Standard period",
  },
  __RECURRENT_REPORTING_: {
    en: "Recurrent reporting",
  },
  __STANDARD_REPORTING_: {
    en: "Standard reporting",
  },
  __ADMIN_MODE_: {
    en: "Admin mode",
  },
  __PHONE_: {
    en: "Phone",
  },
  __ADDRESS_: {
    en: "Address",
  },
  __LICENCE_: {
    en: "Licence",
  },
  __GROUP_SELECTION_: {
    en: "Group selection",
  },
  __UNCLASSIFIED_: {
    en: "Unclassified",
  },
  __MISSING_METADATA_: {
    en: "Missing metadata",
  },
  __LOGS_: {
    en: "Logs",
  },
  __TECHNICAL_LOGS_: {
    en: "Technical logs",
  },
  __JOB_LOGS_: {
    en: "Job logs",
  },
  __HISTORY_: {
    en: "History",
  },
  __ACTIVITY_: {
    en: "Activity",
  },
  __ACTIVITIES_: {
    en: "Activities",
  },
  __MARKETING_ARRANGEMENT_: {
    en: "Marketing arrangement",
  },
  __PART_A_: {
    en: "Part A",
  },
  __PART_B_: {
    en: "Part B",
  },
  __PART_B_AIF_: {
    en: "Part B AIF",
  },
  __PART_B_UCIT_: {
    en: "Part B UCIT",
  },
  __EMAIL_SENT_: {
    en: "Mail sent",
  },
  __CANCEL_COUNTRY_: {
    en: "Cancel country",
  },
  __SUB_FILE_TYPE_: {
    en: "Sub file type",
  },
  __NEW_DOCUMENT_ONLY_: {
    en: "New doc only",
  },
  __DELEGATE_DETAILS_: {
    en: "Delegate Details",
  },
  __DELEGATE_CLEAR_DUE_DILIGENCE_RATING_: {
    en: "Clear Due Diligence Rating",
  },
  __CONFIRM_TO_SUBMIT_: {
    en: "Confirm to submit",
  },
  __NEW_ENTITY_: {
    en: "New entity",
  },
  __FUND_AND_NAME_: {
    en: "Fund / Name",
  },
  __EXPORT_PREVIEW_: {
    en: "Export preview",
  },
  __ACTIVATION_TRIGGER_: {
    en: "Activation trigger",
  },
  __PLEASE_SELECT_AN_ACTIVATION_TRIGGER_: {
    en: "Please select an activation trigger",
  },
  __ENABLED_: {
    en: "Enabled",
  },
  __EDIT_DOCUMENT_RULE_: {
    en: "Edit document rule",
  },
  __CREATE_DOCUMENT_RULE_: {
    en: "Create document rule",
  },
  __STANDARD_: {
    en: "Standard",
  },
  __ADDITIONAL_COUNTRIES_: {
    en: "Additional countries",
  },
  __FORMAT_: {
    en: "File Format",
  },
  __SELECT_ALL_REGISTERED_: {
    en: "Select all registered",
  },
  __DESELECT_ALL_REGISTERED_: {
    en: "Deselect all registered",
  },
  __LEGAL_FORM_: {
    en: "Legal Form",
  },
  __THERE_IS_NO_DATA_YET_: {
    en: "There is no data yet",
  },
  __CURRENT_MONTH_: {
    en: "Current month",
  },
  __CURRENT_QUARTER_: {
    en: "Current quarter",
  },
  __CURRENT_YEAR_: {
    en: "Current year",
  },
  __TASKS_BY_TYPE_: {
    en: "Tasks by type",
  },
  __TASKS_BY_STATUS_: {
    en: "Tasks by status",
  },
  __ON_GOING_TASKS_: {
    en: "Ongoing tasks",
  },
  __SUCCESS_: {
    en: "Success",
  },
  __WARNING_: {
    en: "Warning",
  },
  __UPLOAD_STARTING_: {
    en: "Upload starting",
  },
  __ERROR_NOTIFICATION_: {
    en: "An error occurred. Please try again.",
  },
  __SUCCESS_NOTIFICATION_RULE_CREATED_: {
    en: "Document check rule has been successfully created",
  },
  __SUCCESS_NOTIFICATION_DOCUMENT_TYPE_CREATED_: {
    en: "Document type has been added",
  },
  __NO_DOCUMENT_TYPE_FOR_THIS_COUNTRY_: {
    en: "No document type for this country",
  },
  __SUCCESS_NOTIFICATION_EMAIL_TEMPLATE_DUPLICATED_: {
    en: "Email template has been duplicated",
  },
  __SUCCESS_NOTIFICATION_TASK_TEMPLATE_DUPLICATED_: {
    en: "Task template has been duplicated",
  },
  __SUCCESS_TASK_COUNTRY_CANCELLED_: {
    en: "The country has correctly been cancelled.",
  },
  __NO_AVAILABLE_EMAIL_TEMPLATE_: {
    en: "No available email template",
  },
  __NO_AVAILABLE_TASK_TEMPLATE_: {
    en: "No available task template",
  },
  __DUPLICATE_TEMPLATE_: {
    en: "Duplicate template",
  },
  __ARE_YOU_SURE_TO_DUPLICATE_: {
    en: "Are you sure to duplicate",
  },
  __NOTIFICATION_SUCCESS_EMAIL_TEMPLATE_CREATED_: {
    en: "E-mail Template has been successfully created",
  },
  __NOTIFICATION_SUCCESS_EMAIL_TEMPLATE_EDITED_: {
    en: "E-mail Template has been successfully edited",
  },
  __NONE_: {
    en: "None",
  },
  __MERGE_BY_FUND_: {
    en: "Merge by fund",
  },
  __MERGE_BY_SUBFUND_: {
    en: "Merge by sub-fund",
  },
  __PDF_NAMING_CONVENTION_: {
    en: "PDF naming convention",
  },
  __ZIP_FILES_: {
    en: "Zip files",
  },
  __MERGE_FILES_: {
    en: "Merge files",
  },
  __REFRESH_AUTO_ON_DONE_: {
    en: "This page will automatically refresh once it is done.",
  },
  __SORRY_WITH_COMMA_: {
    en: "Sorry,",
  },
  __IS_CURRENTLY_EDITING_DISSEMINATION_RULES_: {
    en: "is currently editing dissemination rules.",
  },
  __MY_COUNTRIES_OF_DISTRIBUTION_: {
    en: "My countries of distribution",
  },
  __SELECT_: {
    en: "Select",
  },
  __GRID_VIEW_: {
    en: "Grid view",
  },
  __ALTERNATIVE_VIEW_: {
    en: "Alternative view",
  },
  __DISPLAY_DEFAULT_COUNTRIES_: {
    en: "Display default countries",
  },
  __CONFIRM_EDIT_: {
    en: "Confirm edit",
  },
  __THERE_IS_NO_REPORT_YET_: {
    en: "There is no report yet.",
  },
  __SUB_TASKS_: {
    en: "Sub tasks",
  },
  __MAIL_SUB_TASKS_: {
    en: "Mail(s) for subtask",
  },
  __NO_MAILS_SENT_: {
    en: "No email has been sent.",
  },
  __NUMBER_ATTACHMENTS_: {
    en: "Number of attachments :",
  },
  __DISTRIBUTION_: {
    en: "Distribution",
  },
  __TASKS_IN_PROGRESS_: {
    en: "Tasks in progress",
  },
  __MARK_ARR_: {
    en: "Mark. Arr.",
  },
  __FUND_DISTRIBUTION_WATCH_: {
    en: "Fund distribution watch",
  },
  __COPY_: {
    en: "Copy",
  },
  __DASHBOARD_TITLE_: {
    en: "GFD - Dashboard",
  },
  __DISSEMINATION_TITLE_: {
    en: "GFD - Dissemination",
  },
  __DISTRIBUTION_MAP_TITLE_: {
    en: "GFD - Distribution Map",
  },
  __DISTRIBUTION_MATRIX_TITLE_: {
    en: "GFD - Distribution Matrix",
  },
  __ENTITIES_TITLE_: {
    en: "GFD - Delegates",
  },
  __CALENDAR_TITLE_: {
    en: "GFD - Calendar",
  },
  __FUND_DISTRIBUTION_WATCH_TITLE_: {
    en: "GFD - Fund Distribution Watch",
  },
  __FUND_DISTRIBUTION_WATCH_TAGS_TITLE_: {
    en: "GFD - Fund Distribution Watch Tags",
  },
  __FUND_DETAILS_TITLE_: {
    en: "GFD - Fund Details",
  },
  __LIBRARY_TITLE_: {
    en: "GFD - Library",
  },
  __LOGS_TITLE_: {
    en: "GFD - Logs",
  },
  __MARKETING_ARRANGEMENT_TITLE_: {
    en: "GFD - Marketing Arrangement",
  },
  __PUBLICATIONS_TITLE_: {
    en: "GFD - Publications",
  },
  __REPORTING_TITLE_: {
    en: "GFD - Reporting",
  },
  __RIGHTS_MANAGEMENT_TITLE_: {
    en: "GFD - Rights Management",
  },
  __SETTINGS_TITLE_: {
    en: "GFD - Settings",
  },
  __TASKS_TITLE_: {
    en: "GFD - Tasks",
  },
  __DUE_DILIGENCE_RATING_: {
    en: "Due Diligence Rating",
  },
  __DUE_DILIGENCE_RATING_DATE_: {
    en: "Due Diligence Rating Date",
  },
  __DUE_DILIGENCE_RATING_PAIR_FORM_ERROR_TITLE_: {
    en: "Due Diligence Rating - Due Diligence Rating Date Pair Error",
  },
  __DUE_DILIGENCE_RATING_PAIR_FORM_RATING_ERROR_DESC_: {
    en: "You must select a Due Diligence Rating when you select a Due Diligence Rating Date",
  },
  __DUE_DILIGENCE_RATING_PAIR_FORM_RATING_DATE_ERROR_DESC_: {
    en: "You must select a Due Diligence Rating Date when you select a Due Diligence Rating",
  },
  __TRANSMISSION_FAILURE_: {
    en: "Transmission failed",
  },
  __MANAGEMENT_COMPANY_: {
    en: "Management Company",
  },
  __CONTACT_EMAIL_: {
    en: "Contact email",
  },
};
/*=====----- GATHER ALL LABELS -----=====*/
const lang = {
  ...common,
  ...facilitiesAgent,
};

export { lang };
