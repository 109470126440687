export const GET_PART_GET_INIT = "PART_GET:GET_INIT";
export const GET_PART_GET_SUCCESS = "PART_GET:GET_SUCCESS";
export const GET_PART_GET_FAILURE = "PART_GET:GET_FAIL";

export const GET_PART_SET_INIT = "PART_SET:GET_INIT";
export const GET_PART_SET_SUCCESS = "PART_SET:GET_SUCCESS";
export const GET_PART_SET_FAILURE = "PART_SET:GET_FAIL";

export const GET_PARTS_INIT = "PARTS:GET_INIT";
export const GET_PARTS_SUCCESS = "PARTS:GET_SUCCESS";
export const GET_PARTS_FAILURE = "PARTS:GET_FAIL";

export const GET_PART_STRUCTURE_INIT = "PART_STRUCTURE:GET_INIT";
export const GET_PART_STRUCTURE_SUCCESS = "PART_STRUCTURE:GET_SUCCESS";
export const GET_PART_STRUCTURE_FAILURE = "PART_STRUCTURE:GET_FAIL";

export const GET_PART_GET_EXPORT_LIST_INIT = "PART_GET_EXPORT_LIST:GET_INIT";
export const GET_PART_GET_EXPORT_LIST_SUCCESS =
  "PART_GET_EXPORT_LIST:GET_SUCCESS";
export const GET_PART_GET_EXPORT_LIST_FAILURE = "PART_GET_EXPORT_LIST:GET_FAIL";

export const GET_PART_GET_EXPORT_INIT = "PART_GET_EXPORT:GET_INIT";
export const GET_PART_GET_EXPORT_SUCCESS = "PART_GET_EXPORT:GET_SUCCESS";
export const GET_PART_GET_EXPORT_FAILURE = "PART_GET_EXPORT:GET_FAIL";

export const GET_PART_COPY_TO_INIT = "PART_COPY_TO:GET_INIT";
export const GET_PART_COPY_TO_SUCCESS = "PART_COPY_TO:GET_SUCCESS";
export const GET_PART_COPY_TO_FAILURE = "PART_COPY_TO:GET_FAIL";

export const GET_PART_COPY_FROM_INIT = "PART_COPY_FROM:GET_INIT";
export const GET_PART_COPY_FROM_SUCCESS = "PART_COPY_FROM:GET_SUCCESS";
export const GET_PART_COPY_FROM_FAILURE = "PART_COPY_FROM:GET_FAIL";

export const GET_PART_MARK_AS_COMPLETED_INIT =
  "PART_MARK_AS_COMPLETED:GET_INIT";
export const GET_PART_MARK_AS_COMPLETED_SUCCESS =
  "PART_MARK_AS_COMPLETED:GET_SUCCESS";
export const GET_PART_MARK_AS_COMPLETED_FAILURE =
  "PART_MARK_AS_COMPLETED:GET_FAIL";
