import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import NotificationsApi from "../../../models/NotificationsApi";

export function loadNotificationsMarkAsRead(ids) {
  let action = new ActionRequest(
    NotificationsApi.loadNotificationsMarkAsRead,
    loadNotificationsMarkAsReadInit,
    loadNotificationsMarkAsReadSuccess,
    loadNotificationsMarkAsReadFailure
  );
  return action.run({
    routeParameters: { ids },
    actionParameters: { ids },
  });
}

function loadNotificationsMarkAsReadSuccess(
  notificationsMarkAsReadData,
  params
) {
  return {
    type: types.GET_NOTIFICATIONS_MARK_AS_READ_SUCCESS,
    data: notificationsMarkAsReadData,
  };
}

function loadNotificationsMarkAsReadFailure(error) {
  return {
    type: types.GET_NOTIFICATIONS_MARK_AS_READ_FAILURE,
    notification: {
      level: "error",
      label: "[action:notificationsMarkAsRead>loadNotificationsMarkAsRead]",
      message: "Impossible de récupérer la liste des notificationsMarkAsRead",
      system_message: error.message,
    },
  };
}

function loadNotificationsMarkAsReadInit(params) {
  return { type: types.GET_NOTIFICATIONS_MARK_AS_READ_INIT };
}
