// react dependencies
import React, { Component } from "react";

import Button from "../components/button/Button";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LoaderFullScreen from "../components/loader/LoaderFullScreen";
import * as acceptGtcActions from "../modules/authentification/actions/acceptGtcActions";

class GTC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      view: window.location.href.indexOf("/view") > 0,
    };
  }

  UNSAFE_componentWillMount = () => {
    if (!this.state.view) document.body.classList.add("body--with-gtc");
  };

  componentWillUnmount() {
    if (!this.state.view) document.body.classList.remove("body--with-gtc");
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.acceptGtc.isLoading && !nextProps.acceptGtc.isLoading) {
      if (nextProps.acceptGtc.error) {
      } else {
        this.props.history.push("/");
      }
    }
  };

  accept = () => {
    this.setState({
      loader: true,
    });
    this.props.loadAcceptGtc();
  };

  render() {
    return (
      <section
        className={"gtc" + (this.state.view ? " gtc--with-padding" : "")}
      >
        <h1>
          CURATOR TERMS &amp; CONDITIONS OF USE
        </h1>

        <h2>I - PURPOSE</h2>

        <p>In order to provide certain compliance, oversight, risk and reporting services (the “Services”), Carne and certain third parties will share
          information related to the Services (the “Information”), through the online Curator Platform (the “Site”) for the purpose of supporting Carne’s Services.</p>

        <h2>II - RIGHTS AND USE</h2>

        <p>Subject to the terms set out herein or in any separate agreement, Carne grants to each approved user entity (each a “User”) the right
          to access and to use the Site for the purposes of and in relation to Carne's delivery of the Services. Each User agrees that such use
          is for the User’s internal business purposes. Users may not, except as otherwise expressly permitted under a separate agreement, do
          any of the following or authorize any other third party to do any of the following with respect to the Site, including without limitation
          any of its related technology, software, and databases thereon: (a) access or use any portion except for agreed purposes; (b) reproduce
          or copy any portion; (c) modify or create any derivative works; (d) decompile, disassemble, reverse engineer, or otherwise attempt to
          derive the related source code; (e) redistribute, encumber, sell, rent, lease, use for service bureau purposes, sublicense or otherwise
          transfer any related rights; or (f) remove or alter any related trademark, logo, copyright or other proprietary notices, legends, symbols
          or labels. Any rights of use, except as noted in Section IX below, shall expire upon termination of the relevant Services.</p>

        <h2>III - USERS</h2>

        <p>Each User must inform Carne of the names of the User’s staff (“User Staff”) whom the User authorizes to access and use the Site on the
          User’s behalf. Each User may only have access granted to persons who are not its dependent employees upon the individual consent of Carne
          granted in writing. Carne will supply User Staff with User IDs and passwords. Additional restrictions may be placed on the access of
          individuals to specific data. Users will ensure that all User Staff keep their User ID and password confidential and that they do
          not let any other person become aware of or use their User ID or password. Users remain responsible for User Staff access to and use
          of the Site, and for any content and other materials placed on the Site by User Staff. Users must immediately inform Carne of any
          individuals no longer requiring or permitted to have access to the Site. Carne accepts no responsibility for any consequences of
          access to any Information as a result of a User’s breach of these terms or for any action or omission of a User affiliate or its
          personnel that is inconsistent with these terms.</p>

        <h2>IV - THIRD PARTIES</h2>

        <p>Users shall not give access to the Site to any third party or any persons who are not employees of the User without specific consent from Carne,
          Carne having been advised that such persons are not dependent employees of the User. Users shall indemnify Carne for any loss suffered by Carne
          in consequence of a breach of this obligation.</p>

        <h2>V - INTELLECTUAL PROPERTY RIGHTS</h2>

        <p>Carne owns all rights, title, and interest in and to the Site (including the copyright, trade secrets, trademarks and other intellectual property
          interests pertaining to the Site and to any modifications to and enhancements of the Site). Carne represents and warrants that it has the
          right to grant to the User the access rights to the Site.</p>

        <h2>VI - REQUIREMENTS OF USE AN ACCESS</h2>

        <p>Access to the Site is provided via the Internet through the World Wide Web. Users are responsible for obtaining, at their own cost and expense,
          any software or hardware needed by Users to access the Site such as a computer, an Internet connection and compatible Web browser software.</p>

        <h2>VII - STORAGE OF DATA</h2>

        <p>Access requires each User individual to register for and obtain a User ID and by logging into the Site, the User / User Staff hereby accept
          the Terms of Use. Users are responsible for fulfilling their Data Protection obligations in relation to the processing of User Staff personal data rendered in connection with registration.
          Furthermore, Users hereby attest that they have fulfilled their Data Protection obligations in relation to the processing of any data in the Site which in the sole opinion of Carne is necessary or desirable to be stored for the purpose of rendering the Services. 
          It is the responsibility of the User only to ensure that it fully complies with any applicable laws, regulations, and circulars requirements for the purpose of its use of the Site.</p>

        <p>Under the European Union General Data Protection Regulation, Carne is required to tell individuals whose personal data we process more about how Carne
          uses their personal data and their rights. To the extent Carne collects or uses any personal data provided by a User through the Site, it will use that personal
          data in accordance with its <a href="https://www.carnegroup.com/privacy-notice/">Privacy Notice</a> (please click to access).</p>

        <h2>VIII - INFORMATION</h2>

        <p>Carne reserves the right to review information and content submitted for posting on the Site by third parties. However, Carne shall
          not be required to review or approve such information and content and assumes no responsibility or liability for any information or
          content provided by third parties. Users shall not use the Site to post or transmit any information or content in violation of any
          applicable law or regulation or in any manner that violates, infringes or misappropriates any third party right or interest, or in
          any manner that is tortious. Among other prohibited activities and without limiting the foregoing, Users may not make available or
          promote in or through the Site any material which: (a) infringes any person's intellectual property rights; (b) is or is likely to
          be perceived as defamatory, threatening, misleading, offensive or inappropriate, or materially misrepresents facts; (c) adversely
          affects performance or availability of the Site, or places an undue load on Carne network resources; (d) contains any virus, harmful
          component or corrupted data; or (e) contains any advertising, promotion or solicitation. In the event that Carne believes or
          suspects that information or content posted or placed on the Site violates an applicable law or regulation, or violates,
          infringes, misappropriates any third party right or interest, or otherwise unlawfully harms or damages an individual or entity,
          Carne may suspend service on part or all of the Site. Carne further reserves the right to deny access to the Site in the event
          Carne believes that Users and/or the User Staff may have violated the provisions of these Terms and Conditions or any separate agreement.
          In addition, Carne may, without notice, suspend all services hereunder, destroy any information or data entered on the Site, and/or
          block access to the Site, to the extent that Carne is required by law or order of any court or regulatory agency to do so.</p>

        <p>THE SITE IS PROVIDED "AS IS," AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, CARNE AND ITS LICENSORS, BUSINESS ASSOCIATES AND
          SUPPLIERS DISCLAIM ALL WARRANTIES WITH RESPECT TO THE SAME, EXPRESS, IMPLIED AND STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, COMPLETENESS, NON-INFRINGEMENT, NON-INTERFERENCE, ERROR-FREE
          SERVICE AND UNINTERRUPTED SERVICE. SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF CERTAIN WARRANTIES, SO SOME OR ALL OF THE FOREGOING
          DISCLAIMERS MAY NOT APPLY TO USERS.</p>

        <h2>IX - AVAILABILITY</h2>

        <p>While Carne’s objective is to make the Site reasonably accessible during business hours, Carne may make the Site unavailable from time
          to time for any of the following reasons: (a) routine maintenance; (b) services changes; (c) failure of third party services providers;
          (d) failure of Internet infrastructure beyond Carne’s reasonable control; and (e) disruptions caused by misuse of the Site or intentional
          acts of third parties. While Carne will make commercially reasonable efforts to follow its established security procedures, due to the
          public nature of the internet, Carne makes no guarantee that any User will be able to access the Site at any given time or that access
          will be continuous, uninterrupted, error free or secure. Users acknowledge that certain reliability, availability, performance and
          other aspects of the Internet are beyond Carne’s reasonable control. Carne assumes no responsibility for any consequences of the
          Site not being available for access.</p>

        <h2>X - VIRUSES</h2>

        <p>Carne will use standard, commercially-available virus-checking software in relation to any document or files accessible using the Site,
          however, Carne does not represent or warrant that the Site or any documents or files downloaded from the Site will be error-free,
          free from viruses or other harmful components. Reasonably appropriate technical and organizational security measures are in place in
          respect of the information and data held in the Site.</p>

        <p>Users warrant that any data, content or other materials which Users and/or the User Staff supply, post or otherwise make available
          via the Site do not contain any viruses or other computer programming routines which may damage or interfere with the operations of the Site.</p>

        <h2>XI - TERMINATION</h2>

        <p>Following termination of the relevant Services, subject to any other agreement, Carne will have no duty to continue to make the
          Site available to Users and Users acknowledge that Carne will have no duty to perform any termination/expiration assistance,
          including transfer or migration of Information. However, at the request and the expense of Users, Information on the Site may
          be copied to another medium and given to Users, where agreed. Carne shall remain entitled to store data electronically for as
          long as it is required or permitted to do so by law.</p>

        <h2>XII - RELIANCE ON INFORMATION</h2>

        <p>Users are solely responsible for the reliability, accuracy and completeness of the information provided by them and neither Carne
          nor any of its associated companies accept any liability for such. The Site aims at allowing the exchange of Information only.
          Users acknowledge that all Information and data, which is necessarily dematerialised, cannot be considered as original and shall
          ensure that all its Information is stored/archived properly. Carne does not guarantee the integrity, reliability or the accuracy of
          the Information and cannot be held liable for same.</p>

        <p>Users shall not act or rely on any data contained in, or by implication omitted from, the Site and are advised to seek
          professional advice before taking or refraining from any action based upon such data. Accordingly, Information is provided
          with the understanding that the provision of access to and use of the Site does not constitute the rendering of legal,
          accounting, tax, or other professional advice and services and no professional relationship of any nature is thereby created.
          The service should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.</p>

        <h2>XIII - USER ACCESS TO INFORMATION</h2>

        <p>Users will only have access to Information as authorized by Carne. It is the sole responsibility of Users to determine
          each User Staff level of access to data contained on the website, and to communicate those authorizations and any
          modifications thereto or termination thereof to Carne in a timely fashion. Each User has responsibility to ensure
          that access permissions are safeguarded, kept confidential and not used in an unauthorized manner. Further if any User
          has reason to believe he/she has access to data to which he/she normally should not have access, the User shall undertake to inform Carne.</p>

        {this.state.view ? null : (
          <div className="gtc-actions">
            <Button label="Accept" onClick={this.accept} />
          </div>
        )}

        <LoaderFullScreen show={this.state.loader} />
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    acceptGtc: state.acceptGtcReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, acceptGtcActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GTC);
