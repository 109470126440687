export const SET_CURRENT_PROMOTOR_INIT = "CURRENT_PROMOTOR:GET_INIT";
export const SET_CURRENT_PROMOTOR_SUCCESS = "CURRENT_PROMOTOR:GET_SUCCESS";
export const SET_CURRENT_PROMOTOR_FAILURE = "CURRENT_PROMOTOR:GET_FAIL";

export const GET_PROMOTORS_INIT = "PROMOTORS:GET_INIT";
export const GET_PROMOTORS_SUCCESS = "PROMOTORS:GET_SUCCESS";
export const GET_PROMOTORS_FAILURE = "PROMOTORS:GET_FAIL";

export const ADD_PROMOTOR_INIT = "ADD_PROMOTOR:GET_INIT";
export const ADD_PROMOTOR_SUCCESS = "ADD_PROMOTOR:GET_SUCCESS";
export const ADD_PROMOTOR_FAILURE = "ADD_PROMOTOR:GET_FAIL";

export const GET_SETTINGS_PROMOTOR_INIT = "GET_SETTINGS_PROMOTOR:GET_INIT";
export const GET_SETTINGS_PROMOTOR_SUCCESS =
  "GET_SETTINGS_PROMOTOR:GET_SUCCESS";
export const GET_SETTINGS_PROMOTOR_FAILURE = "GET_SETTINGS_PROMOTOR:GET_FAIL";

export const GET_INFO_PROMOTOR_INIT = "GET_INFOS_PROMOTOR:GET_INIT";
export const GET_INFO_PROMOTOR_SUCCESS = "GET_INFOS_PROMOTOR:GET_SUCCESS";
export const GET_INFO_PROMOTOR_FAILURE = "GET_INFOS_PROMOTOR:GET_FAIL";

export const GET_STAFF_PROMOTOR_INIT = "GET_STAFF_PROMOTOR:GET_INIT";
export const GET_STAFF_PROMOTOR_SUCCESS = "GET_STAFF_PROMOTOR:GET_SUCCESS";
export const GET_STAFF_PROMOTOR_FAILURE = "GET_STAFF_PROMOTOR:GET_FAIL";

export const EDIT_PROMOTOR_INIT = "EDIT_PROMOTOR:GET_INIT";
export const EDIT_PROMOTOR_SUCCESS = "EDIT_PROMOTOR:GET_SUCCESS";
export const EDIT_PROMOTOR_FAILURE = "EDIT_PROMOTOR:GET_FAIL";

export const GET_CURRENT_PROMOTOR_INIT = "GET_CURRENT_PROMOTOR:GET_INIT";
export const GET_CURRENT_PROMOTOR_SUCCESS = "GET_CURRENT_PROMOTOR:GET_SUCCESS";
export const GET_CURRENT_PROMOTOR_FAILURE = "GET_CURRENT_PROMOTOR:GET_FAIL";

export const GET_COUNTRIES_FDW_PROMOTOR_INIT =
  "GET_COUNTRIES_FDW_PROMOTOR:GET_INIT";
export const GET_COUNTRIES_FDW_PROMOTOR_SUCCESS =
  "GET_COUNTRIES_FDW_PROMOTOR:GET_SUCCESS";
export const GET_COUNTRIES_FDW_PROMOTOR_FAILURE =
  "GET_COUNTRIES_FDW_PROMOTOR:GET_FAIL";

export const SET_COUNTRIES_FDW_PROMOTOR_INIT =
  "COUNTRIES_FDW_PROMOTOR:GET_INIT";
export const SET_COUNTRIES_FDW_PROMOTOR_SUCCESS =
  "COUNTRIES_FDW_PROMOTOR:GET_SUCCESS";
export const SET_COUNTRIES_FDW_PROMOTOR_FAILURE =
  "COUNTRIES_FDW_PROMOTOR:GET_FAIL";
