import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import FundsApi from "../../../models/FundsApi";

export function loadSubfundsByCountry(filters) {
  let action = new ActionRequest(
    FundsApi.loadSubfundsByCountry,
    loadSubfundsByCountryInit,
    loadSubfundsByCountrySuccess,
    loadSubfundsByCountryFailure
  );
  return action.run({
    routeParameters: { filters },
    actionParameters: { filters },
  });
}

function loadSubfundsByCountrySuccess(fundsByCountryData, params) {
  return {
    type: types.GET_SUBFUNDS_BY_COUNTRY_SUCCESS,
    data: fundsByCountryData,
  };
}

function loadSubfundsByCountryFailure(error) {
  return {
    type: types.GET_SUBFUNDS_BY_COUNTRY_FAILURE,
    notification: {
      level: "error",
      label: "[action:fundsByCountry>loadSubfundsByCountry]",
      message: "Impossible de récupérer la liste des fundsByCountry",
      system_message: error.message,
    },
  };
}

function loadSubfundsByCountryInit(params) {
  return { type: types.GET_SUBFUNDS_BY_COUNTRY_INIT };
}
