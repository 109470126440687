export const GET_PUBLICATIONS_UPDATE_INIT = "PUBLICATIONS_UPDATE:GET_INIT";
export const GET_PUBLICATIONS_UPDATE_SUCCESS =
  "PUBLICATIONS_UPDATE:GET_SUCCESS";
export const GET_PUBLICATIONS_UPDATE_FAILURE = "PUBLICATIONS_UPDATE:GET_FAIL";

export const GET_PUBLICATIONS_UPDATE_COVER_INIT =
  "PUBLICATIONS_UPDATE_COVER:GET_INIT";
export const GET_PUBLICATIONS_UPDATE_COVER_SUCCESS =
  "PUBLICATIONS_UPDATE_COVER:GET_SUCCESS";
export const GET_PUBLICATIONS_UPDATE_COVER_FAILURE =
  "PUBLICATIONS_UPDATE_COVER:GET_FAIL";

export const GET_PUBLICATIONS_UPDATE_FILE_INIT =
  "PUBLICATIONS_UPDATE_FILE:GET_INIT";
export const GET_PUBLICATIONS_UPDATE_FILE_SUCCESS =
  "PUBLICATIONS_UPDATE_FILE:GET_SUCCESS";
export const GET_PUBLICATIONS_UPDATE_FILE_FAILURE =
  "PUBLICATIONS_UPDATE_FILE:GET_FAIL";

export const GET_PUBLICATIONS_GET_INIT = "PUBLICATIONS_GET:GET_INIT";
export const GET_PUBLICATIONS_GET_SUCCESS = "PUBLICATIONS_GET:GET_SUCCESS";
export const GET_PUBLICATIONS_GET_FAILURE = "PUBLICATIONS_GET:GET_FAIL";

export const GET_PUBLICATIONS_DOWNLOAD_INIT = "PUBLICATIONS_DOWNLOAD:GET_INIT";
export const GET_PUBLICATIONS_DOWNLOAD_SUCCESS =
  "PUBLICATIONS_DOWNLOAD:GET_SUCCESS";
export const GET_PUBLICATIONS_DOWNLOAD_FAILURE =
  "PUBLICATIONS_DOWNLOAD:GET_FAIL";

export const GET_PUBLICATIONS_DELETE_COVER_INIT =
  "PUBLICATIONS_DELETE_COVER:GET_INIT";
export const GET_PUBLICATIONS_DELETE_COVER_SUCCESS =
  "PUBLICATIONS_DELETE_COVER:GET_SUCCESS";
export const GET_PUBLICATIONS_DELETE_COVER_FAILURE =
  "PUBLICATIONS_DELETE_COVER:GET_FAIL";

export const GET_PUBLICATIONS_DELETE_FILE_INIT =
  "PUBLICATIONS_DELETE_FILE:GET_INIT";
export const GET_PUBLICATIONS_DELETE_FILE_SUCCESS =
  "PUBLICATIONS_DELETE_FILE:GET_SUCCESS";
export const GET_PUBLICATIONS_DELETE_FILE_FAILURE =
  "PUBLICATIONS_DELETE_FILE:GET_FAIL";
