// react dependencies
import React, { Component } from 'react';

import NavItem from '../components/nav/NavItem';

import { __ } from '../utils';
import Toaster from 'js/utils/Toaster';

import UploadCenter from '../components/nav/UploadCenter';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as libraryUploadFileActions from '../modules/libraryV2/actions/libraryUploadFileActions';
import * as libraryGetUnclassifiedActions from '../modules/libraryV2/actions/libraryGetUnclassifiedActions';
import * as libraryGetMissingMetadataActions from '../modules/libraryV2/actions/libraryGetMissingMetadataActions';
import * as libraryGetExceptionsActions from '../modules/libraryV2/actions/libraryGetExceptionsActions';
import * as libraryGetCountersActions from '../modules/libraryV2/actions/libraryGetCountersActions';

import PropTypes from 'prop-types';

import { ReactComponent as BillingIcon } from 'img/PwC_Funct_Icons_Cost-Dollar_Outline_Black_RGB.svg';
import CarneLogo from '../../img/Curator_Symbol.svg';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filesToUpload: [],
      uploadPending: false,
      uploadPendingId: -1,
      navItemsMore: [],
      navItems: this.fillNav(),
      navMore: false,
    };
    this.counterFileId = 0;
    this.navItemsFull = this.fillNav();
  }

  compressNav = () => {
    let itemHeight = 50;
    let viewPort =
      Math.max(document.documentElement.clientHeight, window.innerHeight) || 0;
    let totalHeight = itemHeight * this.navItemsFull.length;
    totalHeight = totalHeight + 150; // legal mentions + header + library upload (!!!)
    if (totalHeight > viewPort) {
      const nbItemsToRemove = Math.ceil((totalHeight - viewPort) / 50);
      let navItems = [];
      let navItemsMore = [];
      for (let i = 0; i < this.navItemsFull.length; i++) {
        if (i < this.navItemsFull.length - nbItemsToRemove) {
          navItems.push(this.navItemsFull[i]);
        } else {
          navItemsMore.push(this.navItemsFull[i]);
        }
      }
      this.setState({
        navItemsMore: navItemsMore,
        navItems: navItems,
      });
    } else {
      this.navItems = this.navItemsFull;
      this.setState({
        navItems: this.fillNav(),
        navItemsMore: [],
      });
    }
  };

  renderFilesProcessedCounter = (status) => {
    let counter = 0;
    this.state.filesToUpload.forEach((file) => {
      if (status > 0) {
        if (file.status.id == status) counter++;
      } else {
        if (file.status.id >= 0) counter++;
      }
    });
    return counter;
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      JSON.stringify(this.props.filesToUpload) !=
      JSON.stringify(nextProps.filesToUpload)
    ) {
      let filesToUpload = this.state.filesToUpload;
      let newFiles = [];
      nextProps.filesToUpload.forEach((file) => {
        this.counterFileId++;
        file.fileId = this.counterFileId;
        file.status = { id: -1 };
        if (file.size > 0) newFiles.push(file);
      });
      if (!newFiles.length) {
        Toaster.warning('Your file(s) cannot be read');
      } else {
        Toaster.success('Follow up is in the nav on the left');
      }

      filesToUpload = filesToUpload.concat(newFiles);
      this.setState({
        filesToUpload: filesToUpload,
      });
    }

    if (
      this.props.libraryUploadFile.isLoading &&
      !nextProps.libraryUploadFile.isLoading
    ) {
      let filesToUpload = this.state.filesToUpload;
      filesToUpload.forEach((file) => {
        if (file.fileId == this.state.uploadPendingId) {
          file.status = {
            id: nextProps.libraryUploadFile.items.documentStatusId,
          };
        }
      });
      if (nextProps.libraryUploadFile.items.documentStatusId == 1) {
      }
      if (nextProps.libraryUploadFile.items.documentStatusId == 2)
        this.props.loadLibraryGetUnclassified();
      if (nextProps.libraryUploadFile.items.documentStatusId == 3)
        this.props.loadLibraryGetMissingMetadata();
      if (nextProps.libraryUploadFile.items.documentStatusId == 4)
        this.props.loadLibraryGetExceptions();
      this.props.loadLibraryGetCounters();
      this.setState({
        filesToUpload: filesToUpload,
        uploadPending: false,
        uploadPendingId: -1,
      });
    }
  };

  getNextFileToUpload = () => {
    let file = null;
    for (let i = 0; i < this.state.filesToUpload.length; i++) {
      if (this.state.filesToUpload[i].status.id == -1) {
        file = this.state.filesToUpload[i];
        break;
      }
    }
    return file;
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.compressNav);
    document.body.addEventListener('click', this.disableMoreMenu);
    this.compressNav();
  };

  componentWillUnmount = () => {
    document.body.removeEventListener('click', this.disableMoreMenu);
    window.removeEventListener('resize', this.compressNav);
  };

  componentDidUpdate = (prevProps, prevState) => {
    /*
    [].slice.call(document.querySelectorAll('.nav--surface')).forEach(
      function(ele) {
        mdc.ripple.MDCRipple.attachTo(ele);
      });
      */
    if (prevProps.adminMode != this.props.adminMode) {
      this.navItemsFull = this.fillNav();
      this.compressNav();
    }
    if (!this.state.uploadPending) {
      let file = this.getNextFileToUpload();
      if (file) {
        this.setState(
          {
            uploadPending: true,
            uploadPendingId: file.fileId,
          },
          () => {
            this.props.loadLibraryUploadFile(
              [file],
              file.fundId,
              file.documentType
            );
          }
        );
      } else {
        if (prevState.uploadPending) {
          let ok = this.renderFilesProcessedCounter(1);
          let unclassified = this.renderFilesProcessedCounter(2);
          let missingMetadata = this.renderFilesProcessedCounter(3);
          let exceptions = this.renderFilesProcessedCounter(4);
          let message = (
            <div>
              {ok > 0 ? (
                <div>
                  {ok} document{ok > 1 ? 's' : ''} well uploaded
                </div>
              ) : null}
              {unclassified > 0 ? (
                <div>
                  {unclassified} document{unclassified > 1 ? 's' : ''}{' '}
                  unclassified
                </div>
              ) : null}
              {missingMetadata > 0 ? (
                <div>
                  {missingMetadata} missing metadata document
                  {missingMetadata > 1 ? 's' : ''}
                </div>
              ) : null}
              {exceptions > 0 ? (
                <div>
                  {exceptions} document{exceptions > 1 ? 's' : ''} in exception
                </div>
              ) : null}
            </div>
          );

          Toaster.success(message);

          this.setState({
            filesToUpload: [],
            uploadPending: false,
            uploadPendingId: -1,
          });
        }
      }
    }
  };

  handleClickItem = (e) => {
    e.stopPropagation();
    this.props.onNavigate?.();
  };

  timeout = null;

  handleClickUploadCenter = (open) => {
    this.props.history.push('/library/v2/files');
  };

  fillNav = () => {
    let match = this.props.match;
    let navItems = [];

    if (
      this.props.adminMode &&
      this.props.user.frontAccessList['SUPPORT_WRITE']
    ) {
      navItems.push(
        <NavItem
          key="navitem1"
          onClick={this.handleClickItem}
          to={match.url + 'admin/rights-management'}
          icon="pwc-users-outline-business-setting"
          label={__('__RIGHTS_MANAGEMENT_')}
        />
      );
    }

    if (
      this.props.adminMode &&
      this.props.user.frontAccessList['ADMINISTRATOR_WRITE']
    ) {
      navItems.push(
        <NavItem
          key="navitem2"
          onClick={this.handleClickItem}
          to={match.url + 'admin/entities'}
          icon="pwc-locations-outline-globe-share"
          label={__('__ENTITIES_')}
        />
      );

      navItems.push(
        <NavItem
          key="navitem3"
          onClick={this.handleClickItem}
          to={match.url + 'admin/dissemination'}
          icon="pwc-email-outline-outbox"
          label={__('__DISSEMINATION_')}
        />
      );

      navItems.push(
        <NavItem
          key="navitem4"
          onClick={this.handleClickItem}
          to={match.url + 'admin/publications'}
          icon="pwc-content-outline-news-article"
          label={__('__PUBLICATIONS_')}
        />
      );

      navItems.push(
        <NavItem
          key="navitem5"
          onClick={this.handleClickItem}
          to={match.url + 'admin/fund-distribution-watch-tags'}
          icon="pwc-locations-outline-globe-search"
          label={__('__FUND_DISTRIBUTION_WATCH_')}
        />
      );

      navItems.push(
        <NavItem
          key="navitem6"
          onClick={this.handleClickItem}
          to={match.url + 'admin/logs'}
          icon="pwc-i-f-outline-infomation-circle"
          label={__('__LOGS_')}
        />
      );
    } else {
      if (this.props.user.frontAccessList['DASHBOARD_READ'])
        navItems.push(
          <NavItem
            key="navitem1"
            onClick={this.handleClickItem}
            to={match.url + 'dashboard'}
            icon="pwc-business-outline-graph-pie-2"
            label={__('__DASHBOARD_')}
          />
        );

      if (
        this.props.user.frontAccessList['DISTRIBUTION_MAP_READ'] &&
        this.props.user.hasRegistrationMatrix
      )
        navItems.push(
          <NavItem
            key="navitem2"
            onClick={this.handleClickItem}
            to={match.url + 'distribution-map'}
            icon="pwc-places-outline-globe-2"
            label={__('__DISTRIBUTION_MAP_')}
          />
        );

      if (this.props.user.frontAccessList['FDW_READ'])
        navItems.push(
          <NavItem
            key="navitem3"
            onClick={this.handleClickItem}
            to={match.url + 'fund-distribution-watch'}
            icon="pwc-locations-outline-globe-search"
            label={__('__FUND_DISTRIBUTION_WATCH_')}
          />
        );

      if (this.props.user.frontAccessList['DISTRI_MATRIX_READ'])
        navItems.push(
          <NavItem
            key="navitem4"
            onClick={this.handleClickItem}
            to={match.url + 'matrix'}
            icon="pwc-content-outline-view-module-1"
            label={'Matrices'}
          />
        );

      if (
        this.props.user.frontAccessList['TASK_LIST_READ'] &&
        this.props.user.hasRegistrationMatrix
      ) {
        navItems.push(
          <NavItem
            key="navitem6"
            onClick={this.handleClickItem}
            to={match.url + 'tasks'}
            icon="pwc-tasks-outline-file-checklist"
            label={__('__TASKS_')}
          />
        );
        navItems.push(
          <NavItem
            key="navitemCalendar"
            onClick={this.handleClickItem}
            to={match.url + 'calendar'}
            icon="pwc-time-outline-calendar-2"
            label={__('__CALENDAR_')}
          />
        );
      }

      if (
        this.props.user.frontAccessList['LIBRARY_READ'] &&
        this.props.user.hasRegistrationMatrix
      )
        navItems.push(
          <NavItem
            key="navitem7"
            onClick={this.handleClickItem}
            to={match.url + 'library'}
            icon="pwc-folders-outline-folder-files"
            label={__('__LIBRARY_')}
          />
        );

      if (
        this.props.user.frontAccessList['REPORTING_READ'] &&
        this.props.user.hasRegistrationMatrix
      )
        navItems.push(
          <NavItem
            key="navitem8"
            onClick={this.handleClickItem}
            to={match.url + 'reporting'}
            icon="pwc-office-files-outline-file-text-chart"
            label={__('__REPORTING_')}
          />
        );

      if (
        this.props.user.frontAccessList['MARKETING_ARRANGEMENT_READ'] &&
        this.props.user.hasRegistrationMatrix
      )
        navItems.push(
          <NavItem
            key="navitem9"
            onClick={this.handleClickItem}
            to={match.url + 'marketing-arrangement'}
            icon="pwc-basic-outline-file-edit-2"
            label={__('__MARKETING_ARRANGEMENT_')}
          />
        );

      if (this.props.user.frontAccessList['ENTITIES_READ'])
        navItems.push(
          <NavItem
            key="navitem10"
            onClick={this.handleClickItem}
            to={match.url + 'entities'}
            icon="pwc-locations-outline-globe-share"
            label={__('__ENTITIES_')}
          />
        );

      if (
        this.props.user.frontAccessList['DISSEMINATION_WRITE'] ||
        this.props.user.frontAccessList['DISSEMINATION_READ']
      )
        navItems.push(
          <NavItem
            key="navitem11"
            onClick={this.handleClickItem}
            to={match.url + 'dissemination'}
            icon="pwc-email-outline-outbox"
            label={__('__DISSEMINATION_')}
          />
        );

      if (
        this.props.user.frontAccessList['BILLING_PACKAGE_READ'] ||
        this.props.user.frontAccessList['BILLING_INVOICE_READ'] ||
        this.props.user.frontAccessList['BILLING_NETWORK_READ']
      )
        navItems.push(
          <NavItem
            key="navitem11-1"
            onClick={this.handleClickItem}
            to={match.url + 'billing'}
            icon={<BillingIcon className="nav-icon" />}
            label={'Billing'}
          />
        );

      if (this.props.user.frontAccessList['SETTING_WRITE'])
        navItems.push(
          <NavItem
            key="navitem12"
            onClick={this.handleClickItem}
            to={match.url + 'settings'}
            icon="pwc-settings-outline-cog-double-2"
            label={__('__SETTINGS_')}
          />
        );
    }

    return navItems;
  };

  activeMoreMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.state.navMore) {
      this.setState({
        navMore: true,
      });
    }
  };

  disableMoreMenu = () => {
    if (this.state.navMore) {
      this.setState({
        navMore: false,
      });
    }
  };

  renderNavItemsMore = () => {
    if (this.state.navItemsMore.length) {
      return (
        <li
          ref="item"
          className={
            'main-nav-item nav--more' +
            (this.state.navMore ? ' nav--more-visible' : '')
          }
        >
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a onClick={this.activeMoreMenu}>
            <i className={'pwc-navigation-outline-navigation-show-more-2'}></i>
            <div className="main-nav-item-label">More</div>
          </a>
          <ul className="nav-more">
            {this.state.navItemsMore.map((item) => {
              return <NavItem {...item.props} key={item.props.label} />;
            })}
          </ul>
        </li>
      );
    }
  };

  render() {
    let match = this.props.match;
    return (
      <aside className="main-nav">
        <div>
          <img className="logo-carne" src={CarneLogo} alt="Curator Logo" />
          <nav>
            <ul>
              {this.state.navItems}
              {this.renderNavItemsMore()}
            </ul>

            {this.state.filesToUpload.length ? (
              <UploadCenter
                files={this.state.filesToUpload}
                onClick={this.handleClickUploadCenter}
              />
            ) : null}
          </nav>
          <ul className="legal-mentions">
            <NavItem
              onClick={this.handleClickItem}
              to={match.url + 'gtc/view'}
              icon="pwc-i-f-outline-infomation-circle"
              label={__('__LEGAL_MENTIONS_')}
            />
          </ul>
        </div>
      </aside>
    );
  }
}

Nav.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    libraryUploadFile: state.libraryUploadFileReducer,
    libraryGetUnclassified: state.libraryGetUnclassifiedReducer,
    libraryGetMissingMetadata: state.libraryGetMissingMetadataReducer,
    libraryGetExceptions: state.libraryGetExceptionsReducer,
    libraryGetCounters: state.libraryGetCountersReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      libraryUploadFileActions,
      libraryGetMissingMetadataActions,
      libraryGetExceptionsActions,
      libraryGetCountersActions,
      libraryGetUnclassifiedActions
    ),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
