import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "scss/Toaster.scss";

export default class Toaster {
  static warning(message, title) {
    _toast(title, message, toast.TYPE.WARNING);
  }

  static error(message, title) {
    _toast(title, message, toast.TYPE.ERROR);
  }

  static success(message, title) {
    _toast(title, message, toast.TYPE.SUCCESS);
  }
}

function _toast(title, message, type) {
  const content = () => (
    <div>
      {title && <h3 className="toast-title">{title}</h3>}
      <p className="toast-message">{message}</p>
    </div>
  );

  toast(content, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    type,
  });
}
