import { setAuthentificationNeedsAuth } from "js/modules/authentification/actions/authentificationActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router";

const ProtectedRoute = (props) => {
  const dispatch = useDispatch();
  const needsAuth = useSelector(
    (state) => state.authentificationReducer.needsAuth
  );
  useEffect(() => {
    if (!needsAuth) {
      dispatch(setAuthentificationNeedsAuth(true));
    }
  }, [dispatch, needsAuth]);

  return <Route {...props} />;
};

export default ProtectedRoute;
