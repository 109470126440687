// react dependencies
import React, { Component } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/modal";

class Logout extends Component {
  interval;
  constructor(props) {
    super(props);
    this._TIMEOUT_ = 30 * 1000 * 60; // 30 minutes before auto logout
    this._POPUP_DELAY_ = 120; // 120s left: warning popup appears
    this.state = {
      timer: this._TIMEOUT_,
      show: false,
    };
  }

  componentDidMount = () => {
    this.interval = setInterval(() => {
      if (this.state.timer <= 0) {
        this.setState({
          show: false,
        });
        this.props.logout();
      }
      if (this.state.timer <= this._POPUP_DELAY_ && !this.state.show) {
        this.setState({
          show: true,
        });
      } else if (this.state.show && this.state.timer > this._POPUP_DELAY_) {
        this.setState({
          show: false,
        });
      }
      this.setState({
        timer: this.state.timer - 1 >= 0 ? this.state.timer - 1 : 0,
      });
    }, 1000);

    window.addEventListener("mousemove", this.catchActivity);
    window.addEventListener("click", this.catchActivity);
    window.addEventListener("touchstart", this.catchActivity);
    window.addEventListener("keypress", this.catchActivity);
  };

  componentWillUnmount = () => {
    if (this.interval) clearInterval(this.interval);
    window.removeEventListener("mousemove", this.catchActivity);
    window.removeEventListener("click", this.catchActivity);
    window.removeEventListener("touchstart", this.catchActivity);
    window.removeEventListener("keypress", this.catchActivity);
  };

  catchActivity = () => {
    this.setState({
      timer: this._TIMEOUT_,
    });
  };

  close = () => {};

  render() {
    return (
      <Modal closeButton onClose={this.close} show={this.state.show}>
        <ModalHeader>Session will expire</ModalHeader>
        <ModalBody>
          <div className="logout-timer">
            <div>
              You will be disconnected of GFD Platform in case of you still
              remain inactive in:
            </div>
            <div className="logout-timer-counter">{this.state.timer}s</div>
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer-logout-timer">
          <button
            style={{ border: 0, height: 0, width: 0, background: "#fff" }}
          ></button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default Logout;
