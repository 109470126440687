// react dependencies
import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/modal";

import { __ } from "../../utils";
import Toaster from "js/utils/Toaster";

import Button from "../../components/button/Button";

import FormRow from "../../components/form/FormRow";

import InputText from "../../components/form/InputText";

import FiltersSelect from "../../components/filters/FiltersSelect";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as promotorsAddActions from "../../modules/promotors/actions/promotorsAddActions";

import LoaderModal from "../../components/loader/LoaderModal";

class ModalAddPromotor extends Component {
  UNSAFE_componentWillMount = () => {
    this.reset();
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (!this.props.show && nextProps.show) {
      this.reset();
    }
    if (
      this.props.promotorsAdd.isLoading &&
      !nextProps.promotorsAdd.isLoading
    ) {
      this.setState({
        modalLoader: false,
      });
      if (nextProps.promotorsAdd.error) {
        if (nextProps.promotorsAdd.error == "PROMOTOR_ALREADY_EXIST") {
          Toaster.error("Client name already exists in the platform");
        } else {
          Toaster.error(__("__ERROR_NOTIFICATION_"));
        }
      } else {
        let id = nextProps.promotorsAdd.items.promotorFreshlyCreated;
        Toaster.success("Client has been successfully created");
        this.props.switchToClient(id, this.state.name);
      }
    }
  };

  reset = () => {
    this.setState({
      nameValid: false,
      nameError: false,
      name: "",
      domicileValid: false,
      domicileError: false,
      domicile: null,
      domicileLabel: "",
      modalLoader: false,
    });
  };

  validateName = (val) => {
    let name;
    if (typeof val == "object") {
      name = val.target.value;
    } else {
      name = val;
    }
    if (name)
      this.setState({
        name: name,
        nameValid: true,
      });
    else
      this.setState({
        name: "",
        nameValid: false,
      });
  };

  validateDomicile = (obj) => {
    this.setState({
      domicileLabel: obj.name,
      domicile: obj,
      domicileValid: true,
    });
  };

  confirm = () => {
    let ok = true;
    if (!this.state.nameValid) {
      ok = false;
      this.setState({
        nameError: true,
      });
    }
    if (!this.state.domicileValid) {
      ok = false;
      this.setState({
        domicileError: true,
      });
    }
    if (ok) {
      this.setState({
        modalLoader: true,
      });
      this.props.loadPromotorsAdd(this.state.name, this.state.domicile.id);
    } else {
      Toaster.warning(__("__FORM_ERROR_DESC_"));
    }
  };

  render() {
    return (
      <Modal closeButton onClose={this.props.close} show={this.props.show}>
        <ModalHeader>{__("__ADD_A_NEW_PROMOTOR_")}</ModalHeader>
        <ModalBody>
          <div className="modal-add-promotor">
            <FormRow
              mandatory
              error={this.state.nameError}
              isValid={this.state.nameValid}
              hasHelpText={true}
              helpText="Please enter your promoter's name"
              validate={this.validateName}
              label={__("__PROMOTOR_NAME_")}
            >
              <InputText />
            </FormRow>
            <FormRow
              mandatory
              hasHelpText={true}
              isValid={this.state.domicileValid}
              helpText="Please enter your promoter's domiciliation"
              error={this.state.domicileError}
              label={__("__PROMOTOR_DOMICILE_")}
              validate={this.validateDomicile}
            >
              <FiltersSelect
                loading={false}
                name={this.state.domicileLabel}
                options={this.props.countries.items}
                handleChange={this.validateDomicile}
              ></FiltersSelect>
            </FormRow>
            <div className="warning">
              {__("__PROMOTOR_CLIENT_ID_SETUP__")}
              <a href="mailto:cds-product-gfd@carnegroup.com?subject=Configure client ID">cds-product-gfd@carnegroup.com</a>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-ghost"
            onClick={this.props.close}
            label={__("__CANCEL_")}
          />
          <Button
            onClick={this.confirm}
            label={true ? "Confirm" : __("__PROMOTER_SETUP_")}
          />
        </ModalFooter>
        <LoaderModal show={this.state.modalLoader} />
      </Modal>
    );
  }
}

ModalAddPromotor.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    promotorsAdd: state.promotorsAddReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, promotorsAddActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddPromotor);
