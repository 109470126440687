// react dependencies

import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Switch, Redirect } from "react-router-dom";
import Route from "../components/routes/protectedRoute";

import BackToTop from "../components/backToTop/BackToTop";

import NotFound from "./404";

import Alert from "../components/alert/Alert";
import LoaderFullScreen from "../components/loader/LoaderFullScreen";

import Logout from "../components/logout/Logout";

import "dom4";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";

import Header from "./header";
import Nav from "./nav";
import ErrorBoundary from "../components/errorBoundary/ErrorBoundary";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as distributionMatrixActions from "../modules/distributionMatrix/actions/distributionMatrixActions";
import * as distributionMatrixAllFiltersActions from "../modules/distributionMatrix/actions/distributionMatrixAllFiltersActions";
import * as authentificationActions from "../modules/authentification/actions/authentificationActions";
import * as subfundsByCountryActions from "../modules/funds/actions/subfundsByCountryActions";
import * as promotorsGetCurrentActions from "../modules/promotors/actions/promotorsGetCurrentActions";
import * as countriesActions from "../modules/countries/actions/countriesActions";
import * as countriesByRegionActions from "../modules/countries/actions/countriesByRegionActions";
import * as tasksByStatusActions from "../modules/tasks/actions/tasksByStatusActions";
import * as tasksByTypeActions from "../modules/tasks/actions/tasksByTypeActions";
import * as logOffActions from "../modules/authentification/actions/logOffActions";
import * as fundDetailsStructureActions from "../modules/fundDetails/actions/fundDetailsStructureActions";

import GTC from "./gtc";

import Footer from "./footer";
import PageTitle from "js/components/pageTitle/PageTitle";
import PublicRoute from "js/components/routes/publicRoute";
import { triggerAuthentication } from "js/authentification";
import { resetBuffer, setBuffer } from "js/promotorChangeBuffer";
import SuspenseLoader from "js/components/suspenseLoader/SuspenseLoader";

// Lazy loaded modules
const MatrixModule = lazy(() => import("./matrix/MatrixModule"));
const BillingModule = lazy(() => import("./billing/BillingModule"));
const Logs = lazy(() => import("./logs/logs"));
const Entities = lazy(() => import("./entities/entities"));
const Library = lazy(() => import("./library/Library"));
const Dissemination = lazy(() => import("./dissemination/dissemination"));
const Tasks = lazy(() => import("./tasks/tasks"));
const Locked = lazy(() => import("./locked"));
const DistributionMap = lazy(() => import("./distributionMap/distributionMap"));
const Settings = lazy(() => import("./settings/settings"));
const Reporting = lazy(() => import("./reporting/reporting"));

const RightsManagement = lazy(() =>
  import("./rightsManagement/rightsManagement")
);

const MarketingArrangement = lazy(() =>
  import("./marketingArrangement/marketingArrangement")
);
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Publications = lazy(() => import("./publications/publications"));
const FundDistributionWatch = lazy(() =>
  import("./fundDistributionWatch/fundDistributionWatch")
);
const FundDistributionWatchTags = lazy(() =>
  import("./fundDistributionWatch/fundDistributionWatchTags")
);
const Lib = lazy(() => import("./lib"));
const Calendar = lazy(() => import("./calendar/Calendar"));
const PrivatePlacements = lazy(() =>
  import("./privatePlacements/privatePlacements")
);

const FacilitiesAgent = lazy(() =>
  import("./facilitiesAgent/facilitiesAgents")
);
const FacilitiesAgentView = lazy(() =>
  import("./facilitiesAgent/facilitiesAgentView")
);
const FacilitiesAgentFundManager = lazy(() =>
  import("./facilitiesAgent/FacilitiesAgentFundManager")
);
class App extends Component {
  timeout;
  constructor(props) {
    super(props);
    this.changeUrl = (u) => {
      this.setState({ url: u });
    };
    this.state = {
      distributionMatrixDate: this.props.user.regMatrixUpdateDate,
      currentPromotorId: this.props.user.promotorId,
      alertChangePromotor: false,
      hasHoverClass: false,
      container: document.body,
      lastTouchTime: 0,
      adminMode: false,
      filesToUpload: [],
      forceRefreshLibOk: false,
      loading: false,
      url: "",
      changeUrl: this.changeUrl,
    };
    this.windowRef = React.createRef(window);
    this.copyrightRef = React.createRef();
    setTimeout(resetBuffer, 1000);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.logOff.isLoading && !nextProps.logOff.isLoading) {
      document.location.href = `${process.env.REACT_APP_API_BASEURL}Security/LogOut`;
    }
    if (!this.props.user.isAuthenticated && nextProps.user.isAuthenticated) {
      this.setState({
        distributionMatrixDate: nextProps.user.regMatrixUpdateDate,
        currentPromotorId: nextProps.user.promotorId,
      });
      this.initAnalytics(nextProps.user);
    }
  };

  logout = () => {
    this.setState({
      loading: true,
    });
    this.props.loadLogOff();
  };

  updateDistributionMatrixDate = (date) => {
    if (date != this.state.distributionMatrixDate) {
      this.setState(
        {
          distributionMatrixDate: date,
        },
        () => {
          this.props.loadCountries();
          this.props.loadCountriesByRegion();
        }
      );
    }
  };

  queryCurrentPromotor = () => {
    fetch(process.env.REACT_APP_API_BASEURL + "Promotor/GetCurrentPromotor", {
      method: "get",
      credentials: "include",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        RequestVerificationToken: this.props.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let history = this.props.history;
        if (
          !this.props.user.isAuthenticated &&
          this.props.authentification.needsAuth
        ) {
          if (data.status == "401.1")
            // perte session ADFS
            triggerAuthentication();
          if (data.status == "401.2")
            // GTC a accepter
            history.push("/gtc");
          if (data.status == "206")
            // matrice en cours d'upload
            history.push("/dissemination/locked");
          if (data.status == "207")
            // matrice en cours d'upload
            history.push("/locked");
          if (data.status == "520") {
            // refresh des droits
            this.updateUser();
          }
        }
        if (data.data && this.state.currentPromotorId != data.data) {
          this.checkCurrentPromotor();
        }
      });
  };

  queryRegistrationMatrixDate = (loop) => {
    if (!this.props.user) return;
    // Ne pas checker la date de la matrice pour les users FDW Only
    if (this.props.user.roles.includes(8)) return;

    fetch(
      process.env.REACT_APP_API_BASEURL +
      "RegistrationMatrix/RegMatrixLastUpdateDate",
      {
        method: "get",
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          RequestVerificationToken: this.props.token,
        },
      }
    )
      .then((response) => {
        if (loop)
          setTimeout(() => {
            this.queryRegistrationMatrixDate(true);
          }, 60 * 1000);
        return response.json();
      })
      .then((data) => {
        let d = data.data;
        this.updateDistributionMatrixDate(d);
      });
  };

  initAnalytics = (user) => {
    if (!user.isAuthenticated) return;
    var _paq = (window._paq = window._paq || []);
    if (document.URL.indexOf("gfdplatform-uat.pwc.lu") >= 0) {
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(["trackPageView"]);
      _paq.push(["enableLinkTracking"]);
      var u = "//webanalytics-staging.pwc.lu/";
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", "40"]);
      _paq.push(["setCustomVariable", 1, "User name", user.displayName]);
      _paq.push(["setCustomVariable", 2, "Email", user.email]);
      _paq.push(["setCustomVariable", 3, "Promotor Id", user.promotorId]);
      _paq.push(["setCustomVariable", 4, "Promotor Name", user.promotorName]);
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.type = "text/javascript";
      g.defer = true;
      g.async = true;
      g.src = u + "matomo.js";
      s.parentNode.insertBefore(g, s);
    }

    if (document.URL.indexOf("gfdplatform.pwc.lu") >= 0) {
      u = "//webanalytics.pwc.lu/";
      _paq.push(["setTrackerUrl", u + "piwik.php"]);
      _paq.push(["setSiteId", "155"]);
      _paq.push(["setCustomVariable", 1, "User name", user.displayName]);
      _paq.push(["setCustomVariable", 2, "Email", user.email]);
      _paq.push(["setCustomVariable", 3, "Promotor Id", user.promotorId]);
      _paq.push(["setCustomVariable", 4, "Promotor Name", user.promotorName]);
      _paq.push(["trackPageView"]);
      d = document;
      g = d.createElement("script");
      s = d.getElementsByTagName("script")[0];
      g.type = "text/javascript";
      g.async = true;
      g.defer = true;
      g.src = u + "piwik.js";
      s.parentNode.insertBefore(g, s);
    }
  };

  componentDidMount = () => {
    if (this.props.user.frontAccessList["DISTRI_MATRIX_READ"]) {
      this.props.loadDistributionMatrix({});
      this.props.loadDistributionMatrixAllFilters();
    }
    if (this.props.user.frontAccessList["FUND_DETAILS_READ"]) {
      this.props.loadFundDetailsStructure({});
    }
    this.props.loadCountries();
    this.props.loadCountriesByRegion();
    this.queryCurrentPromotor();
    window.addEventListener("focus", this.queryCurrentPromotor);
    this.queryRegistrationMatrixDate(true);
    document.addEventListener("touchstart", this.updateLastTouchTime, true);
    document.addEventListener("touchstart", this.disableHover, true);
    document.addEventListener("mousemove", this.enableHover, true);
    setTimeout(() => {
      this.setState({
        logoutTimer: true,
      });
    }, 1000);
    this.initAnalytics(this.props.user);
  };

  componentWillUnmount = () => {
    if (this.timeout) clearTimeout(this.timeout);
    document.removeEventListener("touchstart", this.updateLastTouchTime);
    document.removeEventListener("touchstart", this.disableHover);
    document.removeEventListener("mousemove", this.enableHover);
    window.removeEventListener("focus", this.queryCurrentPromotor);
  };

  enableHover = () => {
    // filter emulated events coming from touch events
    if (new Date() - this.state.lastTouchTime < 500) return;
    if (this.state.hasHoverClass) return;

    document.body.classList.add("hasHover");
    this.setState({ hasHoverClass: true });
  };

  disableHover = () => {
    if (!this.state.hasHoverClass) return;

    document.body.classList.remove("hasHover");
    this.setState({ hasHoverClass: false });
  };

  updateLastTouchTime = () => {
    this.setState({ lastTouchTime: new Date() });
  };

  checkCurrentPromotor = () => {
    this.setState({ alertChangePromotor: true });
  };

  updateUser = () => {
    this.props.loadAuthentification();
  };

  switchAdminMode = (checked, noRedirect) => {
    this.setState({
      adminMode: checked,
    });
    this.resetErrorBoundary();
    if (checked) {
      if (!noRedirect)
        this.props.history.push("/admin/entities/list");
    } else {
      if (!noRedirect) this.props.history.push("/");
    }
  };

  uploadFiles = (acceptedFiles) => {
    this.setState({ filesToUpload: acceptedFiles });
  };

  forceRefreshLibOk = () => {
    this.setState(
      {
        forceRefreshLibOk: true,
      },
      () => {
        this.setState({
          forceRefreshLibOk: false,
        });
      }
    );
  };

  errorBoundaryRef;

  resetErrorBoundary = () => {
    if (this.errorBoundaryRef && this.errorBoundaryRef.state.error) {
      this.errorBoundaryRef.resetError();
    }
  };

  render() {
    let match = this.props.match;

    return (
      <>
        <PageTitle />
        <Header
          switchAdminMode={this.switchAdminMode}
          token={this.props.token}
          user={this.props.user}
          history={this.props.history}
          updateUser={this.updateUser}
          countriesByRegion={this.props.countriesByRegion}
          countries={this.props.countries}
          url={this.state.url}
          logoOnly={!this.props.user.isAuthenticated}
          hideIfNotAuthenticated={true}
        />
        {this.props.user.isAuthenticated && (
          <Nav
            filesToUpload={this.state.filesToUpload}
            adminMode={this.state.adminMode}
            user={this.props.user}
            forceRefreshLibOk={this.forceRefreshLibOk}
            history={this.props.history}
            match={this.props.match}
            onNavigate={this.resetErrorBoundary}
          />
        )}
        <ErrorBoundary ref={(elem) => (this.errorBoundaryRef = elem)}>
          <div className="main-content">
            <Suspense fallback={<SuspenseLoader />}>
              <Switch>
                {this.props.user.roles[0] == 8 ? (
                  <Redirect
                    exact
                    from={match.path}
                    to={match.path + "fund-distribution-watch"}
                  />
                ) : this.props.user.roles[0] === 10 ? (
                  <Redirect exact from={match.path} to={match.path + "billing"} />
                ) : (
                  <Redirect
                    exact
                    from={match.path}
                    to={match.path + "dashboard"}
                  />
                )}
                {this.props.user.frontAccessList["ADMINISTRATOR_WRITE"] ? (
                  <Route
                    path={match.path + "lib"}
                    render={(props) => <Lib {...props} />}
                  />
                ) : null}
                <Route path={match.path + "empty"} render={() => null} />

                {this.props.user.frontAccessList["DISTRI_MATRIX_READ"] && (
                  <Route
                    path={match.path + "matrix/:mode?"}
                    render={(props) => <MatrixModule {...props} />}
                  />
                )}

                {(this.props.user.frontAccessList["BILLING_PACKAGE_READ"] ||
                  this.props.user.frontAccessList["BILLING_INVOICE_READ"] ||
                  this.props.user.frontAccessList["BILLING_NETWORK_READ"]) && (
                    <Route
                      path={match.path + "billing/:mode?"}
                      render={(props) => <BillingModule {...props} />}
                    />
                  )}

                {this.props.user.frontAccessList["LIBRARY_READ"] &&
                  this.props.user.hasRegistrationMatrix ? (
                  <Route
                    path={match.path + "library"}
                    render={(props) => (
                      <Library
                        uploadFiles={this.uploadFiles}
                        user={this.props.user}
                        countriesByRegion={this.props.countriesByRegion}
                        countries={this.props.countries}
                        forceRefreshLibOk={this.state.forceRefreshLibOk}
                        {...props}
                      />
                    )}
                  />
                ) : null}
                <PublicRoute
                  path={match.path + "facilities-agent/view/:id"}
                  render={(props) => {
                    const id = props.match.params.id;
                    return <FacilitiesAgentView id={id} />;
                  }}
                />
                {this.props.user.frontAccessList["FACILITIES_AGENT_WRITE"] && (
                  <Route
                    path={match.path + "facilities-agent/manage-funds"}
                    render={(props) => <FacilitiesAgentFundManager />}
                  />
                )}
                <Route
                  path={match.path + "facilities-agent"}
                  render={(props) => <FacilitiesAgent />}
                />

                {this.props.user.frontAccessList["DISSEMINATION_WRITE"] ||
                  this.props.user.frontAccessList["DISSEMINATION_READ"] ? (
                  <Route
                    path={match.path + "dissemination"}
                    render={(props) => (
                      <Dissemination
                        token={this.props.token}
                        user={this.props.user}
                        countriesByRegion={this.props.countriesByRegion}
                        countries={this.props.countries}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                {this.props.user.frontAccessList["ADMINISTRATOR_WRITE"] ? (
                  <Route
                    path={match.path + "admin/dissemination"}
                    render={(props) => (
                      <Dissemination
                        admin
                        token={this.props.token}
                        user={this.props.user}
                        countriesByRegion={this.props.countriesByRegion}
                        countries={this.props.countries}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                {this.props.user.frontAccessList["ADMINISTRATOR_WRITE"] ? (
                  <Route
                    path={match.path + "admin/fund-distribution-watch-tags"}
                    render={(props) => (
                      <FundDistributionWatchTags
                        user={this.props.user}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                <Route
                  path={match.path + "dashboard"}
                  render={(props) => (
                    <Dashboard user={this.props.user} {...props} />
                  )}
                />

                <Route
                  path={match.path + "gtc"}
                  render={(props) => <GTC user={this.props.user} {...props} />}
                />

                {this.props.user.frontAccessList["DISTRI_MATRIX_READ"] ? (
                  <Route
                    exact
                    path={match.path + "locked"}
                    render={(props) => (
                      <Locked
                        updateUser={this.updateUser}
                        token={this.props.token}
                        user={this.props.user}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                {this.props.user.frontAccessList["SUPPORT_WRITE"] ? (
                  <Route
                    path={match.path + "admin/rights-management"}
                    render={(props) => (
                      <RightsManagement
                        updateUser={this.updateUser}
                        token={this.props.token}
                        user={this.props.user}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                {this.props.user.frontAccessList["ADMINISTRATOR_WRITE"] ? (
                  <Route
                    path={match.path + "admin/publications"}
                    render={(props) => (
                      <Publications
                        updateUser={this.updateUser}
                        token={this.props.token}
                        user={this.props.user}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                {this.props.user.frontAccessList["ADMINISTRATOR_WRITE"] ? (
                  <Route
                    path={match.path + "admin/logs"}
                    render={(props) => (
                      <Logs
                        updateUser={this.updateUser}
                        token={this.props.token}
                        user={this.props.user}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                {this.props.user.frontAccessList["TASK_LIST_READ"] &&
                  this.props.user.hasRegistrationMatrix
                  ? [
                    <Route
                      key="route-tasks"
                      path={match.path + "tasks"}
                      render={(props) => (
                        <Tasks
                          token={this.props.token}
                          user={this.props.user}
                          countriesByRegion={this.props.countriesByRegion}
                          countries={this.props.countries}
                          changeUrl={this.state.changeUrl}
                          {...props}
                        />
                      )}
                    />,
                    <Route
                      key="route-calendar"
                      path={match.path + "calendar/:year?/:month?"}
                      render={(props) => <Calendar />}
                    />,
                  ]
                  : null}

                {this.props.user.frontAccessList["ENTITIES_READ"] ? (
                  <Route
                    path={match.path + "entities"}
                    render={(props) => (
                      <Entities
                        token={this.props.token}
                        user={this.props.user}
                        countriesByRegion={this.props.countriesByRegion}
                        countries={this.props.countries}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                {this.props.user.frontAccessList["ADMINISTRATOR_WRITE"] ? (
                  <Route
                    path={match.path + "admin/entities"}
                    render={(props) => (
                      <Entities
                        token={this.props.token}
                        user={this.props.user}
                        countriesByRegion={this.props.countriesByRegion}
                        countries={this.props.countries}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                {this.props.user.frontAccessList["ADMINISTRATOR_WRITE"] ? (
                  <Route
                    path={match.path + "admin/private-placements"}
                    render={(props) => <PrivatePlacements {...props} />}
                  />
                ) : null}

                {this.props.user.frontAccessList["DISTRIBUTION_MAP_READ"] &&
                  this.props.user.hasRegistrationMatrix ? (
                  <Route
                    path={match.path + "distribution-map"}
                    render={(props) => (
                      <DistributionMap
                        updateDistributionMatrixDate={
                          this.updateDistributionMatrixDate
                        }
                        updateDate={this.state.distributionMatrixDate}
                        user={this.props.user}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                {this.props.user.frontAccessList["REPORTING_READ"] ? (
                  <Route
                    path={match.path + "reporting"}
                    render={(props) => (
                      <Reporting
                        updateUser={this.updateUser}
                        token={this.props.token}
                        user={this.props.user}
                        countriesByRegion={this.props.countriesByRegion}
                        countries={this.props.countries}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                <Route
                  path={match.path + "marketing-arrangement"}
                  render={(props) => (
                    <MarketingArrangement
                      updateUser={this.updateUser}
                      token={this.props.token}
                      user={this.props.user}
                      countries={this.props.countries}
                      {...props}
                    />
                  )}
                />

                {this.props.user.frontAccessList["FDW_READ"] ? (
                  <Route
                    path={match.path + "fund-distribution-watch"}
                    render={(props) => (
                      <FundDistributionWatch
                        updateUser={this.updateUser}
                        token={this.props.token}
                        user={this.props.user}
                        countries={this.props.countries}
                        countriesByRegion={this.props.countriesByRegion}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                {this.props.user.frontAccessList["SETTING_WRITE"] ? (
                  <Route
                    path={match.path + "settings"}
                    render={(props) => (
                      <Settings
                        updateUser={this.updateUser}
                        token={this.props.token}
                        user={this.props.user}
                        countriesByRegion={this.props.countriesByRegion}
                        countries={this.props.countries}
                        {...props}
                      />
                    )}
                  />
                ) : null}

                <Route
                  render={(props) => (
                    <NotFound user={this.props.user} {...props} />
                  )}
                />
              </Switch>
            </Suspense>
          </div>
        </ErrorBoundary>

        <BackToTop />
        <Footer />
        <Alert
          close={() => {
            setBuffer();
            window.location.reload();
          }}
          title={"Promotor change detected"}
          message={
            "We detected that you are working with 2 different browsers with 2 different promotors. This is not possible. The page will reload with the latest selected promotor."
          }
          show={this.state.alertChangePromotor}
        />
        <Logout logout={this.logout} />
        <LoaderFullScreen show={this.state.loading} />
      </>
    );
  }
}

App.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    notifications: state.notifications,
    distributionMatrix: state.distributionMatrixReducer,
    distributionMatrixAllFilters: state.distributionMatrixAllFiltersReducer,
    subfundsByCountry: state.subfundsByCountryReducer,
    authentification: state.authentificationReducer,
    promotorsGetCurrent: state.promotorsGetCurrentReducer,
    countries: state.countriesReducer,
    countriesByRegion: state.countriesByRegionReducer,
    tasksByStatus: state.tasksByStatusReducer,
    tasksByType: state.tasksByTypeReducer,
    logOff: state.logOffReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      authentificationActions,
      distributionMatrixActions,
      distributionMatrixAllFiltersActions,
      fundDetailsStructureActions,
      subfundsByCountryActions,
      countriesActions,
      countriesByRegionActions,
      tasksByStatusActions,
      tasksByTypeActions,
      logOffActions,
      promotorsGetCurrentActions
    ),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
