export const GET_COUNTRIES_INIT = "COUNTRIES:GET_INIT";
export const GET_COUNTRIES_SUCCESS = "COUNTRIES:GET_SUCCESS";
export const GET_COUNTRIES_FAILURE = "COUNTRIES:GET_FAIL";

export const GET_COUNTRIES_BY_REGION_INIT = "COUNTRIES_BY_REGION:GET_INIT";
export const GET_COUNTRIES_BY_REGION_SUCCESS =
  "COUNTRIES_BY_REGION:GET_SUCCESS";
export const GET_COUNTRIES_BY_REGION_FAILURE = "COUNTRIES_BY_REGION:GET_FAIL";

export const GET_COUNTRIES_BY_REGION_WITH_CUSTOM_INIT =
  "COUNTRIES_BY_REGION_WITH_CUSTOM:GET_INIT";
export const GET_COUNTRIES_BY_REGION_WITH_CUSTOM_SUCCESS =
  "COUNTRIES_BY_REGION_WITH_CUSTOM:GET_SUCCESS";
export const GET_COUNTRIES_BY_REGION_WITH_CUSTOM_FAILURE =
  "COUNTRIES_BY_REGION_WITH_CUSTOM:GET_FAIL";
