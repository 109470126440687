// react dependencies
import React, { Component } from "react";

class BackToTop extends Component {
  UNSAFE_componentWillMount = () => {
    window.requestAnimFrame = (function () {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function (callback) {
          window.setTimeout(callback, 1000 / 60);
        }
      );
    })();
    document.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnMount = () => {
    document.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = () => {
    if (this.refs.backToTop && this.refs.backToTop.classList) {
      let distance = window.pageYOffset;
      if (distance > 200) {
        this.refs.backToTop.classList.add("back-to-top--visible");
      } else {
        this.refs.backToTop.classList.remove("back-to-top--visible");
      }
    }
  };

  onClick = () => {
    let isIE = document.documentMode || /Edge/.test(navigator.userAgent);
    isIE = true;
    if (!isIE) this.scrollTo(0, 500, "easeInOutQuint");
    else window.scrollTo(0, 0);
  };

  scrollTo = (scrollTargetY, speed, easing) => {
    // scrollTargetY: the target scrollY property of the window
    // speed: time in pixels per second
    // easing: easing equation to use

    var scrollY = window.scrollY || document.documentElement.scrollTop,
      currentTime = 0;

    // min time .1, max time .8 seconds
    var time = Math.max(
      0.1,
      Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)
    );

    // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
    var easingEquations = {
      easeOutSine: function (pos) {
        return Math.sin(pos * (Math.PI / 2));
      },
      easeInOutSine: function (pos) {
        return -0.5 * (Math.cos(Math.PI * pos) - 1);
      },
      easeInOutQuint: function (pos) {
        if ((pos /= 0.5) < 1) {
          return 0.5 * Math.pow(pos, 5);
        }
        return 0.5 * (Math.pow(pos - 2, 5) + 2);
      },
    };

    // add animation loop
    let tick = () => {
      currentTime += 1 / 60;

      var p = currentTime / time;
      var t = easingEquations[easing](p);

      if (p < 1) {
        window.requestAnimFrame(tick);

        window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
      } else {
        window.scrollTo(0, scrollTargetY);
      }
    };

    // call it once to get started
    tick();
  };

  render() {
    return (
      <div
        ref="backToTop"
        onClick={this.onClick}
        className="mdc-fab back-to-top"
      >
        <i className="pwc-arrows-arrow-up-12"></i>
      </div>
    );
  }
}

export default BackToTop;
