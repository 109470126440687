// react dependencies
import React, { Component } from "react";

class ModalBody extends Component {
  render() {
    /*
    return (
        <div className="modal-content-body">
            {this.props.children}
        </div>
    );
    */
    return (
      <div className="mdc-dialog__content" id="my-dialog-content">
        {this.props.children}
      </div>
    );
  }
}
export default ModalBody;
