import React from "react";
import Button from "../button/Button";

import "./ErrorBoundary.scss";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: undefined, errorInfo: undefined };
    this.resetError = this.resetError.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
  }

  resetError() {
    this.setState({
      error: undefined,
      errorInfo: undefined,
    });
  }

  render() {
    const error = this.state.error;
    const errorInfo = this.state.errorInfo;

    if (error) {
      return (
        <section className="not-found react-error-interceptor">
          <div className="container-default">
            <div className="not-found-oops">Oops...</div>
            <div className="not-found-error">An error has occured</div>
            <div className="not-found-sorry">
              We're sorry for any inconvenience caused. Please try again.
            </div>
            <div className="not-found-action">
              <Button onClick={this.resetError} label="Retry" />
            </div>
            {errorInfo?.componentStack &&
              process.env.REACT_APP_ENV_SHORT_NAME !== "prod" && (
                <details
                  open={process.env.REACT_APP_ENV_SHORT_NAME === "localhost"}
                >
                  <summary>Error details</summary>
                  <p>{error.message}</p>
                  <pre>{errorInfo.componentStack}</pre>
                </details>
              )}
          </div>
        </section>
      );
    }

    return this.props.children;
  }
}
