// react dependencies
import React, { Component } from "react";

class ModalFooter extends Component {
  render() {
    /*
    return (
        <div className="modal-content-footer">
            {this.props.children}
        </div>
    );
    */
    return (
      <footer
        className={
          "mdc-dialog__actions" +
          (this.props.className ? " " + this.props.className : "")
        }
      >
        {this.props.children}
      </footer>
    );
  }
}
export default ModalFooter;
