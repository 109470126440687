export const GET_FUNDS_INIT = "FUNDS:GET_INIT";
export const GET_FUNDS_SUCCESS = "FUNDS:GET_SUCCESS";
export const GET_FUNDS_FAILURE = "FUNDS:GET_FAIL";

export const GET_FUNDS_LIFE_CYCLE_STATUS_INIT =
  "FUNDS_LIFE_CYCLE_STATUS:GET_INIT";
export const GET_FUNDS_LIFE_CYCLE_STATUS_SUCCESS =
  "FUNDS_LIFE_CYCLE_STATUS:GET_SUCCESS";
export const GET_FUNDS_LIFE_CYCLE_STATUS_FAILURE =
  "FUNDS_LIFE_CYCLE_STATUS:GET_FAIL";

export const GET_FUNDS_DOMICILES_INIT = "FUNDS_DOMICILES:GET_INIT";
export const GET_FUNDS_DOMICILES_SUCCESS = "FUNDS_DOMICILES:GET_SUCCESS";
export const GET_FUNDS_DOMICILES_FAILURE = "FUNDS_DOMICILES:GET_FAIL";

export const GET_FUNDS_BY_COUNTRY_INIT = "FUNDS_BY_COUNTRY:GET_INIT";
export const GET_FUNDS_BY_COUNTRY_SUCCESS = "FUNDS_BY_COUNTRY:GET_SUCCESS";
export const GET_FUNDS_BY_COUNTRY_FAILURE = "FUNDS_BY_COUNTRY:GET_FAIL";

export const GET_SUBFUNDS_BY_COUNTRY_INIT = "SUBFUNDS_BY_COUNTRY:GET_INIT";
export const GET_SUBFUNDS_BY_COUNTRY_SUCCESS =
  "SUBFUNDS_BY_COUNTRY:GET_SUCCESS";
export const GET_SUBFUNDS_BY_COUNTRY_FAILURE = "SUBFUNDS_BY_COUNTRY:GET_FAIL";

export const GET_FUNDS_BY_PROMOTOR_INIT = "FUNDS_BY_PROMOTOR:GET_INIT";
export const GET_FUNDS_BY_PROMOTOR_SUCCESS = "FUNDS_BY_PROMOTOR:GET_SUCCESS";
export const GET_FUNDS_BY_PROMOTOR_FAILURE = "FUNDS_BY_PROMOTOR:GET_FAIL";

export const GET_FUNDS_BY_TASK_TEMPLATE_INIT =
  "FUNDS_BY_TASK_TEMPLATE:GET_INIT";
export const GET_FUNDS_BY_TASK_TEMPLATE_SUCCESS =
  "FUNDS_BY_TASK_TEMPLATE:GET_SUCCESS";
export const GET_FUNDS_BY_TASK_TEMPLATE_FAILURE =
  "FUNDS_BY_TASK_TEMPLATE:GET_FAIL";
