// react dependencies
import React, { Component } from "react";

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.checked != this.state.checked) {
      this.setState({
        checked: nextProps.checked,
      });
    }
  }

  handleClick = (e) => {
    e.persist();
    if (this.props.disablePropagation) {
      e.stopPropagation();
    }
    if (this.props.onClick && !this.props.disabled) {
      this.props.onClick(!this.state.checked);
    }
    //this.setState({ checked: !this.state.checked })
  };

  renderLabel = () => {
    if (this.props.label) {
      return <span>{this.props.label}</span>;
    }
  };

  render() {
    if (this.props.lightCheckbox)
      return (
        <label
          onClick={this.props.disabled ? () => {} : this.handleClick}
          className={
            "checkbox " +
            (this.state.checked ? "checkbox--checked" : "") +
            (this.props.disabled ? " checkbox--disabled" : "")
          }
        >
          <input
            onClick={(e) => e.stopPropagation()}
            disabled={this.props.disabled}
            type="checkbox"
            onChange={() => {}}
            checked={this.state.checked}
          />
          {this.renderLabel()}
        </label>
      );
    else
      return (
        <div
          onClick={this.props.disabled ? () => {} : this.handleClick}
          className="mdc-form-field"
        >
          <div
            className={
              "mdc-checkbox" +
              (this.props.disabled ? " mdc-checkbox--disabled" : "")
            }
          >
            <input
              type="checkbox"
              checked={this.state.checked}
              className="mdc-checkbox__native-control"
              onChange={() => {}}
            />
            <div className="mdc-checkbox__background">
              <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                <path
                  className="mdc-checkbox__checkmark-path"
                  fill="none"
                  d="M1.73,12.91 8.1,19.28 22.79,4.59"
                />
              </svg>
              <div className="mdc-checkbox__mixedmark"></div>
            </div>
          </div>
          <label>{this.renderLabel()}</label>
        </div>
      );
  }
}

export default Checkbox;
