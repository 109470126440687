import Request from "../utils/requests/request";

export default class CountriesApi {
  static loadCountries() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Countries/GetWithoutCustomCountry"
    );
    return loadReq.get();
  }

  static loadCountriesByRegion() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Countries/GetCountryGroupWithoutCustomCountry"
    );
    return loadReq.get();
  }

  static loadCountriesByRegionWithCustom() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Countries/GetCountryGroup"
    );
    return loadReq.get();
  }
}
