// react dependencies
import React, { Component } from "react";

import Moment from "moment";

class NotificationCenterItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleted: false,
    };
  }

  onClick = () => {
    let href = "";
    if (
      Array.isArray(this.props.notification.targets) &&
      this.props.notification.targets.length
    ) {
      this.props.notification.targets.forEach((target) => {
        if (target.name == "Task") {
          href = "/tasks/" + target.id;
        }
      });
      this.props.hide();
    }
    if (this.props.onClick)
      this.props.onClick(
        href,
        this.props.notification.promotor.id,
        this.props.notification.promotor.name
      );
  };

  renderClassName = () => {
    if (
      Array.isArray(this.props.notification.targets) &&
      this.props.notification.targets.length
    ) {
      return " notification-center-content-item--with-action";
    }
    return "";
  };

  delete = (e) => {
    e.stopPropagation();
    this.props.deleteNotification();
    /*
    this.setState({
      deleted: true
    })
    */
  };

  renderLineBreaks = (text) => {
    let title = text.split("\\n");
    return title.map(function (item, key) {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });
  };

  render() {
    if (!this.state.deleted)
      /*return (
      <div onClick={this.onClick} className={"notification-center-content-item"+(this.props.notification.stateId<=2?" notification-center-content-item--unread":"")+this.renderClassName()}>
        <div className="notification-center-content-item-container">
          <div>
            <span>
              <div className="notification-center-content-item-day">{Moment(this.props.notification.date, "YYYYMMDD HH:mm:ss").format("DD")}.{Moment(this.props.notification.date, "YYYYMMDD HH:mm:ss").format("MM")}</div>
              <div className="notification-center-content-item-year">{Moment(this.props.notification.date, "YYYYMMDD HH:mm:ss").format("YYYY")}</div>
            </span>
            <div>
              <div className="notification-center-content-item-client">{this.props.notification.promotor.name}</div>
              <div className="notification-center-content-item-title">{this.props.notification.title}</div>
              <div className="notification-center-content-item-text">{this.props.notification.text}</div>
            </div>
          </div>
          <i onClick={(e) => this.delete(e)} className="pwc-status-close"></i>
        </div>
      </div>
    ); */
      return (
        <div
          onClick={this.onClick}
          className={
            "notification-center-content-item" +
            (this.props.notification.stateId <= 2
              ? " notification-center-content-item--unread"
              : "") +
            this.renderClassName()
          }
        >
          <div className="notification-center-content-item-container">
            <div>
              <div className="date-promotor">
                <div className="date">
                  {Moment(this.props.notification.date).fromNow()}
                </div>
                <div className="promotor">
                  {this.props.notification.promotor.name}
                </div>
              </div>
              <div className="title">
                {this.renderLineBreaks(this.props.notification.title)}
              </div>
              <div className="description">
                {this.renderLineBreaks(this.props.notification.text)}
              </div>
            </div>
            <i onClick={(e) => this.delete(e)} className="pwc-status-close"></i>
          </div>
        </div>
      );
    else return <span></span>;
  }
}
export default NotificationCenterItem;
