// react dependencies
import React, { Component } from "react";

class LoaderModal extends Component {
  render() {
    if (this.props.show)
      return (
        <div className="cs-loader">
          <div className="loaderv3">
            <svg className="circularv3" viewBox="25 25 50 50">
              <circle
                className="pathv3"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        </div>
      );
    else return <span></span>;
  }
}
export default LoaderModal;
