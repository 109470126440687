// react dependencies
import React, { useEffect, useMemo, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import { isString } from "lodash";
import classNames from "classnames";

const NavItem = (props) => {
  const ref = useRef();
  const location = useLocation();

  useEffect(() => {
    window.mdc.ripple.MDCRipple.attachTo(ref.current);
  }, []);

  const isActive = useMemo(() => {
    if (props.to && location.pathname.match(props.to) !== null) {
      return () => true;
    }
    return undefined;
  }, [props.to, location]);

  return (
    <li
      ref={ref}
      onClick={props.onClick}
      className="nav--surface main-nav-item mdc-ripple-surface mdc-ripple-surface--primary"
    >
      <NavLink
        activeClassName="main-nav-item--active"
        to={props.to}
        isActive={isActive}
      >
        {isString(props.icon) ? (
          <i className={props.icon} />
        ) : (
          <div
            className={classNames("nav-icon-wrapper", isActive?.() && "active")}
          >
            {props.icon}
          </div>
        )}
        <div className="main-nav-item-label">{props.label}</div>
      </NavLink>
    </li>
  );
};

export default NavItem;
