// react dependencies
import React, { Component } from "react";

class Loader extends Component {
  render() {
    return (
      <div className="cs-loader">
        {/*
          <div className="cs-loader-inner">
            <label className="square-load">	■</label>
            <label className="square-load">	■</label>
            <label className="square-load">	■</label>
            <label className="square-load">	■</label>
            <label className="square-load">	■</label>
            <label className="square-load">	■</label>
          </div>
          */}

        {/*
          <div className="loaderv3">
            <svg className="circularv3" viewBox="25 25 50 50">
              <circle className="pathv3" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
          </div>
          */}

        <div className="preloader-wrapper big active">
          <div className="spinner-layer spinner-blue-only">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>
            <div className="gap-patch">
              <div className="circle"></div>
            </div>
            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      </div>
      /*
            <div className="lds-css">
                <div className="lds-dual-ring">
                    <div></div>
                </div>
            </div>
          */
    );
  }
}
export default Loader;
