// react dependencies
import React, { useEffect, useState } from "react";
import Loader from "./components/loader/Loader";

import { loadAuthentification } from "./modules/authentification/actions/authentificationActions";
import { loadPromotorsGetInfo } from "./modules/promotors/actions/promotorsGetInfoActions";

import FullPageError from "./containers/fullPageError";
import Header from "./containers/header";

import ImageNoProfile from "img/no-access.svg";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

export const triggerAuthentication = () =>
  (window.location.href =
    process.env.REACT_APP_API_BASEURL + "Login?url=" + document.URL);

const DEFAULT_USER = {
  hasRegistrationMatrix: false,
  regMatrixUpdateDate: "1989/01/05 00:00:00",
  isAuthenticated: false,
  staffId: "",
  roles: [],
  frontAccessList: [],
  promotorWithNotifications: [],
  specificPromotorAccess: [],
  specificFundAccess: null,
  id: 0,
  email: "",
  displayName: " ",
  firstName: "",
  lastName: "",
  clientId: 0,
  promotorId: 0,
  promotorName: null,
  photo: null,
  phone: null,
  grade: null,
  creationDate: null,
  lastConnexion: null,
  hasPremiumNewsAccess: false,
  isFundRestricted: false,
};

const setAuthenticatedUserForInsights = (appInsights, staffId) => {
  if (appInsights) {
    appInsights.setAuthenticatedUserContext(staffId, null, true);
  }
};

/**
 * main component for this app
 */

RequireAuthentification.propTypes = {
  AppComponent: PropTypes.object,
  appInsights: PropTypes.object,
};

export function RequireAuthentification({
  AppComponent,
  appInsights,
  ...props
}) {
  const authentification = useSelector(
    (state) => state.authentificationReducer
  );
  const dispatch = useDispatch();
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    if (
      authentification?.needsAuth &&
      !authentification?.data?.user?.isAuthenticated
    ) {
      setIsRedirecting(true);
      triggerAuthentication();
    }
  }, [authentification, setIsRedirecting]);

  useEffect(() => {
    dispatch(loadAuthentification());
  }, [dispatch]);

  useEffect(() => {
    if (authentification?.data?.user?.isAuthenticated) {
      setAuthenticatedUserForInsights(
        appInsights,
        authentification?.data?.user?.staffId
      );
      dispatch(loadPromotorsGetInfo());
    }
  }, [authentification, appInsights, dispatch]);

  return (
    <>
      {authentification.isLoading || isRedirecting ? (
        <Loader />
      ) : authentification.error ? (
        <>
          <Header logoOnly />
          <FullPageError message="An error prevented the application from starting. Please try again later." />
        </>
      ) : authentification.data.user?.isAuthenticated &&
        authentification.data.user.roles.length === 0 ? (
        <>
          <Header logoOnly />
          <FullPageError
            tagLine="Your profile doesn't exist"
            message="Your profile has not been set-up for use in the GFD Platform"
            illustration={ImageNoProfile}
          />
        </>
      ) : authentification.data.user?.isAuthenticated ? (
        <AppComponent
          token={authentification.data.token}
          user={authentification.data.user}
          {...props}
        />
      ) : (
        <AppComponent token="" user={DEFAULT_USER} {...props} />
      )}
    </>
  );
}
