export const TASK_TEMPLATES_DATA_INIT = "TASK_TEMPLATES_DATA:GET_INIT";
export const TASK_TEMPLATES_DATA_SUCCESS = "TASK_TEMPLATES_DATA:GET_SUCCESS";
export const TASK_TEMPLATES_DATA_FAILURE = "TASK_TEMPLATES_DATA:GET_FAIL";
export const TASK_TEMPLATES_DATA_RESET = "TASK_TEMPLATES_DATA:GET_RESET";

export const TASK_TEMPLATES_SUB_TASKS_DATA_INIT =
  "TASK_TEMPLATES_SUB_TASKS_DATA:GET_INIT";
export const TASK_TEMPLATES_SUB_TASKS_DATA_SUCCESS =
  "TASK_TEMPLATES_SUB_TASKS_DATA:GET_SUCCESS";
export const TASK_TEMPLATES_SUB_TASKS_DATA_FAILURE =
  "TASK_TEMPLATES_SUB_TASKS_DATA:GET_FAIL";
export const TASK_TEMPLATES_SUB_TASKS_DATA_RESET =
  "TASK_TEMPLATES_SUB_TASKS_DATA:GET_RESET";

export const GET_TASK_TEMPLATES_BY_TASK_TYPE_INIT =
  "TASK_TEMPLATES_BY_TASK_TYPE:GET_INIT";
export const GET_TASK_TEMPLATES_BY_TASK_TYPE_SUCCESS =
  "TASK_TEMPLATES_BY_TASK_TYPE:GET_SUCCESS";
export const GET_TASK_TEMPLATES_BY_TASK_TYPE_FAILURE =
  "TASK_TEMPLATES_BY_TASK_TYPE:GET_FAIL";

export const GET_TASK_TEMPLATES_DETAILS_INIT =
  "TASK_TEMPLATES_DETAILS:GET_INIT";
export const GET_TASK_TEMPLATES_DETAILS_SUCCESS =
  "TASK_TEMPLATES_DETAILS:GET_SUCCESS";
export const GET_TASK_TEMPLATES_DETAILS_FAILURE =
  "TASK_TEMPLATES_DETAILS:GET_FAIL";

export const GET_TASK_TEMPLATES_ADD_INIT = "TASK_TEMPLATES_ADD:GET_INIT";
export const GET_TASK_TEMPLATES_ADD_SUCCESS = "TASK_TEMPLATES_ADD:GET_SUCCESS";
export const GET_TASK_TEMPLATES_ADD_FAILURE = "TASK_TEMPLATES_ADD:GET_FAIL";

export const GET_TASK_TEMPLATES_CLONE_INIT = "TASK_TEMPLATES_CLONE:GET_INIT";
export const GET_TASK_TEMPLATES_CLONE_SUCCESS =
  "TASK_TEMPLATES_CLONE:GET_SUCCESS";
export const GET_TASK_TEMPLATES_CLONE_FAILURE = "TASK_TEMPLATES_CLONE:GET_FAIL";

export const GET_TASK_TEMPLATES_DELETE_INIT = "TASK_TEMPLATES_DELETE:GET_INIT";
export const GET_TASK_TEMPLATES_DELETE_SUCCESS =
  "TASK_TEMPLATES_DELETE:GET_SUCCESS";
export const GET_TASK_TEMPLATES_DELETE_FAILURE =
  "TASK_TEMPLATES_DELETE:GET_FAIL";

export const GET_TASK_TEMPLATES_SUB_TASKS_ACTION_INIT =
  "TASK_TEMPLATES_SUB_TASKS_ACTION:GET_INIT";
export const GET_TASK_TEMPLATES_SUB_TASKS_ACTION_SUCCESS =
  "TASK_TEMPLATES_SUB_TASKS_ACTION:GET_SUCCESS";
export const GET_TASK_TEMPLATES_SUB_TASKS_ACTION_FAILURE =
  "TASK_TEMPLATES_SUB_TASKS_ACTION:GET_FAIL";

export const GET_TASK_TEMPLATES_SUB_TASKS_CONDITIONS_INIT =
  "TASK_TEMPLATES_SUB_TASKS_CONDITIONS:GET_INIT";
export const GET_TASK_TEMPLATES_SUB_TASKS_CONDITIONS_SUCCESS =
  "TASK_TEMPLATES_SUB_TASKS_CONDITIONS:GET_SUCCESS";
export const GET_TASK_TEMPLATES_SUB_TASKS_CONDITIONS_FAILURE =
  "TASK_TEMPLATES_SUB_TASKS_CONDITIONS:GET_FAIL";

export const GET_TASK_TEMPLATES_SUB_TASK_DETAILS_INIT =
  "TASK_TEMPLATES_SUB_TASK_DETAILS:GET_INIT";
export const GET_TASK_TEMPLATES_SUB_TASK_DETAILS_SUCCESS =
  "TASK_TEMPLATES_SUB_TASK_DETAILS:GET_SUCCESS";
export const GET_TASK_TEMPLATES_SUB_TASK_DETAILS_FAILURE =
  "TASK_TEMPLATES_SUB_TASK_DETAILS:GET_FAIL";

export const GET_TASK_TEMPLATES_DETAILS_EDIT_INIT =
  "TASK_TEMPLATES_DETAILS_EDIT:GET_INIT";
export const GET_TASK_TEMPLATES_DETAILS_EDIT_SUCCESS =
  "TASK_TEMPLATES_DETAILS_EDIT:GET_SUCCESS";
export const GET_TASK_TEMPLATES_DETAILS_EDIT_FAILURE =
  "TASK_TEMPLATES_DETAILS_EDIT:GET_FAIL";

export const GET_TASK_TEMPLATE_SET_AUTO_INIT =
  "TASK_TEMPLATE_SET_AUTO:GET_INIT";
export const GET_TASK_TEMPLATE_SET_AUTO_SUCCESS =
  "TASK_TEMPLATE_SET_AUTO:GET_SUCCESS";
export const GET_TASK_TEMPLATE_SET_AUTO_FAILURE =
  "TASK_TEMPLATE_SET_AUTO:GET_FAIL";

export const GET_TASK_TEMPLATES_COUNTRIES_INIT =
  "TASK_TEMPLATES_COUNTRIES:GET_INIT";
export const GET_TASK_TEMPLATES_COUNTRIES_SUCCESS =
  "TASK_TEMPLATES_COUNTRIES:GET_SUCCESS";
export const GET_TASK_TEMPLATES_COUNTRIES_FAILURE =
  "TASK_TEMPLATES_COUNTRIES:GET_FAIL";

export const GET_TASK_TEMPLATE_TRIGGERS_INIT =
  "TASK_TEMPLATE_TRIGGERS:GET_INIT";
export const GET_TASK_TEMPLATE_TRIGGERS_SUCCESS =
  "TASK_TEMPLATE_TRIGGERS:GET_SUCCESS";
export const GET_TASK_TEMPLATE_TRIGGERS_FAILURE =
  "TASK_TEMPLATE_TRIGGERS:GET_FAIL";
