import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import NotificationsApi from "../../../models/NotificationsApi";

export function loadNotificationsMarkAsDone(ids) {
  let action = new ActionRequest(
    NotificationsApi.loadNotificationsMarkAsDone,
    loadNotificationsMarkAsDoneInit,
    loadNotificationsMarkAsDoneSuccess,
    loadNotificationsMarkAsDoneFailure
  );
  return action.run({
    routeParameters: { ids },
    actionParameters: { ids },
  });
}

function loadNotificationsMarkAsDoneSuccess(
  notificationsMarkAsDoneData,
  params
) {
  return {
    type: types.GET_NOTIFICATIONS_MARK_AS_DONE_SUCCESS,
    data: notificationsMarkAsDoneData,
  };
}

function loadNotificationsMarkAsDoneFailure(error) {
  return {
    type: types.GET_NOTIFICATIONS_MARK_AS_DONE_FAILURE,
    notification: {
      level: "error",
      label: "[action:notificationsMarkAsDone>loadNotificationsMarkAsDone]",
      message: "Impossible de récupérer la liste des notificationsMarkAsDone",
      system_message: error.message,
    },
  };
}

function loadNotificationsMarkAsDoneInit(params) {
  return { type: types.GET_NOTIFICATIONS_MARK_AS_DONE_INIT };
}
