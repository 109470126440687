import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import AuthentificationApi from "../../../models/AuthentificationApi";

export function loadAcceptGtc() {
  let action = new ActionRequest(
    AuthentificationApi.acceptGtc,
    loadAcceptGtcInit,
    loadAcceptGtcSuccess,
    loadAcceptGtcFailure
  );
  return action.run({
    routeParameters: {},
    actionParameters: {},
  });
}

function loadAcceptGtcSuccess(contactsData, params) {
  return {
    type: types.ACCEPT_GTC_SUCCESS,
    data: contactsData,
  };
}

function loadAcceptGtcFailure(error) {
  return {
    type: types.ACCEPT_GTC_FAILURE,
    notification: {
      level: "error",
      label: "[action:task>loadAcceptGtc]",
      message: "Impossible de se déconnecter",
      system_message: error.message,
    },
  };
}

function loadAcceptGtcInit(params) {
  return { type: types.ACCEPT_GTC_INIT };
}
