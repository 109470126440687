export const GET_NOTIFICATIONS_INIT = "NOTIFICATIONS:GET_INIT";
export const GET_NOTIFICATIONS_SUCCESS = "NOTIFICATIONS:GET_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "NOTIFICATIONS:GET_FAIL";
export const SET_UNREAD_NOTIFICATIONS = "SET_UNREAD_NOTIFICATIONS";

export const GET_NOTIFICATIONS_MARK_AS_READ_INIT =
  "NOTIFICATIONS_MARK_AS_READ_:GET_INIT";
export const GET_NOTIFICATIONS_MARK_AS_READ_SUCCESS =
  "NOTIFICATIONS_MARK_AS_READ_:GET_SUCCESS";
export const GET_NOTIFICATIONS_MARK_AS_READ_FAILURE =
  "NOTIFICATIONS_MARK_AS_READ_:GET_FAIL";

export const GET_NOTIFICATIONS_MARK_AS_DONE_INIT =
  "NOTIFICATIONS_MARK_AS_DONE_:GET_INIT";
export const GET_NOTIFICATIONS_MARK_AS_DONE_SUCCESS =
  "NOTIFICATIONS_MARK_AS_DONE_:GET_SUCCESS";
export const GET_NOTIFICATIONS_MARK_AS_DONE_FAILURE =
  "NOTIFICATIONS_MARK_AS_DONE_:GET_FAIL";

export const GET_NOTIFICATIONS_DELETE_INIT = "NOTIFICATIONS_DELETE:GET_INIT";
export const GET_NOTIFICATIONS_DELETE_SUCCESS =
  "NOTIFICATIONS_DELETE:GET_SUCCESS";
export const GET_NOTIFICATIONS_DELETE_FAILURE = "NOTIFICATIONS_DELETE:GET_FAIL";

export const GET_NOTIFICATIONS_MARK_ALL_AS_DONE_INIT =
  "NOTIFICATIONS_MARK_ALL_AS_READ_:GET_INIT";
export const GET_NOTIFICATIONS_MARK_ALL_AS_DONE_SUCCESS =
  "NOTIFICATIONS_MARK_ALL_AS_READ_:GET_SUCCESS";
export const GET_NOTIFICATIONS_MARK_ALL_AS_DONE_FAILURE =
  "NOTIFICATIONS_MARK_ALL_AS_READ_:GET_FAIL";
