import React, { useMemo } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

import "./PromotorSelector.scss";
import { Autocomplete } from "@mui/material";

function PromotorSelector({ promotors, onChange, value, onAdd }) {
  const filteredPromotors = useMemo(() => {
    return promotors.filter((promotor) => !promotor.my);
  }, [promotors]);

  const myPromotors = useMemo(() => {
    return promotors.filter((promotor) => promotor.my);
  }, [promotors]);

  const renderPromotor = (promotor) => promotor.name;
  const options = useMemo(() => {
    return [
      ...(onAdd
        ? [
            {
              type: "",
              label: "Add new client",
              value: "add",
              className: "test",
            },
          ]
        : []),
      ...myPromotors.map((promotor) => ({
        type: "My Clients",
        label: renderPromotor(promotor),
        isSec: promotor.sec,
        value: promotor.id,
      })),
      ...filteredPromotors.map((promotor) => ({
        type: "Other Clients",
        label: renderPromotor(promotor),
        isSec: promotor.sec,
        value: promotor.id,
      })),
    ];
  }, [myPromotors, filteredPromotors, onAdd]);

  return (
    <Autocomplete
      className="promotor-selector"
      openOnFocus={true}
      autoHighlight={true}
      options={options}
      renderInput={(params) => (
        <>
          <TextField {...params} size="medium" />
          {promotors?.find((promotor) => promotor.id === value)?.sec && (
            <span className="sec-label">SEC</span>
          )}
        </>
      )}
      renderOption={(props, option, { selected }) => {
        if (option.value === "add") {
          return (
            <li className="add-client-btn-container">
              <Button
                className="btn-add-client"
                variant="contained"
                onClick={onAdd}
              >
                Add new client
              </Button>
            </li>
          );
        }
        return (
          <li {...props}>
            {option.label}{" "}
            {option.isSec && <span className="sec-label">SEC</span>}
          </li>
        );
      }}
      getOptionLabel={(option) => option.label}
      groupBy={(option) => option.type}
      value={{
        value: value,
        label: promotors?.find((promotor) => promotor.id === value)?.name || "",
      }}
      onChange={(event, item) => {
        if (item === null) return;
        onChange(item.value);
      }}
    />
  );
}

PromotorSelector.propTypes = {
  promotors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  onAdd: PropTypes.func,
};

PromotorSelector.defaultProps = {
  promotors: [],
};

export default PromotorSelector;
