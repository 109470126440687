import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import TasksApi from "../../../models/TasksApi";

export function loadTasksByStatus(filters) {
  let action = new ActionRequest(
    TasksApi.loadTasksByStatus,
    loadTasksByStatusInit,
    loadTasksByStatusSuccess,
    loadTasksByStatusFailure
  );
  return action.run({
    routeParameters: { filters },
    actionParameters: { filters },
  });
}

function loadTasksByStatusSuccess(tasksByStatusData, params) {
  return {
    type: types.GET_TASKS_BY_STATUS_SUCCESS,
    data: tasksByStatusData,
  };
}

function loadTasksByStatusFailure(error) {
  return {
    type: types.GET_TASKS_BY_STATUS_FAILURE,
    notification: {
      level: "error",
      label: "[action:tasksByStatus>loadTasksByStatus]",
      message: "Impossible de récupérer la liste des tasksByStatus",
      system_message: error.message,
    },
  };
}

function loadTasksByStatusInit(params) {
  return { type: types.GET_TASKS_BY_STATUS_INIT };
}
