import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import DistributionMatrixApi from "../../../models/DistributionMatrixApi";

export function loadDistributionMatrixAllFilters(filters) {
  let action = new ActionRequest(
    DistributionMatrixApi.loadDistributionMatrixAllFilters,
    loadDistributionMatrixAllFiltersInit,
    loadDistributionMatrixAllFiltersSuccess,
    loadDistributionMatrixAllFiltersFailure
  );
  return action.run({
    routeParameters: { filters },
    actionParameters: { filters },
  });
}

function loadDistributionMatrixAllFiltersSuccess(
  distributionMatrixAllFiltersData,
  params
) {
  return {
    type: types.GET_DISTRIBUTION_MATRIX_ALL_FILTERS_SUCCESS,
    data: distributionMatrixAllFiltersData,
  };
}

function loadDistributionMatrixAllFiltersFailure(error) {
  return {
    type: types.GET_DISTRIBUTION_MATRIX_ALL_FILTERS_FAILURE,
    notification: {
      level: "error",
      label:
        "[action:distributionMatrixAllFilters>loadDistributionMatrixAllFilters]",
      message:
        "Impossible de récupérer la liste des distributionMatrixAllFilters",
      system_message: error.message,
    },
  };
}

function loadDistributionMatrixAllFiltersInit(params) {
  return { type: types.GET_DISTRIBUTION_MATRIX_ALL_FILTERS_INIT };
}
