import * as types from "../constants";

const initialState = {
  isLoading: true,
  error: null,
  items: [],
};

export default function libraryYearsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LIBRARY_YEARS_INIT:
      return { ...state, error: null, isLoading: true };

    case types.GET_LIBRARY_YEARS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.data.data,
        error: action.data.error,
      };

    case types.GET_LIBRARY_YEARS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.notification.system_message,
      };

    default:
      return state;
  }
}
