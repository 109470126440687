export const GET_SUB_FUNDS_BY_FUND_INIT = "SUB_FUNDS_BY_FUND:GET_INIT";
export const GET_SUB_FUNDS_BY_FUND_SUCCESS = "SUB_FUNDS_BY_FUND:GET_SUCCESS";
export const GET_SUB_FUNDS_BY_FUND_FAILURE = "SUB_FUNDS_BY_FUND:GET_FAIL";

export const GET_SUB_FUNDS_LIFE_CYCLE_STATUS_INIT =
  "SUB_FUNDS_LIFE_CYCLE_STATUS:GET_INIT";
export const GET_SUB_FUNDS_LIFE_CYCLE_STATUS_SUCCESS =
  "SUB_FUNDS_LIFE_CYCLE_STATUS:GET_SUCCESS";
export const GET_SUB_FUNDS_LIFE_CYCLE_STATUS_FAILURE =
  "SUB_FUNDS_LIFE_CYCLE_STATUS:GET_FAIL";

export const GET_SUB_FUNDS_CURRENCIES_INIT = "SUB_FUNDS_LIFE_CURRENCY:GET_INIT";
export const GET_SUB_FUNDS_CURRENCIES_SUCCESS =
  "SUB_FUNDS_LIFE_CURRENCY:GET_SUCCESS";
export const GET_SUB_FUNDS_CURRENCIES_FAILURE =
  "SUB_FUNDS_LIFE_CURRENCY:GET_FAIL";
