export const GET_EMAIL_TEMPLATES_INIT = "EMAIL_TEMPLATES:GET_INIT";
export const GET_EMAIL_TEMPLATES_SUCCESS = "EMAIL_TEMPLATES:GET_SUCCESS";
export const GET_EMAIL_TEMPLATES_FAILURE = "EMAIL_TEMPLATES:GET_FAIL";

export const GET_EMAIL_TEMPLATES_TAGS_INIT = "EMAIL_TEMPLATES_TAGS:GET_INIT";
export const GET_EMAIL_TEMPLATES_TAGS_SUCCESS =
  "EMAIL_TEMPLATES_TAGS:GET_SUCCESS";
export const GET_EMAIL_TEMPLATES_TAGS_FAILURE = "EMAIL_TEMPLATES_TAGS:GET_FAIL";

export const GET_EMAIL_TEMPLATES_CLONE_INIT = "EMAIL_TEMPLATES_CLONE:GET_INIT";
export const GET_EMAIL_TEMPLATES_CLONE_SUCCESS =
  "EMAIL_TEMPLATES_CLONE:GET_SUCCESS";
export const GET_EMAIL_TEMPLATES_CLONE_FAILURE =
  "EMAIL_TEMPLATES_CLONE:GET_FAIL";

export const GET_EMAIL_TEMPLATES_DELETE_INIT =
  "EMAIL_TEMPLATES_DELETE:GET_INIT";
export const GET_EMAIL_TEMPLATES_DELETE_SUCCESS =
  "EMAIL_TEMPLATES_DELETE:GET_SUCCESS";
export const GET_EMAIL_TEMPLATES_DELETE_FAILURE =
  "EMAIL_TEMPLATES_DELETE:GET_FAIL";

export const EMAIL_TEMPLATES_DATA_INIT = "EMAIL_TEMPLATES_DATA:GET_INIT";
export const EMAIL_TEMPLATES_DATA_SUCCESS = "EMAIL_TEMPLATES_DATA:GET_SUCCESS";
export const EMAIL_TEMPLATES_DATA_FAILURE = "EMAIL_TEMPLATES_DATA:GET_FAIL";
export const EMAIL_TEMPLATES_DATA_RESET = "EMAIL_TEMPLATES_DATA:GET_RESET";

export const GET_EMAIL_TEMPLATES_ADD_INIT = "EMAIL_TEMPLATES_ADD:GET_INIT";
export const GET_EMAIL_TEMPLATES_ADD_SUCCESS =
  "EMAIL_TEMPLATES_ADD:GET_SUCCESS";
export const GET_EMAIL_TEMPLATES_ADD_FAILURE = "EMAIL_TEMPLATES_ADD:GET_FAIL";

export const GET_EMAIL_TEMPLATES_GET_INIT = "EMAIL_TEMPLATES_GET:GET_INIT";
export const GET_EMAIL_TEMPLATES_GET_SUCCESS =
  "EMAIL_TEMPLATES_GET:GET_SUCCESS";
export const GET_EMAIL_TEMPLATES_GET_FAILURE = "EMAIL_TEMPLATES_GET:GET_FAIL";

export const GET_EMAIL_TEMPLATES_EDIT_INIT = "EMAIL_TEMPLATES_EDIT:GET_INIT";
export const GET_EMAIL_TEMPLATES_EDIT_SUCCESS =
  "EMAIL_TEMPLATES_EDIT:GET_SUCCESS";
export const GET_EMAIL_TEMPLATES_EDIT_FAILURE = "EMAIL_TEMPLATES_EDIT:GET_FAIL";

export const GET_EMAIL_TEMPLATES_TAGS_FILE_INIT =
  "EMAIL_TEMPLATES_TAGS_FILE:GET_INIT";
export const GET_EMAIL_TEMPLATES_TAGS_FILE_SUCCESS =
  "EMAIL_TEMPLATES_TAGS_FILE:GET_SUCCESS";
export const GET_EMAIL_TEMPLATES_TAGS_FILE_FAILURE =
  "EMAIL_TEMPLATES_TAGS_FILE:GET_FAIL";

export const GET_EMAIL_TEMPLATES_GET_TASK_TYPE_INIT =
  "GET_EMAIL_TEMPLATES_GET_TASK_TYPE:GET_INIT";
export const GET_EMAIL_TEMPLATES_GET_TASK_TYPE_SUCCESS =
  "GET_EMAIL_TEMPLATES_GET_TASK_TYPE:GET_SUCCESS";
export const GET_EMAIL_TEMPLATES_GET_TASK_TYPE_FAILURE =
  "GET_EMAIL_TEMPLATES_GET_TASK_TYPE:GET_FAIL";
