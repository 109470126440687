import * as types from "../constants";

const initialState = {
  isLoading: true,
  error: null,
  items: [],
};

export default function createExternalUserReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.GET_CREATE_EXTERNAL_USER_INIT:
      return { ...state, error: null, isLoading: true };

    case types.GET_CREATE_EXTERNAL_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.data.data,
        error: action.data.error,
      };

    case types.GET_CREATE_EXTERNAL_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.notification.system_message,
      };

    default:
      return state;
  }
}
