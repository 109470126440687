import Request from '../utils/requests/request';

export default class AuthentificationApi {

  static isIdentified(params, successCallback, failCallback) {
    const loadReq = new Request(process.env.REACT_APP_API_BASEURL + 'Security/IsAuthenticated');
    return loadReq.get();
  }

  static acceptGtc(params, successCallback, failCallback) {
    const loadReq = new Request(process.env.REACT_APP_API_BASEURL + 'Security/AcceptGtc');
    return loadReq.post();
  }
}
