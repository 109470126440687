import * as types from "../constants";

const initialState = {
  isLoading: true,
  error: null,
  items: [],
};

export default function shareClassesBySubFundByFundReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.GET_SHARE_CLASSES_BY_SUB_FUND_BY_FUND_INIT:
      return { ...state, error: null, isLoading: true };

    case types.GET_SHARE_CLASSES_BY_SUB_FUND_BY_FUND_SUCCESS:
      return { ...state, isLoading: false, items: action.data.data };

    case types.GET_SHARE_CLASSES_BY_SUB_FUND_BY_FUND_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.notification.system_message,
      };

    default:
      return state;
  }
}
