import Request from "../utils/requests/request";

export default class TasksApi {
  static loadTaskTypes() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "TaskTemplate/GetTaskTemplateTypes" +
        (document.URL.indexOf("/admin") >= 0 ? "?Global=true" : "")
    );
    return loadReq.get();
  }

  static loadTasksByStatus(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Dashboard/GetTasksByStatus"
    );
    return loadReq.post(params.filters);
  }

  static loadTasksByType(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Dashboard/GetTasksByType"
    );
    return loadReq.post(params.filters);
  }

  static loadTasks(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/GetTasks"
    );
    return loadReq.post(params.filters);
  }

  static loadTasksDetails(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Tasks/GetTaskDetails?taskId=" +
        params.id
    );
    return loadReq.get();
  }

  static loadSubTasksDetailsForAddingDoc(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Tasks/GetSubTaskDetailsInfoForAddingDoc?subtaskId=" +
        params.id
    );
    return loadReq.get();
  }

  static loadTasksCancel(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Tasks/DisableTask?taskId=" +
        params.id
    );
    return loadReq.post();
  }

  static cancelCountry(taskId, countryId) {
    const request = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Tasks/CancelTaskCountry?taskId=" +
        taskId +
        "&countryId=" +
        countryId
    );
    return request.post();
  }

  static getTaskMailForCountry(taskId, countryId) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Tasks/GetTaskMailForCountry?taskId=" +
        taskId +
        "&countryId=" +
        countryId
    );
    return loadReq.get();
  }

  static getTaskMailAttachment(mailId) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Tasks/GetTaskMailAttachment?mailId=" +
        mailId
    );
    return loadReq.downloadWithGet(null, "attachments.zip");
  }

  static loadTasksDelete(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/DeleteTask?taskId=" + params.id
    );
    return loadReq.post();
  }

  static loadTasksMissingSubfunds(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Tasks/GetSubFundsListMissing?subTaskId=" +
        params.id
    );
    return loadReq.get();
  }

  static loadTasksStatus() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/GetTasksStatuses"
    );
    return loadReq.get();
  }

  static loadTasksAllFilters() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/GetTasksAllFilters"
    );
    return loadReq.get();
  }

  static loadTasksStatusAvailable(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/GetCountersForAllTasks"
    );
    return loadReq.post(params.filters);
  }

  static loadTasksExportExcel(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/GenerateTaskReportAsExcel"
    );
    return loadReq.downloadWithPost(params.filters, params.name);
  }

  static loadTasksDocExportExcel(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/GenerateTaskReport"
    );
    return loadReq.downloadWithPost(params.filters, params.name);
  }

  static loadSubtaskExportExcel(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/GenerateSubtaskReport"
    );
    return loadReq.downloadWithPost(params.subtaskId, params.subtaskName);
  }

  static loadTasksExportPdf(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/GenerateTaskReportAsPdf"
    );
    return loadReq.downloadWithPost(params.filters, params.name);
  }

  static loadTasksAdd(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/CreateTask"
    );
    return loadReq.post(params.task);
  }

  static loadTasksAddDocRequired(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/AddNewDocRequired"
    );
    return loadReq.post(params.doc);
  }

  static loadTasksValidateSubTask(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/ValidateSubTask/" + params.id
    );
    return loadReq.post();
  }

  static loadTasksByTaskType() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/GetTasksByTaskType"
    );
    return loadReq.get();
  }

  static loadTasksGenerateDocument(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Tasks/GenerateSubFundsDocument?subTaskId=" +
        params.id
    );
    return loadReq.post(params.subfunds);
  }

  static loadForceDocCheck(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Tasks/ForceSubTaskDocumentCheck?id=" +
        params.id
    );
    return loadReq.post();
  }

  static loadRemoveDocLink(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/RemoveDocumentTaskLink"
    );
    return loadReq.post(params.ids);
  }

  static loadRemoveDocRequired(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/RemoveRequiredDocument?id="
    );
    return loadReq.post(params.ids);
  }

  static updateTask(id, data) {
    const request = new Request(
      process.env.REACT_APP_API_BASEURL + "Tasks/UpdateTask/" + id
    );
    return request.put(data);
  }
  static reactivateTask(taskId) {
    const request = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Tasks/ReactivateTask?taskId=" +
        taskId
    );
    return request.post();
  }
}
