export const GET_ENTITIES_INIT = "ENTITIES:GET_INIT";
export const GET_ENTITIES_SUCCESS = "ENTITIES:GET_SUCCESS";
export const GET_ENTITIES_FAILURE = "ENTITIES:GET_FAIL";

export const GET_ACTIVITIES_INIT = "ACTIVITIES:GET_INIT";
export const GET_ACTIVITIES_SUCCESS = "ACTIVITIES:GET_SUCCESS";
export const GET_ACTIVITIES_FAILURE = "ACTIVITIES:GET_FAIL";

export const GET_ACTIVITIES_BY_ENTITY_INIT = "ACTIVITIES_BY_ENTITY:GET_INIT";
export const GET_ACTIVITIES_BY_ENTITY_SUCCESS =
  "ACTIVITIES_BY_ENTITY:GET_SUCCESS";
export const GET_ACTIVITIES_BY_ENTITY_FAILURE = "ACTIVITIES_BY_ENTITY:GET_FAIL";

export const GET_ACTIVITIES_BY_ENTITY_FOR_PROMOTOR_INIT =
  "ACTIVITIES_BY_ENTITY_FOR_PROMOTOR:GET_INIT";
export const GET_ACTIVITIES_BY_ENTITY_FOR_PROMOTOR_SUCCESS =
  "ACTIVITIES_BY_ENTITY_FOR_PROMOTOR:GET_SUCCESS";
export const GET_ACTIVITIES_BY_ENTITY_FOR_PROMOTOR_FAILURE =
  "ACTIVITIES_BY_ENTITY_FOR_PROMOTOR:GET_FAIL";

export const GET_ACTIVITIES_ADD_INIT = "ACTIVITIES_ADD:GET_INIT";
export const GET_ACTIVITIES_ADD_SUCCESS = "ACTIVITIES_ADD:GET_SUCCESS";
export const GET_ACTIVITIES_ADD_FAILURE = "ACTIVITIES_ADD:GET_FAIL";

export const GET_ACTIVITIES_ADD_FOR_PROMOTOR_INIT =
  "ACTIVITIES_ADD_FOR_PROMOTOR:GET_INIT";
export const GET_ACTIVITIES_ADD_FOR_PROMOTOR_SUCCESS =
  "ACTIVITIES_ADD_FOR_PROMOTOR:GET_SUCCESS";
export const GET_ACTIVITIES_ADD_FOR_PROMOTOR_FAILURE =
  "ACTIVITIES_ADD_FOR_PROMOTOR:GET_FAIL";

export const GET_ACTIVITIES_DELETE_FOR_PROMOTOR_INIT =
  "ACTIVITIES_DELETE_FOR_PROMOTOR:GET_INIT";
export const GET_ACTIVITIES_DELETE_FOR_PROMOTOR_SUCCESS =
  "ACTIVITIES_DELETE_FOR_PROMOTOR:GET_SUCCESS";
export const GET_ACTIVITIES_DELETE_FOR_PROMOTOR_FAILURE =
  "ACTIVITIES_DELETE_FOR_PROMOTOR:GET_FAIL";

export const GET_ACTIVITIES_DELETE_INIT = "ACTIVITIES_DELETE:GET_INIT";
export const GET_ACTIVITIES_DELETE_SUCCESS = "ACTIVITIES_DELETE:GET_SUCCESS";
export const GET_ACTIVITIES_DELETE_FAILURE = "ACTIVITIES_DELETE:GET_FAIL";

export const GET_ENTITIES_CREATE_INIT = "ENTITIES_CREATE:GET_INIT";
export const GET_ENTITIES_CREATE_SUCCESS = "ENTITIES_CREATE:GET_SUCCESS";
export const GET_ENTITIES_CREATE_FAILURE = "ENTITIES_CREATE:GET_FAIL";

export const GET_ENTITIES_CREATE_CONTACT_INIT =
  "ENTITIES_CREATE_CONTACT:GET_INIT";
export const GET_ENTITIES_CREATE_CONTACT_SUCCESS =
  "ENTITIES_CREATE_CONTACT:GET_SUCCESS";
export const GET_ENTITIES_CREATE_CONTACT_FAILURE =
  "ENTITIES_CREATE_CONTACT:GET_FAIL";

export const GET_ENTITIES_DELETE_CONTACT_INIT =
  "ENTITIES_DELETE_CONTACT:GET_INIT";
export const GET_ENTITIES_DELETE_CONTACT_SUCCESS =
  "ENTITIES_DELETE_CONTACT:GET_SUCCESS";
export const GET_ENTITIES_DELETE_CONTACT_FAILURE =
  "ENTITIES_DELETE_CONTACT:GET_FAIL";

export const GET_ENTITIES_GET_CONTACT_INIT = "ENTITIES_GET_CONTACT:GET_INIT";
export const GET_ENTITIES_GET_CONTACT_SUCCESS =
  "ENTITIES_GET_CONTACT:GET_SUCCESS";
export const GET_ENTITIES_GET_CONTACT_FAILURE = "ENTITIES_GET_CONTACT:GET_FAIL";

export const GET_ENTITIES_CREATE_FOR_PROMOTOR_INIT =
  "ENTITIES_CREATE_FOR_PROMOTOR:GET_INIT";
export const GET_ENTITIES_CREATE_FOR_PROMOTOR_SUCCESS =
  "ENTITIES_CREATE_FOR_PROMOTOR:GET_SUCCESS";
export const GET_ENTITIES_CREATE_FOR_PROMOTOR_FAILURE =
  "ENTITIES_CREATE_FOR_PROMOTOR:GET_FAIL";

export const GET_ENTITIES_FOR_PROMOTOR_INIT = "ENTITIES_FOR_PROMOTOR:GET_INIT";
export const GET_ENTITIES_FOR_PROMOTOR_SUCCESS =
  "ENTITIES_FOR_PROMOTOR:GET_SUCCESS";
export const GET_ENTITIES_FOR_PROMOTOR_FAILURE =
  "ENTITIES_FOR_PROMOTOR:GET_FAIL";

export const GET_ENTITIES_DELETE_INIT = "ENTITIES_DELETE:GET_INIT";
export const GET_ENTITIES_DELETE_SUCCESS = "ENTITIES_DELETE:GET_SUCCESS";
export const GET_ENTITIES_DELETE_FAILURE = "ENTITIES_DELETE:GET_FAIL";

export const GET_ENTITIES_DELETE_FOR_PROMOTOR_INIT =
  "ENTITIES_DELETE_FOR_PROMOTOR:GET_INIT";
export const GET_ENTITIES_DELETE_FOR_PROMOTOR_SUCCESS =
  "ENTITIES_DELETE_FOR_PROMOTOR:GET_SUCCESS";
export const GET_ENTITIES_DELETE_FOR_PROMOTOR_FAILURE =
  "ENTITIES_DELETE_FOR_PROMOTOR:GET_FAIL";

export const GET_ENTITIES_DETAILS_INIT = "ENTITIES_DETAILS:GET_INIT";
export const GET_ENTITIES_DETAILS_SUCCESS = "ENTITIES_DETAILS:GET_SUCCESS";
export const GET_ENTITIES_DETAILS_FAILURE = "ENTITIES_DETAILS:GET_FAIL";

export const GET_ENTITIES_EDIT_INIT = "ENTITIES_EDIT:GET_INIT";
export const GET_ENTITIES_EDIT_SUCCESS = "ENTITIES_EDIT:GET_SUCCESS";
export const GET_ENTITIES_EDIT_FAILURE = "ENTITIES_EDIT:GET_FAIL";

export const GET_LEGAL_FORM_INIT = "LEGAL_FORM:GET_INIT";
export const GET_LEGAL_FORM_SUCCESS = "LEGAL_FORM:GET_SUCCESS";
export const GET_LEGAL_FORM_FAILURE = "LEGAL_FORM:GET_FAIL";

export const GET_ENTITIES_DUE_DILIGENCE_RATINGS_INIT = "ENTITIES_GET_DUE_DILIGENCE_RATINGS:GET_INIT";
export const GET_ENTITIES_DUE_DILIGENCE_RATINGS_SUCCESS = "ENTITIES_GET_DUE_DILIGENCE_RATINGS:GET_SUCCESS";
export const GET_ENTITIES_DUE_DILIGENCE_RATINGS_FAILURE = "ENTITIES_GET_DUE_DILIGENCE_RATINGS:GET_FAIL";

export const GET_ENTITIES_FOR_PROMOTOR_DUE_DILIGENCE_RATINGS_INIT = "ENTITIES_FOR_PROMOTOR_DUE_DILIGENCE_RATINGS:GET_INIT";
export const GET_ENTITIES_FOR_PROMOTOR_DUE_DILIGENCE_RATINGS_SUCCESS =
  "ENTITIES_FOR_PROMOTOR_DUE_DILIGENCE_RATINGS:GET_SUCCESS";
export const GET_ENTITIES_FOR_PROMOTOR_DUE_DILIGENCE_RATINGS_FAILURE =
  "ENTITIES_FOR_PROMOTOR_DUE_DILIGENCE_RATINGS:GET_FAIL";

export const CREATE_OR_UPDATE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS_INIT = "CREATE_OR_UPDATE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS:GET_INIT";
export const CREATE_OR_UPDATE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS_SUCCESS =
  "CREATE_OR_UPDATE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS:GET_SUCCESS";
export const CREATE_OR_UPDATE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS_FAILURE =
  "CREATE_OR_UPDATE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS:GET_FAIL";

export const DELETE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS_INIT = "DELETE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS:GET_INIT";
export const DELETE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS_SUCCESS = "DELETE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS:GET_SUCCESS";
export const DELETE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS_FAILURE = "DELETE_ENTITY_PROMOTOR_DUE_DILIGENCE_RATINGS:GET_FAIL";
