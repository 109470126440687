import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import CountriesApi from "../../../models/CountriesApi";

export function loadCountries() {
  let action = new ActionRequest(
    CountriesApi.loadCountries,
    loadCountriesInit,
    loadCountriesSuccess,
    loadCountriesFailure
  );
  return action.run({
    routeParameters: {},
    actionParameters: {},
  });
}

function loadCountriesSuccess(countriesData, params) {
  return {
    type: types.GET_COUNTRIES_SUCCESS,
    data: countriesData,
  };
}

function loadCountriesFailure(error) {
  return {
    type: types.GET_COUNTRIES_FAILURE,
    notification: {
      level: "error",
      label: "[action:countries>loadCountries]",
      message: "Impossible de récupérer la liste des countries",
      system_message: error.message,
    },
  };
}

function loadCountriesInit(params) {
  return { type: types.GET_COUNTRIES_INIT };
}
