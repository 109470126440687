import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import NotificationsApi from "../../../models/NotificationsApi";

export function loadNotifications() {
  let action = new ActionRequest(
    NotificationsApi.loadNotifications,
    loadNotificationsInit,
    loadNotificationsSuccess,
    loadNotificationsFailure
  );
  return action.run({
    routeParameters: {},
    actionParameters: {},
  });
}

function loadNotificationsSuccess(notificationsData, params) {
  return {
    type: types.GET_NOTIFICATIONS_SUCCESS,
    data: notificationsData,
  };
}

function loadNotificationsFailure(error) {
  return {
    type: types.GET_NOTIFICATIONS_FAILURE,
    notification: {
      level: "error",
      label: "[action:notifications>loadNotifications]",
      message: "Impossible de récupérer la liste des notifications",
      system_message: error.message,
    },
  };
}

function loadNotificationsInit(params) {
  return { type: types.GET_NOTIFICATIONS_INIT };
}

export function setUnreadNotificationsNumber(number) {
  return { type: types.SET_UNREAD_NOTIFICATIONS, payload: number };
}
