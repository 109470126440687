const facilitiesAgent = {
  __SUBMIT_A_REQUEST__: {
    en: "Submit a request",
    no: "Send inn en forespørsel",
    is: "Senda inn beiðni",
    sv: "Skicka en fråga",
    es: "Presentar una solicitud",
    sl: "Oddajte zahtevo",
    sk: "Odoslať žiadosť",
    ro: "Trimite o cerere",
    pt: "Envie uma solicitação",
    pl: "Złóż wniosek",
    nl: "Dien een aanvraag in",
    mt: "Ibgħat talba",
    lt: "Pateikti prašymą",
    lv: "Iesniedziet pieprasījumu",
    it: "Invia una richiesta",
    hu: "Kérelem benyújtása",
    fr: "Soumettre une demande",
    fi: "Lähetä pyyntö",
    et: "Saada päring",
    da: "Send en forespørgsel",
    cs: "Odeslat žádost",
    el: "Υποβάλετε ένα αίτημα",
    hr: "Pošaljite zahtjev",
    bg: "Изпратете заявка",
    de: "Eine Anfrage einreichen",
  },
  __YOUR_EMAIL__: {
    en: "Your email",
    no: "E-posten din",
    is: "Netfangið þitt",
    sv: "Din e-postadress",
    es: "Su email",
    sl: "Vaš e-poštni naslov",
    sk: "Tvoj email",
    ro: "Email-ul tau",
    pt: "Seu email",
    pl: "E-mail",
    nl: "Uw email",
    mt: "L-email tieghek",
    lt: "Tavo elektroninis paštas",
    lv: "Tavs e-pasts",
    it: "La vostra email",
    hu: "Az email címed",
    fr: "Votre e-mail",
    fi: "Sähköpostisi",
    et: "Sinu email",
    da: "Din e-mail",
    cs: "Tvůj e-mail",
    el: "Η διεύθυνση του ηλεκτρονικού σου ταχυδρομείου",
    hr: "Tvoj email",
    bg: "Твоят имейл",
    de: "Ihre E-Mail",
  },
  __ISIN_OR_SUBFUND__: {
    en: "ISIN or sub-fund name (optional)",
    no: "ISIN eller navn på underfond (valgfritt)",
    is: "ISIN eða heiti undirsjóðs (valfrjálst)",
    sv: "ISIN eller delfondens namn (valfritt)",
    es: "ISIN o nombre del subfondo (opcional)",
    sl: "ISIN ali ime podsklada (neobvezno)",
    sk: "ISIN alebo názov podfondu (voliteľné)",
    ro: "ISIN sau numele subfondului (opțional)",
    pt: "ISIN ou nome do subfundo (opcional)",
    pl: "Kod ISIN lub nazwa subfunduszu (opcjonalnie)",
    nl: "ISIN- of subfondsnaam (optioneel)",
    mt: "ISIN jew isem tas-subfond (mhux obbligatorju)",
    lt: "ISIN arba subfondo pavadinimas (neprivaloma)",
    lv: "ISIN vai apakšfonda nosaukums (pēc izvēles)",
    it: "ISIN o nome del comparto (facoltativo)",
    hu: "ISIN vagy részalap neve (opcionális)",
    fr: "ISIN ou nom du compartiment (facultatif)",
    fi: "ISIN tai alarahaston nimi (valinnainen)",
    et: "ISIN või allfondi nimi (valikuline)",
    da: "ISIN eller afdelingsnavn (valgfrit)",
    cs: "ISIN nebo název podfondu (volitelné)",
    el: "ISIN ή όνομα υποκεφαλαίου (προαιρετικό)",
    hr: "ISIN ili naziv podfonda (izborno)",
    bg: "ISIN или име на подфонд (по избор)",
    de: "ISIN oder Name des Teilfonds (optional)",
  },
  __DOCUMENT_REQUEST__: {
    en: "Document request",
    no: "Dokumentforespørsel",
    is: "Skjalabeiðni",
    sv: "Begäran om dokument",
    es: "Solicitud de documento",
    sl: "Zahteva za dokument",
    sk: "Žiadosť o dokument",
    ro: "Cerere document",
    pt: "Solicitação de documento",
    pl: "Wnioskowane dokumenty",
    nl: "Documentaanvraag",
    mt: "Talba għal dokument",
    lt: "Dokumento prašymas",
    lv: "Dokumentu pieprasījums",
    it: "Richiesta di documenti",
    hu: "Dokumentumkérés",
    fr: "Demande de documents",
    fi: "Asiakirjapyyntö",
    et: "Dokumendi taotlus",
    da: "Dokumentanmodning",
    cs: "Žádost o dokument",
    el: "Αίτημα εγγράφου",
    hr: "Zahtjev za dokument",
    bg: "Искане за документ",
    de: "Anforderung von Dokumenten",
  },
  __INFORMATION_REQUEST__: {
    en: "Information request",
    no: "Informasjonsforespørsel",
    is: "Upplýsingabeiðni",
    sv: "Begäran om information",
    es: "Solicitud de información",
    sl: "Zahtevek za informacije",
    sk: "Žiadosť o informácie",
    ro: "Cerere de informatie",
    pt: "Pedido de informação",
    pl: "Wnioskowane informacje",
    nl: "Informatieaanvraag",
    mt: "Talba ta' informazzjoni",
    lt: "Informacijos užklausa",
    lv: "Informācijas pieprasījums",
    it: "Richiesta di informazioni",
    hu: "Információkérés",
    fr: "Demande d'information",
    fi: "Informaatio pyyntö",
    et: "Infonõue",
    da: "Anmodning om oplysninger",
    cs: "Žádost o informace",
    el: "Αίτηση για πληροφορίες",
    hr: "Zahtjev za informacijama",
    bg: "Искане на информация",
    de: "Informationsanfrage",
  },
  __COMPLAINT__: {
    en: "Complaint",
    no: "Klage",
    is: "Ákæra",
    sv: "Klagomål",
    es: "Reclamación",
    sl: "Pritožba",
    sk: "Sťažnosť",
    ro: "Plângere",
    pt: "Reclamação",
    pl: "Skarga",
    nl: "Klacht",
    mt: "Ilment",
    lt: "Skundas",
    lv: "Sūdzība",
    it: "Reclamo",
    hu: "Panasz",
    fr: "Plainte",
    fi: "Valitus",
    et: "Kaebus",
    da: "Klage",
    cs: "Stížnost",
    el: "Καταγγελία",
    hr: "Pritužba",
    bg: "жалба",
    de: "Beschwerde",
  },
  __OTHER__: {
    en: "Other",
    no: "Annet",
    is: "Annað",
    sv: "Övrigt",
    es: "Otra",
    sl: "Drugo",
    sk: "Iné",
    ro: "Alte",
    pt: "De outros",
    pl: "Inne",
    nl: "Ander",
    mt: "Oħrajn",
    lt: "Kita",
    lv: "Cits",
    it: "Altro",
    hu: "Egyéb",
    fr: "Autre",
    fi: "Muut",
    et: "Muud",
    da: "Andet",
    cs: "jiný",
    el: "Αλλα",
    hr: "Ostalo",
    bg: "Друго",
    de: "Andere",
  },
  __DOCUMENT_TYPES__: {
    en: "Document types",
    no: "Dokumenttyper",
    is: "Skjalagerðir",
    sv: "Dokumenttyper",
    es: "Tipos de documento",
    sl: "Vrste dokumentov",
    sk: "Typy dokumentov",
    ro: "Tipuri de documente",
    pt: "Tipos de documentos",
    pl: "Rodzaje dokumentów",
    nl: "Documenttypen",
    mt: "Tipi ta' dokumenti",
    lt: "Dokumentų tipai",
    lv: "Dokumentu veidi",
    it: "Tipi di Documenti",
    hu: "Dokumentumtípusok",
    fr: "Types de documents",
    fi: "Asiakirjatyypit",
    et: "Dokumendi tüübid",
    da: "Dokumenttyper",
    cs: "Typy dokumentů",
    el: "Τύποι εγγράφων",
    hr: "Vrste dokumenata",
    bg: "Видове документи",
    de: "Dokumenttypen",
  },
  __ANNUAL_REPORT__: {
    en: "Annual report",
    no: "Årsberetning",
    is: "Ársskýrsla",
    sv: "Årsrapport",
    es: "Informe anual",
    sl: "Letno poročilo",
    sk: "Výročná správa",
    ro: "Raport anual",
    pt: "Relatório anual",
    pl: "Raport roczny",
    nl: "Jaarverslag",
    mt: "Rapport annwali",
    lt: "Metinis pranešimas",
    lv: "Ikgadējā atskaite",
    it: "Relazione annuale",
    hu: "Éves jelentés",
    fr: "Rapport annuel",
    fi: "Vuosikatsaus",
    et: "Aasta raport",
    da: "Årsrapport",
    cs: "Výroční zpráva",
    el: "Ετήσια Έκθεση",
    hr: "Godišnji izvještaj",
    bg: "Годишен доклад",
    de: "Jahresbericht",
  },
  __SEMI_ANNUAL_REPORT__: {
    en: "Semi annual report",
    no: "Halvårsberetning",
    is: "Árshelmingaskýrsla",
    sv: "Halvårsrapport",
    es: "Informe semestral",
    sl: "Polletno poročilo",
    sk: "Polročná správa",
    ro: "Raport semestrial",
    pt: "Relatório semestral",
    pl: "Raport półroczny",
    nl: "Halfjaarverslag",
    mt: "Rapport semi-annwali",
    lt: "Pusmečio ataskaita",
    lv: "Pusgada pārskats",
    it: "Relazione semestrale",
    hu: "Féléves jelentés",
    fr: "Rapport semestriel",
    fi: "Puolivuosikertomus",
    et: "Poolaasta aruanne",
    da: "Halvårsrapport",
    cs: "Pololetní zpráva",
    el: "Εξαμηνιαία έκθεση",
    hr: "Polugodišnji izvještaj",
    bg: "Полугодишен отчет",
    de: "Halbjahresbericht",
  },
  __DOCUMENTS_INCORPORATION__: {
    en: "Documents of incorporation",
    no: "Dokumentasjon på innlemmelse",
    is: "Stofngögn",
    sv: "Dokument om införlivande",
    es: "Estatutos de la sociedad",
    sl: "Ustanovni dokumenti",
    sk: "Zakladateľské dokumenty",
    ro: "Actele de constituire",
    pt: "Documentos de incorporação",
    pl: "Świadectwo zarejestrowania spółki / Akt zawiązania spółki",
    nl: "Oprichtingsdocumenten",
    mt: "Dokumenti ta' inkorporazzjoni",
    lt: "Steigimo dokumentai",
    lv: "Dibināšanas dokumenti",
    it: "Documenti di costituzione",
    hu: "Alapító okiratok",
    fr: "Documents de constitution",
    fi: "Perustamisasiakirjat",
    et: "Asutamisdokumendid",
    da: "Stiftelsesdokumenter",
    cs: "Zakládací listiny",
    el: "Έγγραφα σύστασης",
    hr: "Dokumenti o osnivanju",
    bg: "Документи за учредяване",
    de: "Gründungsurkunden",
  },
  __PROSPECTUS__: {
    en: "Prospectus",
    no: "Prospekt",
    is: "Útboðslýsing",
    sv: "Prospekt",
    es: "Folleto",
    sl: "Prospekt",
    sk: "Prospekt",
    ro: "Prospect",
    pt: "Prospecto",
    pl: "Prospekt",
    nl: "Prospectus",
    mt: "Prospett",
    lt: "Prospektas",
    lv: "Prospekts",
    it: "Prospetto",
    hu: "Tájékoztató",
    fr: "Prospectus",
    fi: "Esite",
    et: "Prospekt",
    da: "Prospekt",
    cs: "Prospekt",
    el: "Πρόγραμμα",
    hr: "Prospekt",
    bg: "Проспект",
    de: "Prospekt",
  },
  __KIID__: {
    en: "KIID",
    no: "KIID",
    is: "Lykilupplýsingar fyrir fjárfesta",
    sv: "KIID",
    es: "KIID",
    sl: "KIID",
    sk: "KIID",
    ro: "KIID",
    pt: "KIID",
    pl: "KIID",
    nl: "KIID",
    mt: "KIID",
    lt: "KIID",
    lv: "KIID",
    it: "KIID",
    hu: "KIID",
    fr: "DICI",
    fi: "KIID",
    et: "KIID",
    da: "KIID",
    cs: "KIID",
    el: "KIID",
    hr: "KIID",
    bg: "KIID",
    de: "KIID",
  },
  __REQUEST__: {
    en: "Request",
    no: "Forespørsel",
    is: "Biðja um",
    sv: "Begäran",
    es: "Solicitud",
    sl: "Prošnja",
    sk: "Žiadosť",
    ro: "Cerere",
    pt: "Solicitar",
    pl: "Wniosek",
    nl: "Verzoek",
    mt: "Talba",
    lt: "Prašymas",
    lv: "Pieprasīt",
    it: "Richiesta",
    hu: "Kérés",
    fr: "Demande",
    fi: "Pyyntö",
    et: "Taotlus",
    da: "Anmodning",
    cs: "Žádost",
    el: "Αίτηση",
    hr: "Zahtjev",
    bg: "Заявка",
    de: "Anfrage",
  },
  __SUBJECT__: {
    en: "Subject",
    no: "Emne",
    is: "Efnislína",
    sv: "Ämne",
    es: "Asunto",
    sl: "Zadeva",
    sk: "Predmet",
    ro: "Subiect",
    pt: "Sujeita",
    pl: "Temat wniosku",
    nl: "Onderwerp",
    mt: "Suġġett",
    lt: "Tema",
    lv: "Priekšmets",
    it: "Oggetto",
    hu: "Tantárgy",
    fr: "Sujet",
    fi: "Aihe",
    et: "Teema",
    da: "Emne",
    cs: "Předmět",
    el: "Θέμα",
    hr: "Predmet",
    bg: "Предмет",
    de: "Gegenstand",
  },
  __CAPTCHA_UNREADABLE__: {
    en: "Is this captcha unreadable? Click here to request a new Captcha image",
    no: "Er captcha-oppgaven uleselig? Klikk her for å be om et nytt captcha-bilde",
    is: "Er þessi þraut ólæsileg? Smelltu hér til að biðja um nýja þrautarmynd",
    sv: "Kan du inte läsa captchan? Klicka här för att begära en ny Captcha-bild",
    es: "¿Este captcha es difícil de leer? Haga clic aquí para solicitar una nueva imagen Captcha",
    sl: "Ali je ta captcha neberljiva? Kliknite tukaj, če želite zahtevati novo sliko Captcha",
    sk: "Je tento captcha nečitateľný? Kliknite sem a požiadajte o nový obrázok Captcha",
    ro: "Acest captcha este ilizibil? Faceți clic aici pentru a solicita o nouă imagine Captcha",
    pt: "Este captcha é ilegível? Clique aqui para solicitar uma nova imagem Captcha",
    pl: "Czy ta Captcha jest nieczytelna? Proszę kliknąć tutaj, aby wygenerować nowy obraz Captcha",
    nl: "Is deze captcha onleesbaar? Klik hier om een ​​nieuwe Captcha-afbeelding aan te vragen",
    mt: "Din il-captcha ma tistax tinqara? Ikklikkja hawn biex titlob immaġni Captcha ġdida",
    lt: "Ar šis captcha neįskaitomas? Spustelėkite čia, jei norite paprašyti naujo „Captcha“ vaizdo",
    lv: "Vai šī captcha nav nolasāma? Noklikšķiniet šeit, lai pieprasītu jaunu Captcha attēlu",
    it: "Questo captcha è illeggibile? Fare clic qui per richiedere una nuova immagine Captcha",
    hu: "Ez a captcha olvashatatlan? Kattintson ide új Captcha-kép kéréséhez",
    fr: "Ce captcha est-il illisible ? Cliquez ici pour demander une nouvelle image Captcha",
    fi: "Onko tämä captcha lukukelvoton? Napsauta tätä pyytääksesi uutta Captcha-kuvaa",
    et: "Kas see captcha on loetamatu? Uue Captcha pildi taotlemiseks klõpsake siin",
    da: "Er denne captcha ulæselig? Klik her for at anmode om et nyt Captcha-billede",
    cs: "Je tato captcha nečitelná? Kliknutím sem požádáte o nový obrázek Captcha",
    el: "Είναι αυτό το captcha μη αναγνώσιμο; Κάντε κλικ εδώ για να ζητήσετε μια νέα εικόνα Captcha",
    hr: "Je li ovaj captcha nečitljiv? Kliknite ovdje da biste zatražili novu Captcha sliku",
    bg: "Това captcha не може да се чете? Щракнете тук, за да заявите ново изображение Captcha",
    de: "Ist dieses Captcha nicht lesbar? Klicken Sie hier, um ein neues Captcha-Bild anzufordern",
  },
  __CAPTCHA_EXPIRED__: {
    en: "CAPTCHA expired, try again",
    no: "CAPTCHA expired, try again",
    is: "CAPTCHA expired, try again",
    sv: "CAPTCHA expired, try again",
    es: "CAPTCHA expired, try again",
    sl: "CAPTCHA expired, try again",
    sk: "CAPTCHA expired, try again",
    ro: "CAPTCHA expired, try again",
    pt: "CAPTCHA expired, try again",
    pl: "CAPTCHA expired, try again",
    nl: "CAPTCHA expired, try again",
    mt: "CAPTCHA expired, try again",
    lt: "CAPTCHA expired, try again",
    lv: "CAPTCHA expired, try again",
    it: "CAPTCHA expired, try again",
    hu: "CAPTCHA expired, try again",
    fr: "CAPTCHA expired, try again",
    fi: "CAPTCHA expired, try again",
    et: "CAPTCHA expired, try again",
    da: "CAPTCHA expired, try again",
    cs: "CAPTCHA expired, try again",
    el: "CAPTCHA expired, try again",
    hr: "CAPTCHA expired, try again",
    bg: "CAPTCHA expired, try again",
    de: "CAPTCHA expired, try again",
  },
  __PLEASE_CHECK_CAPTCHA__: {
    en: "Please check your captcha input",
    no: "Please check your captcha input",
    is: "Please check your captcha input",
    sv: "Please check your captcha input",
    es: "Please check your captcha input",
    sl: "Please check your captcha input",
    sk: "Please check your captcha input",
    ro: "Please check your captcha input",
    pt: "Please check your captcha input",
    pl: "Please check your captcha input",
    nl: "Please check your captcha input",
    mt: "Please check your captcha input",
    lt: "Please check your captcha input",
    lv: "Please check your captcha input",
    it: "Please check your captcha input",
    hu: "Please check your captcha input",
    fr: "Please check your captcha input",
    fi: "Please check your captcha input",
    et: "Please check your captcha input",
    da: "Please check your captcha input",
    cs: "Please check your captcha input",
    el: "Please check your captcha input",
    hr: "Please check your captcha input",
    bg: "Please check your captcha input",
    de: "Please check your captcha input",
  },
  __INVALID_CAPTCHA__: {
    en: "Invalid CAPTCHA",
    no: "Invalid CAPTCHA",
    is: "Invalid CAPTCHA",
    sv: "Invalid CAPTCHA",
    es: "Invalid CAPTCHA",
    sl: "Invalid CAPTCHA",
    sk: "Invalid CAPTCHA",
    ro: "Invalid CAPTCHA",
    pt: "Invalid CAPTCHA",
    pl: "Invalid CAPTCHA",
    nl: "Invalid CAPTCHA",
    mt: "Invalid CAPTCHA",
    lt: "Invalid CAPTCHA",
    lv: "Invalid CAPTCHA",
    it: "Invalid CAPTCHA",
    hu: "Invalid CAPTCHA",
    fr: "Invalid CAPTCHA",
    fi: "Invalid CAPTCHA",
    et: "Invalid CAPTCHA",
    da: "Invalid CAPTCHA",
    cs: "Invalid CAPTCHA",
    el: "Invalid CAPTCHA",
    hr: "Invalid CAPTCHA",
    bg: "Invalid CAPTCHA",
    de: "Invalid CAPTCHA",
  },
  __REQUEST_SUCCESS__: {
    en: "Your request was successfully submitted",
    no: "Forespørselen din har blitt sendt inn",
    is: "Beiðnin var send inn",
    sv: "Din fråga har skickats",
    es: "Su solicitud se ha enviado correctamente",
    sl: "Vaša zahteva je bila uspešno poslana",
    sk: "Vaša žiadosť bola úspešne odoslaná",
    ro: "Solicitarea dvs. a fost trimisă cu succes",
    pt: "Sua solicitação foi enviada com sucesso",
    pl: "Wniosek został pomyślnie przesłany",
    nl: "Uw aanvraag is succesvol ingediend",
    mt: "It-talba tiegħek intbagħtet b'suċċess",
    lt: "Jūsų užklausa sėkmingai pateikta",
    lv: "Jūsu pieprasījums tika veiksmīgi iesniegts",
    it: "La vostra richiesta è stata inoltrata con successo",
    hu: "Kérelmét sikeresen elküldtük",
    fr: "Votre demande a été soumise avec succès",
    fi: "Pyyntösi lähetettiin onnistuneesti",
    et: "Teie taotlus esitati edukalt",
    da: "Din anmodning blev sendt",
    cs: "Vaše žádost byla úspěšně odeslána",
    el: "Το αίτημά σας υποβλήθηκε με επιτυχία",
    hr: "Vaš zahtjev je uspješno poslan",
    bg: "Заявката ви беше изпратена успешно",
    de: "Ihre Anfrage wurde erfolgreich übermittelt",
  },
  __REQUEST_SUCCESS_SUBTITLE__: {
    en: "We will get back to you as soon as possible.",
    no: "Vi tar kontakt med deg så snart som mulig",
    is: "Við höfum samband eins fljótt og auðið er",
    sv: "Vi kontaktar dig så fort som möjligt",
    es: "Nos pondremos en contacto con usted tan pronto como sea posible",
    sl: "Odgovorili vam bomo v najkrajšem možnem času",
    sk: "Čo najskôr sa vám ozveme",
    ro: "Vă vom reveni cât mai curând posibil",
    pt: "Retornaremos o mais breve possível",
    pl: "Odezwiemy się tak szybko, jak to możliwe",
    nl: "We nemen zo snel mogelijk contact met u op",
    mt: "Aħna ser nerġgħu lura lilek malajr kemm jista 'jkun",
    lt: "Su jumis susisieksime kuo greičiau",
    lv: "Mēs sazināsimies ar jums, cik drīz vien iespējams",
    it: "Vi risponderemo il prima possibile",
    hu: "A lehető leghamarabb visszakeresünk",
    fr: "Nous vous répondrons dans les plus brefs délais",
    fi: "Palaamme sinulle mahdollisimman pian",
    et: "Võtame teiega esimesel võimalusel ühendust",
    da: "Vi vender tilbage til dig hurtigst muligt",
    cs: "Ozveme se vám co nejdříve",
    el: "Θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατό",
    hr: "Javit ćemo Vam se u najkraćem mogućem roku",
    bg: "Ще се свържем с вас възможно най-скоро",
    de: "Wir werden uns schnellstmöglich bei Ihnen melden",
  },
  __ERROR_LOADING_FORM__: {
    en: "An error occurred while loading this form",
    no: "En feil oppstod under innlasting av dette skjemaet",
    is: "Villa kom upp þegar verið var að hlaða þessu eyðublaði",
    sv: "Det uppstod ett fel när formuläret laddades",
    es: "Ocurrió un error al cargar este formulario",
    sl: "Pri nalaganju tega obrazca je prišlo do napake",
    sk: "Pri načítavaní tohto formulára sa vyskytla chyba",
    ro: "A apărut o eroare la încărcarea acestui formular",
    pt: "Ocorreu um erro ao carregar este formulário",
    pl: "Wystąpił błąd podczas ładowania tego formularza",
    nl: "Er is een fout opgetreden bij het laden van dit formulier",
    mt: "Sar żball waqt it-tagħbija ta' din il-formola",
    lt: "Įkeliant šią formą įvyko klaida",
    lv: "Ielādējot šo veidlapu, radās kļūda",
    it: "C'è stato un errore durante il caricamento del modulo",
    hu: "Hiba történt az űrlap betöltése közben",
    fr: "Une erreur s'est produite lors du chargement de ce formulaire",
    fi: "Tätä lomaketta ladattaessa tapahtui virhe",
    et: "Selle vormi laadimisel ilmnes viga",
    da: "Der opstod en fejl under indlæsning af denne formular",
    cs: "Při načítání tohoto formuláře došlo k chybě",
    el: "Παρουσιάστηκε σφάλμα κατά τη φόρτωση αυτής της φόρμας",
    hr: "Došlo je do pogreške prilikom učitavanja ovog obrasca",
    bg: "Възникна грешка при зареждането на този формуляр",
    de: "Beim Laden dieses Formulars ist ein Fehler aufgetreten",
  },
  __TRY_AGAIN__: {
    en: "Try again",
    no: "Prøv igjen",
    is: "Reyna aftur",
    sv: "Försök igen",
    es: "Inténtelo de nuevo",
    sl: "Poskusi ponovno",
    sk: "Skúste to znova",
    ro: "Încearcă din nou",
    pt: "Tente novamente",
    pl: "Proszę spróbować ponownie",
    nl: "Probeer opnieuw",
    mt: "Erġa 'pprova",
    lt: "Bandyk iš naujo",
    lv: "Mēģini vēlreiz",
    it: "Riprova",
    hu: "Próbáld újra",
    fr: "Essayez à nouveau",
    fi: "Yritä uudelleen",
    et: "Proovi uuesti",
    da: "Prøv igen",
    cs: "Zkus to znovu",
    el: "Προσπάθησε ξανά",
    hr: "Pokušajte ponovno",
    bg: "Опитай пак",
    de: "Versuchen Sie es noch einmal",
  },
  __EMAIL_HELPER_TEXT__: {
    en: "Please write a valid email",
    no: "Skriv en gyldig e-post",
    is: "Færðu inn gilt netfang",
    sv: "Ange en giltig e-postadress",
    es: "Por favor escriba un email valido",
    sl: "Prosimo, napišite veljaven e-poštni naslov",
    sk: "Napíšte platný e-mail",
    ro: "Vă rugăm să scrieți un e-mail valid",
    pt: "Por favor escreva um e-mail válido",
    pl: "Proszę wpisać poprawny adres e-mail",
    nl: "Schrijf alstublieft een geldig e-mail ",
    mt: "Jekk jogħġbok ikteb e-mail valida",
    lt: "Prašome parašyti galiojantį el",
    lv: "Lūdzu, uzrakstiet derīgu e-pastu",
    it: "Vi preghiamo di inserire un indirizzo email valido",
    hu: "Kérjük, írjon érvényes e-mailt",
    fr: "Merci d'écrire un e-mail valide",
    fi: "Kirjoita kelvollinen sähköposti",
    et: "Palun kirjutage kehtiv e-kiri",
    da: "Skriv venligst en gyldig e-mail",
    cs: "Napište prosím platný email",
    el: "Παρακαλώ γράψτε ένα έγκυρο email",
    hr: "Molimo napišite važeći email",
    bg: "Моля, напишете валиден имейл",
    de: "Bitte geben Sie eine gültige E-mail Adresse an",
  },
  __MANDATORY_FIELD__: {
    en: "This field is mandatory",
    no: "Dette feltet er obligatorisk",
    is: "Þessi reitur er áskilinn",
    sv: "Detta fält är obligatoriskt",
    es: "Este campo es obligatorio",
    sl: "To polje je obvezno",
    sk: "Toto pole je povinné",
    ro: "Acest camp este obligatoriu",
    pt: "Este campo é obrigatório",
    pl: "To pole jest obowiązkowe",
    nl: "Dit veld is verplicht",
    mt: "Dan il-qasam huwa obbligatorju",
    lt: "Šis laukas yra privalomas",
    lv: "Šis lauks ir obligāts",
    it: "Questo campo è obbligatorio",
    hu: "Ez a mező kötelező",
    fr: "Ce champ est obligatoire",
    fi: "Tämä kenttä on pakollinen",
    et: "See väli on kohustuslik",
    da: "Dette felt er obligatorisk",
    cs: "Toto pole je povinné",
    el: "Αυτό το πεδίο είναι υποχρεωτικό",
    hr: "Ovo polje je obavezno",
    bg: "Това поле е задължително",
    de: "Dieses Feld ist ein Pflichtfeld",
  },
  __PLEASE_SELECT_VALUE__: {
    en: "Please select a value",
    no: "Velg en verdi",
    is: "Veldu gildi",
    sv: "Välj ett värde",
    es: "Por favor seleccione un valor",
    sl: "Prosimo, izberite vrednost",
    sk: "Vyberte hodnotu",
    ro: "Vă rugăm să selectați o valoare",
    pt: "Selecione um valor",
    pl: "Proszę wybrać odpowiednią wartość",
    nl: "Selecteer  alstublieft een waarde",
    mt: "Jekk jogħġbok agħżel valur",
    lt: "Pasirinkite vertę",
    lv: "Lūdzu, atlasiet vērtību",
    it: "Vi preghiamo di inserire un valore",
    hu: "Kérjük, válasszon egy értéket",
    fr: "Veuillez sélectionner une valeur",
    fi: "Valitse arvo",
    et: "Valige väärtus",
    da: "Vælg venligst en værdi",
    cs: "Vyberte hodnotu",
    el: "Επιλέξτε μια τιμή",
    hr: "Molimo odaberite vrijednost",
    bg: "Моля, изберете стойност",
    de: "Bitte wählen Sie einen Wert aus",
  },
  __REQUEST_HELPER_TEXT__: {
    en: "Please write your request",
    no: "Skriv forespørselen din",
    is: "Skrifaðu beiðni þína",
    sv: "Skriv din fråga",
    es: "Por favor escriba su solicitud",
    sl: "Prosimo, napišite svojo zahtevo",
    sk: "Napíšte prosím Vašu požiadavku",
    ro: "Vă rugăm să scrieți cererea dvs",
    pt: "Por favor escreva o seu pedido",
    pl: "Proszę wpisać treść wniosku",
    nl: "Schrijf  alstublieft uw verzoek",
    mt: "Jekk jogħġbok ikteb it-talba tiegħek",
    lt: "Prašome parašyti savo prašymą",
    lv: "Lūdzu, uzrakstiet savu pieprasījumu",
    it: "Vi preghiamo di scrivere la vostra richiesta",
    hu: "Kérem írja meg kérését",
    fr: "Veuillez écrire votre demande",
    fi: "Kirjoita pyyntösi",
    et: "Palun kirjutage oma soov",
    da: "Skriv venligst din anmodning",
    cs: "Napište prosím svůj požadavek",
    el: "Παρακαλώ γράψτε το αίτημά σας",
    hr: "Molimo napišite svoj zahtjev",
    bg: "Моля, напишете вашата заявка",
    de: "Bitte stellen Sie Ihre Anfrage",
  },
  __CAPTCHA_HELPER_TEXT__: {
    en: "Please solve the captcha",
    no: "Løs captcha-oppgaven",
    is: "Leystu þrautina",
    sv: "Lös captcha",
    es: "Por favor resuelva",
    sl: "Prosimo, rešite captcha",
    sk: "Prosím, vyriešte captcha",
    ro: "Vă rugăm să rezolvați captcha",
    pt: "Por favor, resolva o captcha",
    pl: "Proszę rozwiązać Captcha",
    nl: "Los de captcha alstublieft op",
    mt: "Jekk jogħġbok issolvi l-captcha",
    lt: "Prašome išspręsti captcha",
    lv: "Lūdzu, atrisiniet captcha",
    it: "Vi preghiamo di risolvere il captcha",
    hu: "Kérjük, oldja meg a captcha-t",
    fr: "Merci de résoudre le captcha",
    fi: "Ratkaise captcha",
    et: "Palun lahendage captcha",
    da: "Løs venligst captchaen",
    cs: "Vyřešte prosím captcha",
    el: "Λύστε το captcha",
    hr: "Molimo riješite captcha",
    bg: "Моля, решете captcha",
    de: "Bitte lösen Sie das Captcha",
  },
  __SUBMIT__: {
    en: "Submit",
    no: "Send inn en forespørsel",
    is: "Sendu inn beiðni",
    sv: "Skicka en förfrågan",
    es: "Presentar una solicitud",
    sl: "Oddajte zahtevo",
    sk: "Odoslať žiadosť",
    ro: "Trimite o cerere",
    pt: "Envie uma solicitação",
    pl: "Złożyć wniosek",
    nl: "Dien een aanvraag in",
    mt: "Ibgħat talba",
    lt: "Pateikti prašymą",
    lv: "Iesniedziet pieprasījumu",
    it: "Invia una richiesta",
    hu: "Kérelem benyújtása",
    fr: "Soumettre une demande",
    fi: "Lähetä pyyntö",
    et: "Saada päring",
    da: "Send en forespørgsel",
    cs: "Odeslat žádost",
    el: "Υποβάλετε ένα αίτημα",
    hr: "Pošaljite zahtjev",
    bg: "Изпратете заявка",
    de: "EinenaAntrag stellen",
  },
};

export default facilitiesAgent;
