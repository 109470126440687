// react dependencies
import React, { Component } from "react";

class Modal extends Component {
  dialog;

  componentDidMount = () => {
    if (this.refs.dialog) {
      this.dialog = window.mdc.dialog.MDCDialog.attachTo(this.refs.dialog);
      this.dialog.scrimClickAction = "";
      this.dialog.listen("MDCDialog:closing", () => {
        this.props.onClose();
      });
    }
  };

  componentWillUnmount = () => {
    this.dialog.foundation_.close();
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.show != this.props.show) {
      if (nextProps.show) {
        this.dialog.foundation_.open();
      } else {
        this.dialog.foundation_.close();
      }
    }
  };

  renderChildren = () => {
    return React.Children.map(this.props.children, (child, index) => {
      if (child)
        return React.cloneElement(child, {
          closeButton: this.props.closeButton,
          onClose: this.props.onClose,
        });
    });
  };

  render() {
    return (
      <div
        ref="dialog"
        className={
          "mdc-dialog" +
          (this.props.className ? " " + this.props.className : "")
        }
        role="alertdialog"
        aria-modal="true"
        aria-labelledby="my-dialog-title"
        aria-describedby="my-dialog-content"
      >
        <div className="mdc-dialog__container">
          <div className="mdc-dialog__surface">{this.renderChildren()}</div>
        </div>
        <div className="mdc-dialog__scrim"></div>
      </div>
    );
  }
}
export default Modal;
