import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import PromotorsApi from "../../../models/PromotorsApi";

export function loadPromotorsAdd(name, domicileId) {
  let action = new ActionRequest(
    PromotorsApi.loadAddPromotor,
    loadPromotorsAddInit,
    loadPromotorsAddSuccess,
    loadPromotorsAddFailure
  );
  return action.run({
    routeParameters: { name, domicileId },
    actionParameters: { name, domicileId },
  });
}

function loadPromotorsAddSuccess(promotorsAddData, params) {
  return {
    type: types.ADD_PROMOTOR_SUCCESS,
    data: promotorsAddData,
  };
}

function loadPromotorsAddFailure(error) {
  return {
    type: types.ADD_PROMOTOR_FAILURE,
    notification: {
      level: "error",
      label: "[action:promotorsAdd>loadPromotorsAdd]",
      message: "Impossible de récupérer la liste des promotorsAdd",
      system_message: error.message,
    },
  };
}

function loadPromotorsAddInit(params) {
  return { type: types.ADD_PROMOTOR_INIT };
}
