export const IS_AUTHENTICATED_INIT = "IS_AUTHENTICATED:GET_INIT";
export const IS_AUTHENTICATED_SUCCESS = "IS_AUTHENTICATED:GET_SUCCESS";
export const IS_AUTHENTICATED_FAILURE = "IS_AUTHENTICATED:GET_FAIL";

export const SET_NEEDS_AUTH = "SET_NEEDS_AUTH";

export const CLAIMS_INIT = "GET_CLAIMS:GET_INIT";
export const CLAIMS_SUCCESS = "GET_CLAIMS:GET_SUCCESS";
export const CLAIMS_FAILURE = "GET_CLAIMS:GET_FAIL";

export const LOG_OFF_INIT = "LOG_OFF:GET_INIT";
export const LOG_OFF_SUCCESS = "LOG_OFF:GET_SUCCESS";
export const LOG_OFF_FAILURE = "LOG_OFF:GET_FAIL";

export const ACCEPT_GTC_INIT = "ACCEPT_GTC:GET_INIT";
export const ACCEPT_GTC_SUCCESS = "ACCEPT_GTC:GET_SUCCESS";
export const ACCEPT_GTC_FAILURE = "ACCEPT_GTC:GET_FAIL";
