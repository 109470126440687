export const GET_DOCUMENT_TYPES_BY_FUND_INIT =
  "DOCUMENT_TYPES_BY_FUND:GET_INIT";
export const GET_DOCUMENT_TYPES_BY_FUND_SUCCESS =
  "DOCUMENT_TYPES_BY_FUND:GET_SUCCESS";
export const GET_DOCUMENT_TYPES_BY_FUND_FAILURE =
  "DOCUMENT_TYPES_BY_FUND:GET_FAIL";

export const GET_DOCUMENT_TYPES_INIT = "DOCUMENT_TYPES:GET_INIT";
export const GET_DOCUMENT_TYPES_SUCCESS = "DOCUMENT_TYPES:GET_SUCCESS";
export const GET_DOCUMENT_TYPES_FAILURE = "DOCUMENT_TYPES:GET_FAIL";

export const DOCUMENT_SUB_TYPES_BY_DOCUMENT_TYPE_INIT =
  "DOCUMENT_SUB_TYPES_BY_DOCUMENT_TYPE:GET_INIT";
export const DOCUMENT_SUB_TYPES_BY_DOCUMENT_TYPE_SUCCESS =
  "DOCUMENT_SUB_TYPES_BY_DOCUMENT_TYPE:GET_SUCCESS";
export const DOCUMENT_SUB_TYPES_BY_DOCUMENT_TYPE_FAILURE =
  "DOCUMENT_SUB_TYPES_BY_DOCUMENT_TYPE:GET_FAIL";
