export const GET_DISTRIBUTION_MATRIX_INIT = "DISTRIBUTION_MATRIX:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_SUCCESS =
  "DISTRIBUTION_MATRIX:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_FAILURE = "DISTRIBUTION_MATRIX:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_TASK_DETAILS_INIT =
  "DISTRIBUTION_MATRIX_TASK_DETAILS:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_TASK_DETAILS_SUCCESS =
  "DISTRIBUTION_MATRIX_TASK_DETAILS:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_TASK_DETAILS_FAILURE =
  "DISTRIBUTION_MATRIX_TASK_DETAILS:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_MAP_INIT =
  "DISTRIBUTION_MATRIX_MAP:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_MAP_SUCCESS =
  "DISTRIBUTION_MATRIX_MAP:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_MAP_FAILURE =
  "DISTRIBUTION_MATRIX_MAP:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_IMPORT_INIT =
  "DISTRIBUTION_MATRIX_IMPORT:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_IMPORT_SUCCESS =
  "DISTRIBUTION_MATRIX_IMPORT:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_IMPORT_FAILURE =
  "DISTRIBUTION_MATRIX_IMPORT:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE_INIT =
  "DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE_SUCCESS =
  "DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE_FAILURE =
  "DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE_CSV_INIT =
  "DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE_CSV:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE_CSV_SUCCESS =
  "DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE_CSV:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE_CSV_FAILURE =
  "DISTRIBUTION_MATRIX_IMPORT_FUNDSQUARE_CSV:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE_INIT =
  "DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE_SUCCESS =
  "DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE_FAILURE =
  "DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE_CSV_INIT =
  "DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE_CSV:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE_CSV_SUCCESS =
  "DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE_CSV:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE_CSV_FAILURE =
  "DISTRIBUTION_MATRIX_IMPORT_UPDATE_FUNDSQUARE_CSV:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_IMPORT_UPDATE_INIT =
  "DISTRIBUTION_MATRIX_IMPORT_UPDATE:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_IMPORT_UPDATE_SUCCESS =
  "DISTRIBUTION_MATRIX_IMPORT_UPDATE:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_IMPORT_UPDATE_FAILURE =
  "DISTRIBUTION_MATRIX_IMPORT_UPDATE:GET_FAIL";
export const GET_DISTRIBUTION_MATRIX_IMPORT_UPDATE_FAILURE_CLEAR =
  "DISTRIBUTION_MATRIX_IMPORT_UPDATE:GET_FAIL_CLEAR";

export const GET_DISTRIBUTION_MATRIX_FILTERS_INIT =
  "DISTRIBUTION_MATRIX_FILTERS:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_FILTERS_SUCCESS =
  "DISTRIBUTION_MATRIX_FILTERS:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_FILTERS_FAILURE =
  "DISTRIBUTION_MATRIX_FILTERS:GET_FAIL";

export const SET_DISTRIBUTION_MATRIX_FILTERS =
  "DISTRIBUTION_MATRIX_SET_FILTERS";

export const MATRIX_SHOW_FILTERS = "MATRIX_SHOW_FILTERS";

export const SET_APPLIED_DISTRIBUTION_MATRIX_FILTERS =
  "DISTRIBUTION_MATRIX_SET_APPLIED_FILTERS";

export const GET_DISTRIBUTION_MATRIX_FILTERS_STATS_INIT =
  "DISTRIBUTION_MATRIX_FILTERS_STATS:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_FILTERS_STATS_SUCCESS =
  "DISTRIBUTION_MATRIX_FILTERS_STATS:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_FILTERS_STATS_FAILURE =
  "DISTRIBUTION_MATRIX_FILTERS_STATS:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_EXPORT_EXCEL_INIT =
  "DISTRIBUTION_MATRIX_EXPORT_EXCEL:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_EXPORT_EXCEL_SUCCESS =
  "DISTRIBUTION_MATRIX_EXPORT_EXCEL:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_EXPORT_EXCEL_FAILURE =
  "DISTRIBUTION_MATRIX_EXPORT_EXCEL:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_EXPORT_PDF_INIT =
  "DISTRIBUTION_MATRIX_EXPORT_PDF:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_EXPORT_PDF_SUCCESS =
  "DISTRIBUTION_MATRIX_EXPORT_PDF:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_EXPORT_PDF_FAILURE =
  "DISTRIBUTION_MATRIX_EXPORT_PDF:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_EXPORT_CSVOPENFUNDS_INIT =
  "DISTRIBUTION_MATRIX_EXPORT_CSVOPENFUNDS:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_EXPORT_CSVOPENFUNDS_SUCCESS =
  "DISTRIBUTION_MATRIX_EXPORT_CSVOPENFUNDS:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_EXPORT_CSVOPENFUNDS_FAILURE =
  "DISTRIBUTION_MATRIX_EXPORT_CSVOPENFUNDS:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_ALL_FILTERS_INIT =
  "DISTRIBUTION_MATRIX_ALL_FILTERS:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_ALL_FILTERS_SUCCESS =
  "DISTRIBUTION_MATRIX_ALL_FILTERS:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_ALL_FILTERS_FAILURE =
  "DISTRIBUTION_MATRIX_ALL_FILTERS:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_DATE_INIT =
  "DISTRIBUTION_MATRIX_DATE:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_DATE_SUCCESS =
  "DISTRIBUTION_MATRIX_DATE:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_DATE_FAILURE =
  "DISTRIBUTION_MATRIX_DATE:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_FLUSH_INIT =
  "DISTRIBUTION_MATRIX_FLUSH:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_FLUSH_SUCCESS =
  "DISTRIBUTION_MATRIX_FLUSH:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_FLUSH_FAILURE =
  "DISTRIBUTION_MATRIX_FLUSH:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_STATUS_INIT =
  "DISTRIBUTION_MATRIX_STATUS:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_STATUS_SUCCESS =
  "DISTRIBUTION_MATRIX_STATUS:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_STATUS_FAILURE =
  "DISTRIBUTION_MATRIX_STATUS:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_UPDATE_INIT =
  "DISTRIBUTION_MATRIX_UPDATE:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_UPDATE_SUCCESS =
  "DISTRIBUTION_MATRIX_UPDATE:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_UPDATE_FAILURE =
  "DISTRIBUTION_MATRIX_UPDATE:GET_FAIL";

export const GET_DISTRIBUTION_MATRIX_CHECK_EDIT_LOCKED_INIT =
  "DISTRIBUTION_MATRIX_CHECK_EDIT_LOCKED:GET_INIT";
export const GET_DISTRIBUTION_MATRIX_CHECK_EDIT_LOCKED_SUCCESS =
  "DISTRIBUTION_MATRIX_CHECK_EDIT_LOCKED:GET_SUCCESS";
export const GET_DISTRIBUTION_MATRIX_CHECK_EDIT_LOCKED_FAILURE =
  "DISTRIBUTION_MATRIX_CHECK_EDIT_LOCKED:GET_FAIL";
