export const GET_DOCUMENTS_BY_MONTH_INIT = "DOCUMENTS_BY_MONTH:GET_INIT";
export const GET_DOCUMENTS_BY_MONTH_SUCCESS = "DOCUMENTS_BY_MONTH:GET_SUCCESS";
export const GET_DOCUMENTS_BY_MONTH_FAILURE = "DOCUMENTS_BY_MONTH:GET_FAIL";

export const GET_DOCUMENTS_LANGUAGES_INIT = "DOCUMENTS_LANGUAGES:GET_INIT";
export const GET_DOCUMENTS_LANGUAGES_SUCCESS =
  "DOCUMENTS_LANGUAGES:GET_SUCCESS";
export const GET_DOCUMENTS_LANGUAGES_FAILURE = "DOCUMENTS_LANGUAGES:GET_FAIL";

export const GET_DOCUMENTS_SET_METADATA_INIT =
  "DOCUMENTS_SET_METADATA:GET_INIT";
export const GET_DOCUMENTS_SET_METADATA_SUCCESS =
  "DOCUMENTS_SET_METADATA:GET_SUCCESS";
export const GET_DOCUMENTS_SET_METADATA_FAILURE =
  "DOCUMENTS_SET_METADATA:GET_FAIL";

export const GET_DOCUMENTS_METADATA_INIT = "DOCUMENTS_METADATA:GET_INIT";
export const GET_DOCUMENTS_METADATA_SUCCESS = "DOCUMENTS_METADATA:GET_SUCCESS";
export const GET_DOCUMENTS_METADATA_FAILURE = "DOCUMENTS_METADATA:GET_FAIL";

export const GET_DOCUMENTS_METADATA_TYPES_INIT =
  "DOCUMENTS_METADATA_TYPES:GET_INIT";
export const GET_DOCUMENTS_METADATA_TYPES_SUCCESS =
  "DOCUMENTS_METADATA_TYPES:GET_SUCCESS";
export const GET_DOCUMENTS_METADATA_TYPES_FAILURE =
  "DOCUMENTS_METADATA_TYPES:GET_FAIL";

export const GET_DOCUMENTS_DELETE_INIT = "DOCUMENTS_DELETE:GET_INIT";
export const GET_DOCUMENTS_DELETE_SUCCESS = "DOCUMENTS_DELETE:GET_SUCCESS";
export const GET_DOCUMENTS_DELETE_FAILURE = "DOCUMENTS_DELETE:GET_FAIL";

export const GET_DOCUMENTS_PREVIEW_INIT = "DOCUMENTS_PREVIEW:GET_INIT";
export const GET_DOCUMENTS_PREVIEW_SUCCESS = "DOCUMENTS_PREVIEW:GET_SUCCESS";
export const GET_DOCUMENTS_PREVIEW_FAILURE = "DOCUMENTS_PREVIEW:GET_FAIL";

export const GET_DOCUMENTS_DOWNLOAD_INIT = "DOCUMENTS_DOWNLOAD:GET_INIT";
export const GET_DOCUMENTS_DOWNLOAD_SUCCESS = "DOCUMENTS_DOWNLOAD:GET_SUCCESS";
export const GET_DOCUMENTS_DOWNLOAD_FAILURE = "DOCUMENTS_DOWNLOAD:GET_FAIL";

export const DOCUMENTS_SELECTED_INIT = "DOCUMENTS_SELECTED:GET_INIT";
export const DOCUMENTS_SELECTED_SUCCESS = "DOCUMENTS_SELECTED:GET_SUCCESS";
export const DOCUMENTS_SELECTED_FAILURE = "DOCUMENTS_SELECTED:GET_FAIL";

export const GET_DOCUMENTS_DOWNLOAD_MULTIPLE_INIT =
  "DOCUMENTS_DOWNLOAD:GET_INIT";
export const GET_DOCUMENTS_DOWNLOAD_MULTIPLE_SUCCESS =
  "DOCUMENTS_DOWNLOAD:GET_SUCCESS";
export const GET_DOCUMENTS_DOWNLOAD_MULTIPLE_FAILURE =
  "DOCUMENTS_DOWNLOAD:GET_FAIL";

export const GET_DOCUMENTS_CHECK_EXISTING_INIT =
  "DOCUMENTS_CHECK_EXISTING:GET_INIT";
export const GET_DOCUMENTS_CHECK_EXISTING_SUCCESS =
  "DOCUMENTS_CHECK_EXISTING:GET_SUCCESS";
export const GET_DOCUMENTS_CHECK_EXISTING_FAILURE =
  "DOCUMENTS_CHECK_EXISTING:GET_FAIL";

export const GET_DOCUMENTS_SUB_TYPES_INIT = "DOCUMENTS_SUB_TYPES:GET_INIT";
export const GET_DOCUMENTS_SUB_TYPES_SUCCESS =
  "DOCUMENTS_SUB_TYPES:GET_SUCCESS";
export const GET_DOCUMENTS_SUB_TYPES_FAILURE = "DOCUMENTS_SUB_TYPESG:GET_FAIL";
