const SESSION_STORAGE_KEY = "GFD_Promotor_Change_Buffer";

const BUFFER_WINDOW = 10 * 1000; //seconds

export const setBuffer = () => {
  window.sessionStorage.setItem(SESSION_STORAGE_KEY, new Date().toISOString());
};

export const resetBuffer = () => {
  window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
};

export const inBuffer = () => {
  const buffer = window.sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (!buffer) {
    return false;
  }
  window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
  const bufferDate = new Date(buffer);
  const dateLimit = new Date(Date.now() - BUFFER_WINDOW);
  if (bufferDate > dateLimit) {
    return true;
  }
  return false;
};
