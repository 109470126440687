import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import LibraryV2Api from "../../../models/LibraryV2Api";

export function loadLibraryGetExceptions() {
  let action = new ActionRequest(
    LibraryV2Api.loadLibraryGetExceptions,
    loadLibraryGetExceptionsInit,
    loadLibraryGetExceptionsSuccess,
    loadLibraryGetExceptionsFailure
  );
  return action.run({
    routeParameters: {},
    actionParameters: {},
  });
}

function loadLibraryGetExceptionsSuccess(librayImportData, params) {
  return {
    type: types.LIBRARY_GET_EXCEPTIONS_SUCCESS,
    data: librayImportData,
  };
}

function loadLibraryGetExceptionsFailure(error) {
  return {
    type: types.LIBRARY_GET_EXCEPTIONS_FAILURE,
    notification: {
      level: "error",
      label: "[action:librayImport>loadLibraryGetExceptions]",
      message: "Impossible de récupérer la liste des librayImport",
      system_message: error.message,
    },
  };
}

function loadLibraryGetExceptionsInit(params) {
  return { type: types.LIBRARY_GET_EXCEPTIONS_INIT };
}
