export const LIBRARY_GET_AUTHORS_INIT = "LIBRARY_GET_AUTHORS:GET_INIT";
export const LIBRARY_GET_AUTHORS_SUCCESS = "LIBRARY_GET_AUTHORS:GET_SUCCESS";
export const LIBRARY_GET_AUTHORS_FAILURE = "LIBRARY_GET_AUTHORS:GET_FAIL";

export const LIBRARY_GET_EXCEPTIONS_INIT = "LIBRARY_GET_EXCEPTIONS:GET_INIT";
export const LIBRARY_GET_EXCEPTIONS_SUCCESS =
  "LIBRARY_GET_EXCEPTIONS:GET_SUCCESS";
export const LIBRARY_GET_EXCEPTIONS_FAILURE = "LIBRARY_GET_EXCEPTIONS:GET_FAIL";

export const LIBRARY_GET_HISTORY_INIT = "LIBRARY_GET_HISTORY:GET_INIT";
export const LIBRARY_GET_HISTORY_SUCCESS = "LIBRARY_GET_HISTORY:GET_SUCCESS";
export const LIBRARY_GET_HISTORY_FAILURE = "LIBRARY_GET_HISTORY:GET_FAIL";

export const LIBRARY_GET_COUNTERS_INIT = "LIBRARY_GET_COUNTERS:GET_INIT";
export const LIBRARY_GET_COUNTERS_SUCCESS = "LIBRARY_GET_COUNTERS:GET_SUCCESS";
export const LIBRARY_GET_COUNTERS_FAILURE = "LIBRARY_GET_COUNTERS:GET_FAIL";

export const LIBRARY_GET_METADATA_BY_TYPE_INIT =
  "LIBRARY_GET_METADATA_BY_TYPE:GET_INIT";
export const LIBRARY_GET_METADATA_BY_TYPE_SUCCESS =
  "LIBRARY_GET_METADATA_BY_TYPE:GET_SUCCESS";
export const LIBRARY_GET_METADATA_BY_TYPE_FAILURE =
  "LIBRARY_GET_METADATA_BY_TYPE:GET_FAIL";

export const LIBRARY_GET_MISSING_METADATA_INIT =
  "LIBRARY_MISSING_METADATA:GET_INIT";
export const LIBRARY_GET_MISSING_METADATA_SUCCESS =
  "LIBRARY_MISSING_METADATA:GET_SUCCESS";
export const LIBRARY_GET_MISSING_METADATA_FAILURE =
  "LIBRARY_GET_MISSING_METADATA:GET_FAIL";

export const LIBRARY_GET_STATUS_INIT = "LIBRARY_GET_STATUS:GET_INIT";
export const LIBRARY_GET_STATUS_SUCCESS = "LIBRARY_GET_STATUS:GET_SUCCESS";
export const LIBRARY_GET_STATUS_FAILURE = "LIBRARY_GET_STATUS:GET_FAIL";

export const LIBRARY_GET_UNCLASSIFIED_INIT =
  "LIBRARY_GET_UNCLASSIFIED:GET_INIT";
export const LIBRARY_GET_UNCLASSIFIED_SUCCESS =
  "LIBRARY_GET_UNCLASSIFIED:GET_SUCCESS";
export const LIBRARY_GET_UNCLASSIFIED_FAILURE =
  "LIBRARY_GET_UNCLASSIFIED:GET_FAIL";

export const LIBRARY_POST_EXCEPTION_INIT = "LIBRARY_POST_EXCEPTION:GET_INIT";
export const LIBRARY_POST_EXCEPTION_SUCCESS =
  "LIBRARY_POST_EXCEPTION:GET_SUCCESS";
export const LIBRARY_POST_EXCEPTION_FAILURE = "LIBRARY_POST_EXCEPTION:GET_FAIL";

export const LIBRARY_POST_MISSING_METADATA_INIT =
  "LIBRARY_POST_MISSING_METADATA:GET_INIT";
export const LIBRARY_POST_MISSING_METADATA_SUCCESS =
  "LIBRARY_POST_MISSING_METADATA:GET_SUCCESS";
export const LIBRARY_POST_MISSING_METADATA_FAILURE =
  "LIBRARY_POST_MISSING_METADATA:GET_FAIL";

export const LIBRARY_POST_UNCLASSIFIED_INIT =
  "LIBRARY_POST_UNCLASSIFIED:GET_INIT";
export const LIBRARY_POST_UNCLASSIFIED_SUCCESS =
  "LIBRARY_POST_UNCLASSIFIED:GET_SUCCESS";
export const LIBRARY_POST_UNCLASSIFIED_FAILURE =
  "LIBRARY_POST_UNCLASSIFIED:GET_FAIL";

export const LIBRARY_UPLOAD_FILE_INIT = "LIBRARY_UPLOAD_FILE:GET_INIT";
export const LIBRARY_UPLOAD_FILE_SUCCESS = "LIBRARY_UPLOAD_FILE:GET_SUCCESS";
export const LIBRARY_UPLOAD_FILE_FAILURE = "LIBRARY_UPLOAD_FILE:GET_FAIL";

export const LIBRARY_DOCUMENTS_INIT = "LIBRARY_DOCUMENTS:GET_INIT";
export const LIBRARY_DOCUMENTS_SUCCESS = "LIBRARY_DOCUMENTS:GET_SUCCESS";
export const LIBRARY_DOCUMENTS_FAILURE = "LIBRARY_DOCUMENTS:GET_FAIL";

export const LIBRARY_RELATED_TASKS_INIT = "LIBRARY_RELATED_TASKS:GET_INIT";
export const LIBRARY_RELATED_TASKS_SUCCESS =
  "LIBRARY_RELATED_TASKS:GET_SUCCESS";
export const LIBRARY_RELATED_TASKS_FAILURE = "LIBRARY_RELATED_TASKS:GET_FAIL";
