import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import PromotorsApi from "../../../models/PromotorsApi";

export function loadPromotorsGetCurrent() {
  let action = new ActionRequest(
    PromotorsApi.getCurrentPromotor,
    loadPromotorsGetCurrentInit,
    loadPromotorsGetCurrentSuccess,
    loadPromotorsGetCurrentFailure
  );
  return action.run({
    routeParameters: {},
    actionParameters: {},
  });
}

function loadPromotorsGetCurrentSuccess(promotorsGetCurrentData, params) {
  return {
    type: types.GET_CURRENT_PROMOTOR_SUCCESS,
    data: promotorsGetCurrentData,
  };
}

function loadPromotorsGetCurrentFailure(error) {
  return {
    type: types.GET_CURRENT_PROMOTOR_FAILURE,
    notification: {
      level: "error",
      label: "[action:promotorsGetCurrent>loadPromotorsGetCurrent]",
      message: "Impossible de récupérer la liste des promotorsGetCurrent",
      system_message: error.message,
    },
  };
}

function loadPromotorsGetCurrentInit(params) {
  return { type: types.GET_CURRENT_PROMOTOR_INIT };
}
