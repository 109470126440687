// react dependencies
import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import Avatar from "../avatar/Avatar";

class UserCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCenter: false,
    };
  }

  handleClickOutside = (evt) => {
    this.setState({ userCenter: false });
  };

  onClickUserCenterContent = (e) => {
    e.stopPropagation();
  };

  onClickUserCenter = () => {
    this.setState({
      userCenter: !this.state.userCenter,
    });
  };

  render() {
    return (
      <div
        onClick={this.onClickUserCenter}
        className="main-header-connected-user-name-user"
      >
        <span>Hello, {this.props.user.firstName}</span>
        <Avatar
          fullName={`${this.props.user.firstName || ""} ${
            this.props.user.lastName || ""
          }`.trim()}
          photo={
            this.props.user.photo
              ? `data:image/png;base64,${this.props.user.photo}`
              : null
          }
        />
        <i
          className={
            "arrow-user " +
            (this.state.userCenter
              ? "pwc-arrows-arrow-up-8"
              : "pwc-arrows-arrow-down-8")
          }
        />
        <div
          className={
            "user-center " +
            (this.state.userCenter ? "user-center--visible" : "")
          }
        >
          <div
            onClick={(e) => this.onClickNotificationCenterContent(e)}
            className={
              "user-center-container " +
              (this.state.userCenter ? "user-center-container--visible" : "")
            }
          >
            <ul>{this.props.children}</ul>
          </div>
        </div>
      </div>
    );
  }
}
export default onClickOutside(UserCenter);
