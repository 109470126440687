export const GET_CRM_COMPANIES_INIT = "CRM_COMPANIES:GET_INIT";
export const GET_CRM_COMPANIES_SUCCESS = "CRM_COMPANIES:GET_SUCCESS";
export const GET_CRM_COMPANIES_FAILURE = "CRM_COMPANIES:GET_FAIL";

export const GET_ADD_USERS_INIT = "ADD_USERS:GET_INIT";
export const GET_ADD_USERS_SUCCESS = "ADD_USERS:GET_SUCCESS";
export const GET_ADD_USERS_FAILURE = "ADD_USERS:GET_FAIL";

export const GET_ADD_PWC_USERS_INIT = "ADD_PWC_USERS:GET_INIT";
export const GET_ADD_PWC_USERS_SUCCESS = "ADD_PWC_USERS:GET_SUCCESS";
export const GET_ADD_PWC_USERS_FAILURE = "ADD_PWC_USERS:GET_FAIL";

export const GET_REMOVE_USERS_INIT = "REMOVE_USERS:GET_INIT";
export const GET_REMOVE_USERS_SUCCESS = "REMOVE_USERS:GET_SUCCESS";
export const GET_REMOVE_USERS_FAILURE = "REMOVE_USERS:GET_FAIL";

export const GET_EDIT_STAFF_INIT = "EDIT_STAFF:GET_INIT";
export const GET_EDIT_STAFF_SUCCESS = "EDIT_STAFF:GET_SUCCESS";
export const GET_EDIT_STAFF_FAILURE = "EDIT_STAFF:GET_FAIL";

export const GET_GET_EXTERNAL_USERS_INIT = "GET_EXTERNAL_USERS:GET_INIT";
export const GET_GET_EXTERNAL_USERS_SUCCESS = "GET_EXTERNAL_USERS:GET_SUCCESS";
export const GET_GET_EXTERNAL_USERS_FAILURE = "GET_EXTERNAL_USERS:GET_FAIL";

export const GET_GET_ALL_ROLES_INIT = "GET_ALL_ROLES:GET_INIT";
export const GET_GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES:GET_SUCCESS";
export const GET_GET_ALL_ROLES_FAILURE = "GET_ALL_ROLES:GET_FAIL";

export const GET_GET_ALL_EXTERNAL_ROLES_INIT =
  "GET_ALL_EXTERNAL_ROLES:GET_INIT";
export const GET_GET_ALL_EXTERNAL_ROLES_SUCCESS =
  "GET_ALL_EXTERNAL_ROLES:GET_SUCCESS";
export const GET_GET_ALL_EXTERNAL_ROLES_FAILURE =
  "GET_ALL_EXTERNAL_ROLES:GET_FAIL";

export const GET_GET_USERS_FOR_PROMOTOR_INIT =
  "GET_USERS_FOR_PROMOTOR:GET_INIT";
export const GET_GET_USERS_FOR_PROMOTOR_SUCCESS =
  "GET_USERS_FOR_PROMOTOR:GET_SUCCESS";
export const GET_GET_USERS_FOR_PROMOTOR_FAILURE =
  "GET_USERS_FOR_PROMOTOR:GET_FAIL";

export const GET_REMOVE_ACCESS_INIT = "REMOVE_ACCESS:GET_INIT";
export const GET_REMOVE_ACCESS_SUCCESS = "REMOVE_ACCESS:GET_SUCCESS";
export const GET_REMOVE_ACCESS_FAILURE = "REMOVE_ACCESS:GET_FAIL";

export const GET_CREATE_EXTERNAL_USER_INIT = "CREATE_EXTERNAL_USER:GET_INIT";
export const GET_CREATE_EXTERNAL_USER_SUCCESS =
  "CREATE_EXTERNAL_USER:GET_SUCCESS";
export const GET_CREATE_EXTERNAL_USER_FAILURE = "CREATE_EXTERNAL_USER:GET_FAIL";

export const GET_CREATE_OR_EDIT_ACCESS_INIT = "CREATE_OR_EDIT_ACCESS:GET_INIT";
export const GET_CREATE_OR_EDIT_ACCESS_SUCCESS =
  "CREATE_OR_EDIT_ACCESS:GET_SUCCESS";
export const GET_CREATE_OR_EDIT_ACCESS_FAILURE =
  "CREATE_OR_EDIT_ACCESS:GET_FAIL";

export const GET_GET_USERS_BY_MAIL_INIT = "GET_USERS_BY_MAIL:GET_INIT";
export const GET_GET_USERS_BY_MAIL_SUCCESS = "GET_USERS_BY_MAIL:GET_SUCCESS";
export const GET_GET_USERS_BY_MAIL_FAILURE = "GET_USERS_BY_MAIL:GET_FAIL";
