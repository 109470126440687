import * as types from "../constants";
import ActionRequest from "../../../utils/requests/actionRequest";
import NotificationsApi from "../../../models/NotificationsApi";

export function loadNotificationsMarkAllAsDone() {
  let action = new ActionRequest(
    NotificationsApi.loadNotificationsMarkAllAsDone,
    loadNotificationsMarkAllAsDoneInit,
    loadNotificationsMarkAllAsDoneSuccess,
    loadNotificationsMarkAllAsDoneFailure
  );
  return action.run({
    routeParameters: {},
    actionParameters: {},
  });
}

function loadNotificationsMarkAllAsDoneSuccess(
  notificationsMarkAllAsDoneData,
  params
) {
  return {
    type: types.GET_NOTIFICATIONS_MARK_ALL_AS_DONE_SUCCESS,
    data: notificationsMarkAllAsDoneData,
  };
}

function loadNotificationsMarkAllAsDoneFailure(error) {
  return {
    type: types.GET_NOTIFICATIONS_MARK_ALL_AS_DONE_FAILURE,
    notification: {
      level: "error",
      label:
        "[action:notificationsMarkAllAsDone>loadNotificationsMarkAllAsDone]",
      message:
        "Impossible de récupérer la liste des notificationsMarkAllAsDone",
      system_message: error.message,
    },
  };
}

function loadNotificationsMarkAllAsDoneInit(params) {
  return { type: types.GET_NOTIFICATIONS_MARK_ALL_AS_DONE_INIT };
}
