import * as types from '../constants';

const initialState = {
  isLoading: true,
  error: null,
  data: {}
}

export default function logOffReducer(state = initialState, action) {
  switch (action.type) {

    case types.LOG_OFF_INIT:
      return { ...state, error: null, isLoading: true };

    case types.LOG_OFF_SUCCESS:
      return { ...state, isLoading: false, data: action.data.data };

    case types.LOG_OFF_FAILURE:
      return { ...state, isLoading: false, error: action.notification.system_message };

    default:
      return state;
  }
}
