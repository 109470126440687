import Request from "../utils/requests/request";

export default class PromotorsApi {
  static setCurrentPromotor(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Promotor/SetCurrentPromotor"
    );
    return loadReq.post(params.promotorId);
  }

  static loadPromotors(params) {
    let loadReq;
    if (params.search)
      loadReq = new Request(
        process.env.REACT_APP_API_BASEURL +
          "Promotor/Get?search=" +
          encodeURI(params.search)
      );
    else
      loadReq = new Request(process.env.REACT_APP_API_BASEURL + "Promotor/Get");
    return loadReq.get();
  }

  static loadAddPromotor(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Promotor/CreatePromotor"
    );
    return loadReq.post({ Name: params.name, CountryId: params.domicileId });
  }

  static getPromotorInfo() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Promotor/GetPromotorInfos"
    );
    return loadReq.get();
  }

  static getPromotorSettings() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Promotor/GetPromotorSettings"
    );
    return loadReq.get();
  }

  static loadPromotorsGetStaff() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "User/GetStaff"
    );
    return loadReq.get();
  }

  static loadPromotorsEdit(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Promotor/EditPromotor"
    );
    return loadReq.post(params.data);
  }

  static getCurrentPromotor() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Promotor/GetCurrentPromotor"
    );
    return loadReq.get();
  }

  static getCountriesFDWPromotor() {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Promotor/GetNewsSettings"
    );
    return loadReq.get();
  }

  static getPromotorDeleteableStatus(promotorId) {
    let loadReq = new Request(
      `${process.env.REACT_APP_API_BASEURL}Promotor/PromotorCanBeDeleted?promotorId=${promotorId}`
    );
    return loadReq.get();
  }

  static deletePromotor(promotorId) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL + "Promotor/RemovePromotor"
    );
    return loadReq.post(promotorId);
  }

  static setCountriesFDWPromotor(params) {
    let loadReq = new Request(
      process.env.REACT_APP_API_BASEURL +
        "Promotor/SetNewsSettings?isPrenium=" +
        params.data.premium
    );
    return loadReq.post(params.data.countries);
  }
}
